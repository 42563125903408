import { useTranslation } from "react-i18next";
import { useState } from "react";

import { getRequest, postRequest } from "../../services/Networking";
import {
  GET_ACCOUNT_STATUS,
  RESTART_ACCOUNT,
} from "../../services/Networking/api";
import statusGreenIcon from "../../assets/images/status_green_icon.svg";
import statusRedIcon from "../../assets/images/status_red_icon.svg";
import statusBlueIcon from "../../assets/images/status_reconnecting_icon.svg";
import editIcon from "../../assets/images/edit_icon.svg";
import crossIcon from "../../assets/images/cross_red_icon.svg";
import styles from "./styles.module.css";

const AccountListItem = ({
  index,
  fullName,
  profilePicture,
  validSession,
  unipile_id,
  modifyModalStates,
  removeAccount,
  setUpdateStatus,
  getHostedAuthUrl,
  isMember,
}) => {
  const { t } = useTranslation();
  const [accountReconnect, setAccountReconnect] = useState(false);

  const restartAccount = async (type, index) => {
    let res = null;
    // reconnectStateHandler(index, true); ac reconnect instead
    setAccountReconnect(true);
    try {
      const response = await getRequest(`${GET_ACCOUNT_STATUS}/${unipile_id}`);
      res = await response.json();
    } catch (error) {
      console.error(error);
    }

    if (res.result !== "CREDENTIALS") {
      // reconnectStateHandler(index, true);

      try {
        const response = await postRequest(`${RESTART_ACCOUNT}`, {
          account_id: unipile_id,
        });
        res = await response.json();
      } catch (error) {
        console.error(error);
        // reconnectStateHandler(index, true); ac reconnect instead
        setAccountReconnect(true);
      }

      if (
        res.result === "AccountRestarted" ||
        res.result === "Account Restarted"
      ) {
        //waiting for 5 seconds to again check the status
        setUpdateStatus((curr) => !curr);

        // await new Promise((resolve) => setTimeout(resolve, 5000)); // second wait for now

        // try {
        //   const response = await getRequest(
        //     `${GET_ACCOUNT_STATUS}/${unipile_id[index]}`
        //   );
        //   res = await response.json();
        // } catch (error) {
        //   console.error(error);
        // }

        // if (res.result === "OK") {
        //   // making valid session as 1 in db
        //   try {
        //     const response = await postRequest(`${SET_VALID_SESSION}`, { account_id: unipile_id[index] });
        //     res = await response.json();
        //   } catch (error) {
        //     console.error(error);
        //   }

        //   if (res.code === 200) {
        //     setUpdateStatus((curr) => !curr);
        //   }
        //   else {
        //     // change back button to reconnect
        //     reconnectStateHandler(index, false);
        //   }
        // }
        // else {
        //   // change back button to reconnect
        //   reconnectStateHandler(index, false);
        // }
      } else {
        // change back button to reconnect
        // reconnectStateHandler(index, false); ac reconnect instead
        setAccountReconnect(false);
        getHostedAuthUrl("reconnect", index);
      }
    } else {
      getHostedAuthUrl("reconnect", index);
    }
  };

  return (
    <div className="flex items-center justify-between w-[90%]">
      <div className="flex items-center gap-3">
        <img src={profilePicture} className="w-[50px] rounded-full" />
        <h4 className="text-[#0A66C2]">{fullName}</h4>
      </div>
      <div className="flex items-center gap-3">
        {validSession ? (
          <>
            <img
              alt="connected"
              width={14}
              src={statusGreenIcon}
              className="cursor-pointer hidden sm:block"
            />
          </>
        ) : accountReconnect ? (
          <>
            <span className="bg-gradient-to-r from-[#1e93fd] to-[#3154c8] text-transparent bg-clip-text uppercase text-[10px] font-semibold hidden sm:block">
              Reconnecting
            </span>
            <img
              alt="reconnecting"
              width={14}
              src={statusBlueIcon}
              className="cursor-pointer hidden sm:block"
            />
          </>
        ) : (
          <>
            <button
              className={`${styles.btn} uppercase text-[10px] font-semibold hidden sm:block`}
              onClick={() => restartAccount("reconnect", index)}
            >
              {t("RECONNECT")}
            </button>
            <img
              alt="not connected"
              width={14}
              src={statusRedIcon}
              className="cursor-pointer hidden sm:block"
            />
          </>
        )}
        {!isMember && (
          <>
            <img
              alt="edit"
              src={editIcon}
              onClick={() => modifyModalStates(index)}
              className="cursor-pointer mb-1"
            />
            <img
              alt="cross"
              width={15}
              src={crossIcon}
              className="cursor-pointer"
              onClick={() => removeAccount(index)}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default AccountListItem;
