import { getRequest, postRequest, deleteRequest } from "./Networking";
import { GET_CLIENT_TAGS, PROSPECTOR_USER_TAGS, EDIT_TAG } from "./Networking/api";
import { getUrlParamsString } from "./Utils";

const getClientTags = async (params) => {
  const paramsString = getUrlParamsString(params);
  const response = await getRequest(`${GET_CLIENT_TAGS}?${paramsString}`);
  return response.json();
};

const addProspectorTagsForUser = async (conversationUrn, params) => {
    const response = await postRequest(`${PROSPECTOR_USER_TAGS}/${conversationUrn}`, params);
    return response.json();
};

const getProspectorTagsForUser = async (conversationUrn, params) => {
    const paramsString = getUrlParamsString(params);
    const response = await getRequest(`${PROSPECTOR_USER_TAGS+"/"+conversationUrn}?${paramsString}`);
    return response.json();
};

const deleteProspectorTagForUser = async (conversationUrn, params) => {
  const response = await deleteRequest(`${PROSPECTOR_USER_TAGS}/${conversationUrn}`, params);
  return response.json();

}

const editTagForUser = async (params) => {
  const response = await postRequest(`${EDIT_TAG}`, params);
  return response.json();

}
export { editTagForUser, getClientTags, addProspectorTagsForUser, getProspectorTagsForUser, deleteProspectorTagForUser };
