import React from "react";
import { Outlet } from "react-router-dom";
import MediaQuery from "react-responsive";

import Sidebar, { SidebarItem } from "../../components/Sidebar/Sidebar";
import { useTranslation } from "react-i18next";
import Header from "./Header";
import Cookies from "js-cookie";

const AccountManagement = () => {
  const { t } = useTranslation();
  let isMember = Cookies.get("isMember") === "1" || null || "0" || undefined;
  if (isMember === true || isMember === "1" || isMember === 1) {
    isMember = true;
  } else {
    isMember = false;
  }
  return (
    <>
      <MediaQuery minWidth={1024}>
        <div className="flex">
          <Sidebar mobileViewOnly={false}>
            {!isMember && (
              <>
                <SidebarItem
                  icon="Money"
                  text={t("MY_ACCOUNT")}
                  to="/account-management/myaccount"
                />
                <SidebarItem
                  icon="Setting"
                  text={t("LINKEDIN_SETTINGS")}
                  to="/account-management/linkedin-settings"
                />
                <SidebarItem
                  icon="Team"
                  text="Team"
                  to="/account-management/team"
                />
              </>
            )}
            {isMember && (
              <SidebarItem
                icon="Setting"
                text={t("LINKEDIN_SETTINGS")}
                to="/account-management/linkedin-settings"
              />
            )}
          </Sidebar>

          <div className="w-full h-screen overflow-y-auto no-scrollbar">
            <Header />
            <div className="max-h-full">
              <Outlet />
            </div>
          </div>
        </div>
      </MediaQuery>

      <MediaQuery maxWidth={1023}>
        <div className="h-screen flex flex-col">
          <div className="w-[90%] flex items-center mx-auto">
            <div className="w-[10%] mb-2">
              <Sidebar mobileViewOnly={false}>
                {!isMember && (
                  <>
                    <SidebarItem
                      icon="Money"
                      text={t("MY_ACCOUNT")}
                      to="/account-management/myaccount"
                    />
                    <SidebarItem
                      icon="Setting"
                      text={t("LINKEDIN_SETTINGS")}
                      to="/account-management/linkedin-settings"
                    />
                    <SidebarItem
                      icon="Team"
                      text="Team"
                      to="/account-management/team"
                    />
                  </>
                )}
                {isMember && (
                  <SidebarItem
                    icon="Setting"
                    text={t("LINKEDIN_SETTINGS")}
                    to="/account-management/linkedin-settings"
                  />
                )}
              </Sidebar>
            </div>
            <div className="w-[90%]">
              <Header />
            </div>
          </div>

          <div className="w-full h-screen overflow-y-auto no-scrollbar">
            <div className="max-h-full">
              <Outlet />
            </div>
          </div>
        </div>
      </MediaQuery>
    </>
  );
};

export default AccountManagement;
