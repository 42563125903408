import {  postRequest, getRequest } from "./Networking"
import { LOGIN, SESSION_CHECK, FORGOT_PASSWORD, CHANGE_PASSWORD, LOGOUT } from "./Networking/api"



const loginApi = async (params) => {
    const response = await postRequest(`${LOGIN}`, params)
    return response.json()
}

const validSession = async () => {
    const response = await getRequest(`${SESSION_CHECK}`)
    return response.json()
}

const forgotPassword = async (params) => {
    const response = await postRequest(`${FORGOT_PASSWORD}`, params)
    return response.json()
}
const changePassword = async (params) => {
    const response = await postRequest(`${CHANGE_PASSWORD}`, params)
    return response.json()
}

const logoutApi = async () => {
    const response = await getRequest(`${LOGOUT}`)
    return response.json()
}
export {loginApi, validSession, forgotPassword, changePassword, logoutApi}