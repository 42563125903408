import React, { useState, useRef, useEffect } from "react";
import "../styles.css";
import { createPortal } from "react-dom";
import CannedResponses from "../../../../components/CannedResponses/index";
import { useSelector, useDispatch } from "react-redux";
import {
  sendMessage,
  updateMessageApi,
} from "../../../../services/Conversation";
import { addMessage, updateMessage } from "../../../../store/actions/message";
import SendMessageIcon from "../../../../assets/images/send_message.svg";
import CannedIcon from "../../../../assets/images/canned_icon.svg";
import EmojiIcon from "../../../../assets/images/emoji.svg";
import { useTranslation } from "react-i18next";
import MPEmojiPicker from "../../../../components/MPFactory/MPEmojiPicker";
import CannedResponseList from "../../../../components/CannedResponseList";
import { fetchCustomVariableValue } from "../../../../services/CannedResponses";
import { addNewMessageToConversations } from "../../../../store/actions/conversation";

const MessageBox = () => {
  const [showCannedResponse, setShowCannedResponse] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [message, setMessage] = useState("");
  const [trigger, setTrigger] = useState("");
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const messageRef = useRef(null);

  const setCannedResponseToInput = async (
    cannedResponse = "",
    reset = false
  ) => {
    let message = cannedResponse;
    message = message.replaceAll(
      "{{firstname}}",
      state?.conversation?.selectedConversation?.interlocutor?.firstName
    );
    message = message.replaceAll(
      "{{lastname}}",
      state?.conversation?.selectedConversation?.interlocutor?.lastName
    );
    message = message.replaceAll(
      "{{companyname}}",
      state?.contactInfo?.basicDetails?.companyName
    );

    if (message.includes("{{Formulaire_UMI}}")) {
      const params = {
        urn: state?.conversation?.selectedConversation?.interlocutor?.memberUrn.replace("urn:li:member:",""),
        prospectorUrn:
          state?.conversation?.selectedConversation?.prospector?.entityUrn,
      };
      const formulaireValue =
        (await fetchCustomVariableValue(params))?.url ||
        t("MISSING_CUSTOM_VARIABLE");

      message = message.replaceAll("{{Formulaire_UMI}}", formulaireValue);
    }

    if (reset) {
      setMessage(message);
    } else addMessageAtCursor(message);
  };

  const addMessageAtCursor = (message) => {
    const textBoxmessage = messageRef.current;
    const start = textBoxmessage.selectionStart;
    const end = textBoxmessage.selectionEnd;

    const currentText = textBoxmessage.value;
    const udpatedMessage =
      currentText.substring(0, start) + message + currentText.substring(end);

    setMessage(udpatedMessage);
    textBoxmessage.focus();
  };

  const onMessageSend = async () => {
    const body = {
      key: "0",
      conversationUrn: state?.conversation?.selectedConversation?.entityUrn,
      prospectorUrn:
        state?.conversation?.selectedConversation?.prospector?.entityUrn,
      message,
      linkedinUrn: state?.conversation?.selectedConversation?.prospector?.prospectorProviderId,
    };
    const messageObj = {
      author: {
        ...state?.conversation?.selectedConversation?.prospector,
        isProspector: true,
      },
      body: message,
      tempId: Math.random() * 1000,
      updateMessage: function () {
        this.isPending = false;
      },
      addEntityUrn: function (entityUrn) {
        this.entityUrn = entityUrn;
      },
      isPending: true,
      deliveredAt: Date.now(),
      converationUrn: state?.conversation?.selectedConversation?.entityUrn,
    };
    if (
      messageObj.body != null &&
      messageObj.body != undefined &&
      messageObj.body.trim() != ""
    ) {
      dispatch(addMessage(messageObj));
      setMessage("");

      const msgSent = await sendMessage(body);
      messageObj.addEntityUrn(msgSent?.msg_urn);
      dispatch(addNewMessageToConversations(messageObj));
      dispatch(updateMessage(messageObj?.tempId));
    }
  };

  const onMessageChange = (e) => {
    setMessage(e.target.value);
  };

  useEffect(() => {
    updateBoxHeight();
  }, [message]);

  const updateBoxHeight = () => {
    const messageInp = document.getElementById("message-input");
    if (messageInp) {
      messageInp.style.height = "0";
      messageInp.style.height = messageInp.scrollHeight + "px";
    }
  };

  const checkIfIos = () => {
    return (
      /iPad|iPhone|iPod/.test(navigator?.platform) ||
      navigator?.platform === "MacIntel" ||
      navigator?.platform === "MacPPC"
    );
  };

  const handleKeyDown = (e) => {
    const isIOS = checkIfIos();

    if (isIOS) {
      if (e.metaKey && e.key === "Enter") {
        e.preventDefault();
        onMessageSend();
      }
    } else if (e.ctrlKey && e.key === "Enter") {
      e.preventDefault();
      onMessageSend();
    }
  };

  useEffect(() => {
    const emojiPickerContainer = document.createElement("div");
    document.body.appendChild(emojiPickerContainer);
    return () => {
      document.body.removeChild(emojiPickerContainer);
    };
  }, []);

  const renderEmojiPicker = () => {
    if (showEmojiPicker) {
      return (
        <div
          id="mpEmojiWrapper"
          style={{
            position: "absolute",
            bottom: "63px",
            width: "80%",
            height: "50%",
          }}
        >
          <MPEmojiPicker
            setShowEmojiPicker={setShowEmojiPicker}
            onEmojiClick={onEmojiClick}
          />
        </div>
      );
    }
    return null;
  };

  const onEmojiClick = (emoji) => {
    if (emoji?.emoji) setMessage(message + emoji?.emoji);
  };

  return (
    <>
      <CannedResponseList
        onResponseSelect={setCannedResponseToInput}
        trigger={trigger}
      />

      {/* <!-- Message Input --> */}
      <div id="message-input-container">
        <div id="canned-responses-button-column">
          <button
            id="show-canned-responses"
            onClick={() => setShowCannedResponse(true)}
            title={t("OPEN_SAVED_RESPONSES")}
          >
            <img style={{ marginTop: 6 }} src={CannedIcon} />
          </button>
        </div>
        <div id="canned-responses-button-column">
          <button
            id="show-canned-responses"
            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
            title={t("OPEN_EMOJI")}
          >
            <img style={{ marginTop: 6 }} src={EmojiIcon} />
          </button>
        </div>
        <div id="message-input-column">
          <textarea
            ref={messageRef}
            id="message-input"
            value={message}
            onKeyDown={handleKeyDown}
            onChange={onMessageChange}
            rows="1"
            placeholder={t("WRITE_MESSAGE")}
          ></textarea>
        </div>
        <div id="send-message-button-column">
          <button
            id="send-message"
            title={t("SEND_MESSAGE")}
            onClick={onMessageSend}
          >
            <img src={SendMessageIcon} />
          </button>
          <div className="ctrl-enter-send">
            {!checkIfIos() ? `Ctrl` : `Cmd`} + {t("ENTER")}
          </div>
        </div>
      </div>
      {renderEmojiPicker()}
      {showCannedResponse
        ? createPortal(
            <CannedResponses
              onMovedCannedResponse={setCannedResponseToInput}
              onClose={() => {
                setTrigger((curr) => !curr);
                setShowCannedResponse(false);
              }}
              myTrigger={() => setTrigger((curr) => !curr)}
            />,
            document.body
          )
        : ""}
    </>
  );
};

export default MessageBox;
