import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./styles.css";
import LeftArrow from "../../../assets/images/left-arrow.svg";
import DefaultPP from "../../../assets/images/mirrorprofiles-default-profile-picture.png";

const CollapsedDashboardRight = ({ setIsCollapsed }) => {
  const state = useSelector((state) => state);
  return (
    <div className="collapsed-right-wrapper">
      {!!state?.conversation?.selectedConversation && (
        <div
          className="right-info-container"
          onClick={() => setIsCollapsed(false)}
        >
          <img src={LeftArrow} style={{ width: "28px", height: "28px" }} />
          <img
            src={
              state?.conversation?.selectedConversation?.interlocutor
                ?.profilePicture || DefaultPP
            }
            style={{ height: "40px", width: "40px", borderRadius: "50%" }}
          />
        </div>
      )}
    </div>
  );
};

export default CollapsedDashboardRight;
