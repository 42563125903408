import React, { useEffect } from "react";
import { stringToColour } from "../../../services/Utils";
import "../styles.css";
import WhiteClose from "../../../assets/images/white_close.svg";
import EditIcon from "../../../assets/images/tabler_edit.svg";

const TagChip = ({
  tag,
  onClick = () => {},
  showClose = false,
  onDelete = () => {},
}) => {
  return (
    <span
      className="tag-chip"
      onClick={onClick}
      style={{ background: tag?.color ? tag.color : stringToColour(tag?.title || tag?.tag?.title) }}
    >
      {tag?.title || tag?.tag?.title}
      {showClose && (
        <img
          src={WhiteClose}
          onClick={onDelete}
          style={{ marginLeft: "3px", cursor: "pointer" }}
        />
      )}
    </span>
  );
};

export default TagChip;
