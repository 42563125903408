import React, { useEffect, useState } from "react";
import { getStatusMessage } from "../../services/Miscellaneous";
import "./styles.css";
import StatusIcon from "../../assets/images/statusinfo.svg";
import CrossIcon from "../../assets/images/cross.svg";

const StatusMessage = () => {
  const [statusMessage, setStatusMessage] = useState();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    statusMessageInit();
  }, []);

  const statusMessageInit = async () => {
    const { code, result } = await getStatusMessage();
    setStatusMessage({ title: result.title, message: result.message });
    // console.log(result, code);
  };
  return statusMessage ? (
    <>
      <div className="status-icon-wrap">
        <img
          src={StatusIcon}
          className="cursor-pointer"
          onClick={() => setExpanded(!expanded)}
        />
      </div>
      {expanded ? (
        <div className="status-message-wrap">
          <div style={{ float: "right" }}>
            <img
              src={CrossIcon}
              className="cursor-pointer"
              onClick={() => setExpanded(false)}
            />
          </div>
          <div style={{ clear: "both" }}></div>
          <div className="triangle-left"></div>
          <div className="msg-heading-text">{statusMessage?.title}</div>
          <div className="msg-body-text">{statusMessage?.message}</div>
        </div>
      ) : (
        <></>
      )}
    </>
  ) : (
    <></>
  );
};

export default StatusMessage;
