import { UPDATE_SELECTED_CONVERSATION, MARK_UNREAD, NEW_MESSAGES, UPDATE_UNREAD_COUNT } from "./types";

const setSelectedConversation = (selectedConversation) => {
  return {
    type: UPDATE_SELECTED_CONVERSATION,
    payload: selectedConversation
  };
};

const markConversationUnreadInStore = (conversation) => {
  return {
    type: MARK_UNREAD,
    payload: conversation
  };
};

const addNewMessageToConversations = (newMessage) => {
  return {
    type: NEW_MESSAGES,
    payload: newMessage
  };
};

const updateUnreadCount = (newUnreadCount) => {
  return {
    type: UPDATE_UNREAD_COUNT,
    payload: newUnreadCount
  }
}


export { setSelectedConversation, markConversationUnreadInStore, addNewMessageToConversations, updateUnreadCount };
