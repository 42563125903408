import React, { useEffect } from 'react';

const MPModalHOC = (WrappedComponent) => {
  const WithEscapeKeyListener = (props) => {
    useEffect(() => {
      const handleKeyPress = (event) => {
        if (event.key === 'Escape') {
          props.onClose();
        }
      };

      window.addEventListener('keydown', handleKeyPress);

      return () => {
        window.removeEventListener('keydown', handleKeyPress);
      };
    }, []); 

    return <WrappedComponent {...props} />;
  };

  return WithEscapeKeyListener;
};

export default MPModalHOC;
