import { getRequest } from "./Networking"
import { CONTACT_INFO, QUICK_REPLIES, GET_STATUS_MESSAGE, GET_CLIENT_PROSPECTORS } from "./Networking/api"
import { getUrlParamsString } from "./Utils"

let contactInfoController = null;

const getContactInfo = async (params, signal) => {

    if(!contactInfoController) {
       contactInfoController = new AbortController()
    }else {
        contactInfoController.abort();
        contactInfoController = new AbortController()

    }
    const paramsString = getUrlParamsString(params)
    const response = await getRequest(`${CONTACT_INFO}?${paramsString}`,  contactInfoController?.signal)
    contactInfoController = null

    return response.json()
}
const getQuickReplies = async (params) => {
    const paramsString = getUrlParamsString(params)
    const response = await getRequest(`${QUICK_REPLIES}?${paramsString}`)
    return response.json()
}
const getStatusMessage = async () => {
    const response = await getRequest(`${GET_STATUS_MESSAGE}`)
    return response.json()
}

const getClientProspectors = async () => {
    const response = await getRequest(`${GET_CLIENT_PROSPECTORS}`)
    return response.json()?.then(res=>res.result)
}
export {getContactInfo, getQuickReplies, getStatusMessage, getClientProspectors}