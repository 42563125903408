import React from "react";

const MPChips = ({ selected, text, onChipClick, type }) => {
  return (
    <div
      onClick={onChipClick}
      style={selected ? { ...styles.chip, ...styles.selected } : styles.chip}
    >
      {text}
    </div>
  );
  // if (type == 1) {
  //   return (
  //     <div
  //       onClick={onChipClick}
  //       style={selected ? { ...styles.chip, ...styles.selected1 } : styles.chip}
  //     >
  //       {text}
  //     </div>
  //   );
  // }
  // else if (type == 2) {
  //   return (
  //     <div
  //       onClick={onChipClick}
  //       style={selected ? { ...styles.chip, ...styles.selected2 } : styles.chip}
  //     >
  //       {text}
  //     </div>
  //   );
  // }
  // else if (type == 3) {
  //   return (
  //     <div
  //       onClick={onChipClick}
  //       style={selected ? { ...styles.chip, ...styles.selected3 } : styles.chip}
  //     >
  //       {text}
  //     </div>
  //   );
  // }
  // else
  // {
  //   return (
  //     <div
  //       onClick={onChipClick}
  //       style={selected ? { ...styles.chip, ...styles.selected } : styles.chip}
  //     >
  //       {text}
  //     </div>
  //   );
  // }
};
const styles = {
  chip: {
    borderRadius: 15,
    border: "1px solid #1E93FD",
    padding: "9px 13px",
    marginTop: 8,
    fontSize: 10,
    fontWeight: 400,
    color: "#3154C8",
    cursor: "pointer",
  },
  selected: {
    background: "linear-gradient(90deg, #1E93FD 0%, #3154C8 100%)",
    // background: "linear-gradient(90deg, #069852 0%, #069852 100%)",
    color: "#fff",
    fontWeight: 700,
  },
  // selected1: {
  //   // background: "linear-gradient(90deg, #1E93FD 0%, #3154C8 100%)",
  //   background: "linear-gradient(90deg, #069852 0%, #069852 100%)",
  //   color: "#fff",
  //   fontWeight: 700,
  // },
  // selected2: {
  //   // background: "linear-gradient(90deg, #1E93FD 0%, #3154C8 100%)",
  //   background: "linear-gradient(90deg, #FF5454 0%, #FF5454 100%)",
  //   color: "#fff",
  //   fontWeight: 700,
  // },
  // selected3: {
  //   // background: "linear-gradient(90deg, #1E93FD 0%, #3154C8 100%)",
  //   background: "linear-gradient(90deg, #FF910F 0%, #FF910F 100%)",
  //   color: "#fff",
  //   fontWeight: 700,
  // },
};
export default MPChips;
