import React, { useState, useEffect } from "react";
import MarkConversationInterested from "./MarkConversationInterested";
import ReminderOptions from "./reminderOptions";
import {
  getConversationPreferences,
  setConversationPreferences,
} from "../../services/ConversationPreferences";
import { useSelector, useDispatch} from "react-redux";

import MarkConversationUninterested from "./MarkConversationUninterested";
import { getProspectorTagsForUser } from "../../services/Tags";
import { triggerWebhook } from "../../services/Webhook";
import { setConversationPreferenceText } from "../../store/actions/conversationPreference";

const ConversationPreferences = ({ prefTrigger, tagTrigger, messageTrigger}) => {
  let state = useSelector((state) => state);
  const dispatch = useDispatch();

  const { customDateTrigger } = useSelector(
    (state) => state.conversationPreferenceReducer
  );
  
  const [conversationPref, setConversationPref] = useState();

  useEffect(() => {
    loadConversationPreferences();
  }, [state?.conversation?.selectedConversation?.entityUrn, tagTrigger, messageTrigger, customDateTrigger]);

  const loadConversationPreferences = async () => {
    const conversationUrn =
      state?.conversation?.selectedConversation?.entityUrn;
    const prospectorUrn =
      state?.conversation?.selectedConversation?.prospector?.entityUrn;
    const query = {
      conversationUrn,
      prospectorUrn,
    };
    const conversationPrefs = await getConversationPreferences(query);
    // console.log(conversationPrefs);
    setConversationPref(conversationPrefs);
  };

  const sendWebhook = async (webhookUrl) => {

    if (!webhookUrl) return;

    const interlocutor =
      state?.conversation?.selectedConversation?.interlocutor;
    const prospector = state?.conversation?.selectedConversation?.prospector;
    const basicDetails = state?.contactInfo?.basicDetails;
    const body = {
      key: "0",
      webhook_url: webhookUrl,
      linkedin_profile_name:
        interlocutor?.firstName + " " + interlocutor?.lastName,
      linkedin_profile_url: interlocutor?.profileUrl,
      linkedin_account_sender:
        prospector?.firstName + " " + prospector?.lastName,
      linkedin_profile_title: interlocutor?.headline,
      linkedin_profile_phone: basicDetails?.phoneNumbers?.[0],
      linkedin_profile_email: basicDetails?.result,
      linkedin_conversations: state?.message?.messages,
      prospector_urn: prospector?.entityUrn,
      conversation_urn: state?.conversation?.selectedConversation?.entityUrn,
      member_urn_id:
        state?.conversation?.selectedConversation?.interlocutor?.memberUrn?.replace(
          "urn:li:member:",
          ""
        ),
    };
    const webhook = await triggerWebhook(body);
  };

  const saveConversationPreferences = async (prefs = {}) => {

    const conversationUrn =
      state?.conversation?.selectedConversation?.entityUrn;
    const prospectorUrn =
      state?.conversation?.selectedConversation?.prospector?.entityUrn;

    const body = {
      conversationUrn,
      prospectorUrn,
      ...prefs,
    };
    const newPrefs = await setConversationPreferences(body);
    setConversationPref(newPrefs);
    // prefTrigger();

    try {
      if (prefs?.isInterested == true || prefs?.isInterested == false)
      {
        await dispatch(setConversationPreferenceText({ textType: prefs?.isInterested ? "interested" : "not interested"}))
        
      }
      else
      {
        dispatch(setConversationPreferenceText({ textType: null}))
        const textType  = state?.conversationPreferenceReducer?.textType;
      }
    } catch (error) {
      
    } 

    prefTrigger();
  };

  return (
    <div style={{ display: "flex" }}>
      <MarkConversationInterested
        conversationPref={conversationPref}
        saveConversationPreferences={saveConversationPreferences}
      />
      <MarkConversationUninterested
        conversationPref={conversationPref}
        saveConversationPreferences={saveConversationPreferences}
      />
      <ReminderOptions
        conversationPref={conversationPref}
        saveConversationPreferences={saveConversationPreferences}
      />
    </div>
  );
};

export default ConversationPreferences;
