import { SET_CONVERSATION_PREFERENCE_DATE, SET_CONVERSATION_PREFERENCE_TEXT, SET_CUSTOM_DATE_TRIGGER } from '../actions/types';

const initialState = {
    conversationDate: null,
    textType: null,
    customDateTrigger: false,
};

const conversationPreferenceReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CONVERSATION_PREFERENCE_DATE:
            return {
                ...state,
                conversationDate: action.payload,
            };

        case SET_CONVERSATION_PREFERENCE_TEXT:
            return {
                ...state,
                textType: action.payload
            };

        case SET_CUSTOM_DATE_TRIGGER:
            return {
                ...state,
                customDateTrigger: action.payload
            };

        default:
            return state;
    }
};

export default conversationPreferenceReducer;