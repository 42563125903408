import React, { useEffect, useState } from "react";
import MpTextArea from "../../../../../components/MPFactory/MPTextArea";
import { useTranslation } from "react-i18next";
import MpButtonPrimary from "../../../../../components/MPFactory/MPButton/primary";
import { useDispatch, useSelector } from "react-redux";
import { updateMessageApi } from "../../../../../services/Conversation";
import { setSelectedMessage, updateMessageContent } from "../../../../../store/actions/message";
import { toast } from "react-toastify";

const EditMessageBox = ({ message, onEditCancel, onEditMessageConfirm }) => {
  const { t } = useTranslation();
  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  const [editedMessage, setEditedMessage] = useState();
  useEffect(() => {
    setEditedMessage(message?.body);

  }, [message]);

  const onUpdateMessage = async () => {
    const body = {
      key: "0",
      messageUrn: state?.message?.selectedMessage?.entityUrn,
      prospectorUrn:
        state?.conversation?.selectedConversation?.prospector?.entityUrn,
      updatedMessage: editedMessage,
    };
    const msgUpdated = await updateMessageApi(body)
    const newMessage = {...state?.message?.selectedMessage, body: editedMessage}
    dispatch(updateMessageContent(newMessage));
    dispatch(setSelectedMessage(null));
    onEditMessageConfirm()
    toast.success(t("EDIT_SUCCESS"), {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });


  }

  const onCancelPress = () => {
    dispatch(setSelectedMessage(null));
    onEditCancel()
  }

  return (
    <>
      <MpTextArea value={editedMessage} onValueChange={setEditedMessage} />
      <div className="button-group">
        <MpButtonPrimary
          customStyle={{
            fontSize: 10,
            padding: 9,
            marginLeft: 0,
            color: "#1E93FD",
            background: "transparent",
            border: "1px solid #1E93FD",
          }}
          CTA_TEXT={t("CANCEL")}
          onCtaClick={onCancelPress}
        />
        <MpButtonPrimary
          customStyle={{
            fontSize: 10,
            padding: 10,
            marginLeft: 10,
            fontWeight: 700,
          }}
          CTA_TEXT={t("REGISTER")}
          onCtaClick={onUpdateMessage}
        />
      </div>
    </>
  );
};

export default EditMessageBox;
