import React, {useState, useEffect} from "react";
import Additional from "./additional";
import Conversations from "./conversations";
import { isMobile } from "../../../services/responsiveness";
import CustomerSupport from "../../../assets/images/customer_support.svg";
import { useTranslation } from "react-i18next";

import { boot, shutdown, show, hide, onHide } from '@intercom/messenger-js-sdk'; // Import SDK methods

const DashboardLeft = ({ onCollapse }) => {
  const { t } = useTranslation();

  const [intercomVisible, setIntercomVisible] = useState(false);

  useEffect(() => {
    // Boot Intercom using SDK
    boot({
      app_id: 'vvm0a8qj', // Your app ID
      alignment: 'left', // Position chat to bottom-left
      horizontal_padding: 20, // Adjust horizontal padding
      vertical_padding: 20, // Adjust vertical padding
      hide_default_launcher: true, // Hide default launcher bubble
    });

    // Listen for when the chat window is closed
    onHide(() => {
      setIntercomVisible(false);
    });

    return () => {
      // Shut down Intercom when the component unmounts
      shutdown();
    };
  }, []);

  const toggleIntercom = () => {
    if (intercomVisible) {
      hide(); // Hide the chat window using SDK
    } else {
      show(); // Show the chat window using SDK
    }
    setIntercomVisible(!intercomVisible); // Toggle the state
  };

  return (
    <div className="width-md-100">
      {!isMobile() && (
        <div style={styles?.textStyle}>
          {t("MESSAGES")}
          <img
            src={CustomerSupport}
            style={{ height: 26, width: 26, float: "right", cursor: "pointer" }}
            // onClick={() => {
            //   window.$chatwoot.toggle();
            // }}
            onClick={toggleIntercom}
          />
        </div>
      )}
      <Conversations onCollapse={onCollapse} />
      <Additional />
    </div>
  );
};

const styles = {
  textStyle: {
    padding: "0 30px 30px",
    fontWeight: 600,
    fontFamily: "Montserrat",
    fontSize: "20px",
  },
};

export default DashboardLeft;
