import React, { useState } from "react";


import MPLogo from "../../../assets/images/mirrorProfilesLogo.png";
import "./styles.css";
import { loginApi } from "../../../services/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Header from "../../AccountManagement/Header";

import Lamps from "../../../assets/images/lamps.svg";
import SmileyDown from "../../../assets/images/smiley-down.svg";
import EndTrialBackground from "../../../assets/images/endtrial-background.svg";

const EndTrial = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const email = "romain@stepward.com"

  return (
    <div className="relative">
      <Header />
      <img src={Lamps} className="absolute w-[20%] top-5 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
      <img src={SmileyDown} className="absolute w-[4%] top-[100px] left-1/2 transform -translate-x-1/2" />
      <div className="flex justify-center items-center">
        <div className="endtrial-container w-[30%] h-[30%]">
          <div className="login-details p-8">
            <div className="forgot-subhead-endtrial">
              {t("END_TRIAL_TEXT")}
            </div>
            <button
              onClick={() => window.open(`https://checkout.mirrorprofiles.com/b/14k4k84LfcnJ4P6g0d?prefilled_email=${email}`, '_blank')}
              className="endtrial-btn">
              {t("END_TRIAL_TITLE")}
            </button>
            <button
              onClick={() => window.open(`https://mirrorprofiles.com/demo-mirror-chat/`, '_blank')}
              className="endtrial-btn-cta">
              {t("END_TRIAL_CTA")}
            </button>
          </div>
        </div>
      </div>
      <div className="endtrial-background endtrial-background-img">
        <img src={EndTrialBackground} alt="" />
      </div>
    </div>
  );  
}

export default EndTrial;
