import { getRequest, postRequest } from "./Networking"
import { getUrlParamsString } from "./Utils"
import { CANNED_RESPONSES, SET_CANNED_RESPONSES, DELETE_CANNED_RESPONSES, CUSTOM_VARIABLE_VALUE, VALID_CUSTOM_SESSION } from "./Networking/api"

const getCannedResponses = async (params) => {
    const paramsString = getUrlParamsString(params)
    const response = await getRequest(`${CANNED_RESPONSES}?${paramsString}`)
    return response.json()?.then(res=>res?.result)
}

const setCannedResponses = async (params) => {
    const response = await postRequest(`${SET_CANNED_RESPONSES}`, params)
    return response.json()?.then(res=>res?.result)
}

const deleteCannedResponse = async (params) => {
    const response = await postRequest(`${DELETE_CANNED_RESPONSES}`, params)
    return response.json()
}

const fetchCustomVariableValue = async (params) => {
    const paramsString = getUrlParamsString(params)
    const response = await getRequest(`${CUSTOM_VARIABLE_VALUE}?${paramsString}`)
    return response.json()?.then(res=>res?.result)
}

const validCustomSession = async (params) => {
    const paramsString = getUrlParamsString(params)
    const response = await getRequest(`${VALID_CUSTOM_SESSION}?${paramsString}`)
    return response.json()?.then(res=>res?.result)
}
export {getCannedResponses, setCannedResponses, deleteCannedResponse, fetchCustomVariableValue, validCustomSession}