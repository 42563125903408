import { SET_CONTACT_INFO, SET_CONTACT_INFO_LOADING, SET_CONTACT_INFO_LI_AT } from "../actions/types";

const initialState = {
  basicDetails: null,
  li_at: null,
};

const contactInfo = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONTACT_INFO:
      return {
        ...state,
        loading: false,
        basicDetails: action?.payload,
      };
    case SET_CONTACT_INFO_LOADING:
      return {
        ...state,
        loading: action?.payload,
      };
    case SET_CONTACT_INFO_LI_AT:
      return {
        ...state,
        li_at: action?.payload,
      };
    default:
      return state;
  }
};

export default contactInfo;
