import React, { useEffect, useState } from "react";
import "./styles.css";
import { useTranslation } from "react-i18next";
import { getCannedResponses } from "../../services/CannedResponses";

const CannedResponseList = ({onResponseSelect, trigger}) => {
  const { t } = useTranslation();
  const [availableResponses, setAvailableResponses] = useState([]);

  useEffect(() => {
    loadCannedResponse();
  }, [trigger]);

  const responseTile = (response) => {
    return <span className="response-tile" onClick={()=>onResponseSelect(response?.value, true)}>{response?.title}</span>;
  };
  const loadCannedResponse = async () => {
    const cannedResponsesObj = await getCannedResponses({ key: 0 });
    const cannedResponses = cannedResponsesObj.map((cr) => {
      return { title: cr?.name, value: cr?.content };
    });
    setAvailableResponses(cannedResponses);
  };
  return (
    <div className="response-list-horizontal-wrapper">
      <div className="reponse-list-title">{t("RESPONSE_TEMPLATES")}</div>
      <div style={{width: "100px", marginTop: "20px",
    whiteSpace: "nowrap"}}>
        {availableResponses?.map((response) => responseTile(response))}
      </div>
    </div>
  );
};

export default CannedResponseList;
