import React, { useState, useEffect } from 'react';
import Success from "../../assets/images/circle-tick.svg"
const InlineToast = ({message, shouldShow, updateShouldShow, style={}}) => {
    const [show, setShow] = useState(false)
    
    useEffect(()=>{
        if(shouldShow)
            setShow(shouldShow)
    }, [shouldShow])

    useEffect(()=>{
        if(show)    {
            setTimeout(() => {
                setShow(false)
            }, 2000);
        }
        else
            updateShouldShow(false)
    }, [show])
    return ( 
        show &&
        <div style={{...styles.inlineCover, ...style}}>
            <img src={Success} style={styles.tickIcon}/>
            {message}
        </div>
     );
}
 
const styles = {
    tickIcon: {
        height: 12,
        width: 12, 
        marginRight: 4
    },
    inlineCover: {
        borderRadius: 2,
        backgroundColor: "#4ECB71",
        position: "absolute",
        color: "#fff",
        fontSize: 10,
        fontWeight: 500,
        right: 5,
        padding: 4,
        display: "flex"
    }
}
export default InlineToast;