import { SET_FILTER_TAGS, SET_PROSPECTORS_LIST, SET_LAST_MESSAGE_NOT_BY_PROSPECTOR, SET_START_DATE, SET_END_DATE, SET_NUMBER_OF_MESSAGES } from "./types";

export const setFilterTagsRedux = (tags) => ({
  type: SET_FILTER_TAGS,
  payload: tags,
});

export const setProspectorsListRedux = (prospectors) => ({
    type: SET_PROSPECTORS_LIST,
    payload: prospectors
});

export const setLastMessageNotByProspectorRedux = (booleanValue) => ({
    type: SET_LAST_MESSAGE_NOT_BY_PROSPECTOR,
    payload: booleanValue
});

export const setStartDateRedux = (startDate) => ({
    type: SET_START_DATE,
    payload: startDate
});

export const setEndDateRedux = (endDate) => ({
    type: SET_END_DATE,
    payload: endDate
});


export const setNumberOfMessagesRedux = (number) => ({
    type: SET_NUMBER_OF_MESSAGES,
    payload: number
});




