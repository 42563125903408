import React, { useState, useEffect } from "react";
import DefaultPP from "../../../../../../assets/images/mirrorprofiles-default-profile-picture.png";
import "./styles.css";
import { useSelector, useDispatch } from "react-redux";
import { formattedUnixTime } from "../../../../../../services/Utils";
import { updateUnreadCount } from "../../../../../../store/actions/conversation";
import alarm from "../../../../../../assets/images/alarm.svg";

import i18n from 'i18next';

const ConversationCard = ({ conversation, setSelectedConversation }) => {
  const state = useSelector((state) => state);
  const [isClicked, setIsClicked] = useState(false);

  const [isMarkedUnread, setIsMarkedUnread] = useState(false);
  const [newMessage, setNewMessage] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (state.conversation?.markedUnread?.includes(conversation?.entityUrn))
      setIsMarkedUnread(true);
  }, [state.conversation?.markedUnread]);

  useEffect(() => {
    if (
      conversation?.entityUrn ==
      state?.conversation?.newMessages?.converationUrn
    ) {
      setNewMessage(state?.conversation?.newMessages);
    }
  }, [state?.conversation?.newMessages]);

  const onConversationCardClick = () => {
    setIsClicked(true);
    setSelectedConversation(conversation);
    if (conversation?.read == false) {
      const newCount =
        state?.conversation?.unreadCount - 1 >= 0
          ? state?.conversation?.unreadCount - 1
          : 0;
      dispatch(updateUnreadCount(newCount));
    }
  };
  return (
    <ul id="conversations">
      <li
        className={`conversation-item ${
          state?.conversation?.selectedConversation?.entityUrn ==
          conversation?.entityUrn
            ? "selected"
            : conversation?.read && !isMarkedUnread
            ? "seperator"
            : ""
        } ${
          (!conversation?.read && !isClicked) || isMarkedUnread ? "unread" : ""
        }`}
        onClick={onConversationCardClick}
      >
        <div class="prospect-profile-picture-column">
          <div className="selected-img-wrapper">
            <i className="fa-solid fa-check-circle check-icon"></i>
          </div>
          <img
            class="prospect-profile-picture"
            src={conversation?.interlocutor?.profilePicture || DefaultPP}
          />
        </div>
        <div class="conversation-column">
          <div class="conversation-header">
            <div class="participants-column">
              <div class="prospect-name-row">
                {conversation?.interlocutor?.firstName +
                  " " +
                  conversation?.interlocutor?.lastName}
              </div>
              <div class="prospector-name-row">
                {conversation?.prospector?.firstName +
                  " " +
                  conversation?.prospector?.lastName}
              </div>
            </div>
            <div class="time-column">
              {formattedUnixTime(
                (newMessage?.deliveredAt || conversation?.deliveredAt), i18n.language
              )}
            </div>
          </div>
          <div class="conversation-bottom">
            <div class="message-snippet">
              {newMessage?.body || conversation?.messageSnippet}
            </div>
            <div class="notification-column">
              {conversation.isFromReminder ? (
                <img width={16} src={alarm} className="bg-gradient-to-b from-[#1E93FD] to-[#3153C7] p-1 rounded-full" />
              ) : (
                <span class="notification-counter">
                  {conversation?.unreadCount || 1}
                </span>
              )}
            </div>
          </div>
        </div>
      </li>
    </ul>
  );
};

export default ConversationCard;
