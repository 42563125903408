import styles from './styles.module.css'

const StyledBox = ({children}) => {
  return (
    <div className={`${styles["border-styling"]} px-[25px] py-[35px] sm:px-[60px] sm:py-[40px]`}>
        {children}
    </div>
  )
}

export default StyledBox