import React from "react";
import MPChips from "../../MPFactory/MPChips";
import { useTranslation } from "react-i18next";

const MarkConversationInterested = ({
  saveConversationPreferences,
  conversationPref,
}) => {
  const { t } = useTranslation();

  const onInterested = () => {
    const isInterested = conversationPref?.isInterested === true ? null : true;
    const prefs = {
      isInterested: isInterested,
      reminderDuration: conversationPref?.reminderDuration,
    };
    saveConversationPreferences(prefs);
  };
  return (
    <div style={styles.wrapper}>
      <MPChips
        onChipClick={onInterested}
        selected={conversationPref?.isInterested}
        text={t("APPOINTMENT_MADE")}
      />
    </div>
  );
};
const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "-8px",
    marginRight: "5px",
  },
};
export default MarkConversationInterested;
