import React from 'react'
import ConversationCard from './conversation-card'
import "./styles.css"

const ConversationsList = ({conversationList, setSelectedConversation}) => {
    return ( <div className="conversationWrapper">
        {
            conversationList?.map(conversation=><ConversationCard setSelectedConversation={setSelectedConversation} conversation={conversation}/>)
        }
    </div> );
}
 
export default ConversationsList;