import React from "react";
import "./styles.css"
const MpTextArea = ({ value, handleKeyDown, onValueChange, placeholder }) => {
  return (
    <textarea
      id="mp-textarea-input"
      value={value}
      onKeyDown={handleKeyDown}
      onChange={e=>onValueChange(e.target.value)}
      placeholder={placeholder}
    ></textarea>
  );
};

export default MpTextArea;
