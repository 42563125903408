import React, { useEffect, useState, useRef } from "react";
import "../styles.css";
import { useSelector, useDispatch } from "react-redux";
import { getContactInfo } from "../../../../services/Miscellaneous";
import {
  getConversation,
  markConversationUnread,
} from "../../../../services/Conversation";
import Messages from "./Messages";
import { setContactInfo } from "../../../../store/actions/contactInfo";
import { setMessages as setMessagesToStore } from "../../../../store/actions/message";
import Loader from "../../../../components/Loader";
import MessageBox from "../MessageBox";
import { markConversationUnreadInStore } from "../../../../store/actions/conversation";
import { useTranslation } from "react-i18next";
import DefaultPP from "../../../../assets/images/mirrorprofiles-default-profile-picture.png";
import ConversationPreferences from "../../../../components/ConversationPreferences";
import BlockAccount from "../../../../components/BlockAccount";

import UnreadIcon from "../../../../assets/images/conversationUnread.svg";
import BlockIcon from "../../../../assets/images/userBlocked.svg";

const Conversation = ({ onCollapse, setShowProfileInfo, prefTrigger, tagTrigger }) => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const conversationRef = useRef(null);

  const [selectedConversation, setSelectedConversation] = useState({});
  const [loadingConversation, setLoadingConversation] = useState(false);
  const [showMarkUnread, setShowMarkUnread] = useState(false);
  const [messageTrigger, setMessageTrigger] = useState(false);

  const [messages, setMessages] = useState([]);
  useEffect(() => {
    if (state?.conversation?.selectedConversation) {
      setSelectedConversation(state?.conversation?.selectedConversation);
      onConversationChange(state?.conversation?.selectedConversation);
    }
  }, [state?.conversation?.selectedConversation?.entityUrn]);
  useEffect(() => {
    if (state?.message?.messages) {
      setMessages(state?.message?.messages);
      adjustConversationScroll();
    }
  }, [state?.message?.messages]);
  
  const onConversationChange = (conversation) => {
    loadContactInfo(conversation);
    loadConversation(conversation);
  };

  const loadContactInfo = async (conversation) => {
    const prospectorUrn = conversation?.prospector?.entityUrn;
    const urn = conversation?.interlocutor?.entityUrn;
    const contactInfo = await getContactInfo({ key: 0, urn, prospectorUrn });

    dispatch(setContactInfo(contactInfo));
  };

  const loadConversation = async (conversation) => {
    setLoadingConversation(true);
    const prospectorUrn = conversation?.prospector?.entityUrn;
    const conversationProviderId = conversation?.conversation_provider_id || null;
    const isFromReminder = conversation?.isFromReminder || null;
    const urn = conversation?.entityUrn;
    const prospectorProviderId = conversation?.prospector.prospectorProviderId || null;
    const isUnread = conversation.read || null;
    const conversationDetails = await getConversation({
      key: 0,
      urn,
      prospectorUrn,
      isFromReminder,
      conversationProviderId,
      prospectorProviderId,
      isUnread: !conversation.read
      
    });

    setMessageTrigger((item) => !item);
    let modifiedMessages = [...conversationDetails?.messages].map(message => ({
      ...message,
      author: message.author || conversation?.interlocutor
    }));
    dispatch(setMessagesToStore(modifiedMessages.reverse()));
    setLoadingConversation(false);
    adjustConversationScroll();
  };
  const adjustConversationScroll = () => {
    setTimeout(() => {
      conversationRef?.current?.scrollTo(
        0,
        conversationRef?.current?.scrollHeight
      );
    }, 10);
  };
  const onConversationUnread = () => {
    dispatch(markConversationUnreadInStore(selectedConversation));
    const body = {
      account_id: selectedConversation?.prospector?.entityUrn,
      conversationProviderId: selectedConversation?.conversation_provider_id || null,
      prospectorProviderId: selectedConversation?.prospector.prospectorProviderId || null,
    };
    markConversationUnread(body);
  };

  return (
    <div id="conversation-container" onClick={() => setShowMarkUnread(false)}>
      <div id="conversation-top-bar">
        <button id="close-conversation" onClick={() => onCollapse("center")}>
          <i class="fa-solid fa-chevron-left"></i>
        </button>
        <div id="conversation-prospect-informations">
          <div id="conversation-prospect-informations-left">
            <a
              id="conversation-prospect-profile-redirection"
              target="_blank"
              href={selectedConversation?.interlocutor?.profileUrl}
            >
              <img
                id="conversation-prospect-profile-picture"
                src={selectedConversation?.interlocutor?.profilePicture || DefaultPP}
              />
            </a>
          </div>
          <div
            id="conversation-prospect-informations-right"
            onClick={() => setShowProfileInfo(true)}
          >
            <div>
              <span id="conversation-prospect-name">
                {selectedConversation?.interlocutor?.firstName +
                  " " +
                  selectedConversation?.interlocutor?.lastName}
              </span>
            </div>
            <div>
              <span id="conversation-prospector-name">
                {selectedConversation?.prospector?.firstName +
                  " " +
                  selectedConversation?.prospector?.lastName}
              </span>
            </div>
            <div style={{maxWidth: 400}}>
              <span id="conversation-prospect-headline">
                {selectedConversation?.interlocutor?.headline}
              </span>
            </div>
          </div>
        </div>
        <ConversationPreferences prefTrigger={prefTrigger} tagTrigger={tagTrigger} messageTrigger={messageTrigger}/>
       <button
          id="conversation-more-actions"
          title="Plus"
          onClick={(e) => {
            e.stopPropagation();
            setShowMarkUnread(true);
          }}
        >
          <i class="fa-solid fa-ellipsis-vertical"></i>
        </button>
        {showMarkUnread ? (
          <ul id="conversation-more-actions-dropdown">
            {<li class="action-item" style={{borderBottom: "1px solid #ccc", paddingBottom: 5}}>
              <img src={UnreadIcon} />
              <button
                id="mark-conversation-unread"
                onClick={onConversationUnread}
                style={{color: "#1E93FD", marginLeft: 6}}
              >
                {t("MARK_UNREAD")}
              </button>
            </li>
          /*<li class="action-item"> 
            <img src={BlockIcon} />

            <BlockAccount />

          </li>*/}
          </ul>
        ) : (
          ""
        )}
      </div>
      <ul id="conversation" ref={conversationRef}>
        {loadingConversation ? <Loader /> : <Messages messages={messages} />}
      </ul>
      <MessageBox />
    </div>
  );
};

export default Conversation;
