const getRequest = (url, signal=null) => {
    return fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {'Content-Type': 'application/json'},
        signal
    })
    .then((response) => {
        if(response.status==401)    {
            window.location.href="/login"
            return
        }
        return response;
    })
    .catch((error) => {
        // Handle request errors
        console.error('Error:', error);
        throw error;
    });
} 

const postRequest = (url, body, signal=null) => {
    return fetch(url, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify(body),
        signal
    })
    .then((response) => {
        if(response.status==401 && !url.includes("login"))    {
            window.location.href="/login"
            return
        }
        return response;
    })
    .catch((error) => {
        // Handle request errors
        console.error('Error:', error);
        throw error;
    });
}

const deleteRequest = (url, body) => {
    return fetch(url, {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify(body)
    })
    .then((response) => {
        if(response.status==401)    {
            window.location.href="/login"
            return
        }
        return response;
    })
    .catch((error) => {
        // Handle request errors
        console.error('Error:', error);
        throw error;
    });
}

export {getRequest, postRequest, deleteRequest}