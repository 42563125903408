/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import Mobile from "../../../assets/images/icons_mobile.svg";
import Copy from "../../../assets/images/copy-icon.svg";
import CopyWhite from "../../../assets/images/copy-icon-white.svg";
import Location from "../../../assets/images/location.svg";
import Email from "../../../assets/images/email.svg";
import Linkedin from "../../../assets/images/logo-linkedin.svg";
import Linkedin_blue from "../../../assets/images/logo-linkedin-blue.svg";
import Linkedin_bw from "../../../assets/images/linkedin_bw.svg";
import Loader from "../../../components/Loader";
import { useSelector, useDispatch } from "react-redux";
import DefaultPP from "../../../assets/images/mirrorprofiles-default-profile-picture.png";
import InlineToast from "../../../components/InlineToast";
import { useTranslation } from "react-i18next";
import {
  ACCOUNT_INFO,
  ACCOUNT_INFO_OFFLINE,
} from "../../../services/Networking/api";
import {
  addInterlocutorInfos,
  getInterlocutorInfos,
} from "../../../services/Interlocutor";
import { getRequest, postRequest } from "../../../services/Networking";
import { setContactInfo } from "../../../store/actions/contactInfo";
import bellIcon from "../../../assets/images/bell.svg";
import blackCrossIcon from "../../../assets/images/cross_black_icon.svg";

import conversation from "../../../store/reducers/conversation";
import { SET_CUSTOM_REMINDER_DATE } from "../../../services/Networking/api"; import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { setConversationPreferenceDate, setCustomDateTrigger } from "../../../store/actions/conversationPreference";


const ProfileInfo = () => {
  const state = useSelector((state) => state);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [toastCopyPhone, setToastCopyPhone] = useState(false);
  const [toastCopyEmail, setToastCopyEmail] = useState(false);
  const [toastCopyLI, setToastCopyLI] = useState(false);
  const [profileInfos, setProfileInfos] = useState(false);
  const [loadingProfileInfo, setLoadingProfileInfo] = useState(false);


  const { conversationDate, customDateTrigger } = useSelector(
    (state) => state.conversationPreferenceReducer.conversationDate
  );



  const setCustomerReminderDate = async (date = null) => {
    const conversationUrn = state?.conversation?.selectedConversation?.id;
    const prospectorUrn = state?.conversation?.selectedConversation?.account_id;
    // vv API CALL
    // for custom date, use the date from date picker and pass it as a parameter to this function
    // or any other workflow that you feel would be better

    // conversationUrn, prospectorUrn are good to go, just need to add dateParameter
    // date format needed: "2024-05-08T19:00:00.000Z" (YES it is a string)
    // copy the code from previous API and see how we are converting the date into this format

    // uncomment the API and test. If it works, then you remove the console.logs etc etc

    const params = {
      conversationUrn,
      prospectorUrn,
      customReminderDate: date,
    }

    let response = null;
    try {
      response = await postRequest(SET_CUSTOM_REMINDER_DATE, params);
      const res = await response.json();

      if (res?.code === 200) {
        dispatch(setConversationPreferenceDate({ conversationDate: formatDate(date) }));
        dispatch(setCustomDateTrigger({ customDateTrigger: !customDateTrigger }));
      }

    } catch (error) {
      console.log("error", error);
    }
    return;
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  const handleCustomDate = () => {
    setIsCalendarOpen((curr) => !curr);
    setCustomerReminderDate(selectedDate.toISOString());
  }

  const getProfileInfos = async () => {
    setLoadingProfileInfo(true);
    try {
      const response = await getRequest(
        `${ACCOUNT_INFO}/${state?.conversation?.selectedConversation?.interlocutor.providorId}/${state?.conversation?.selectedConversation?.prospector.entityUrn}`
      );
      const data = await response.json();
      console.log("data", data);
      setProfileInfos(data);
      addInterlocutorInfos(
        state?.conversation?.selectedConversation?.entityUrn,
        data
      );
      dispatch(setContactInfo(data));
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoadingProfileInfo(false);
    }
  };

  const getInterlocutor = async () => {
    const conversationUrn =
      state?.conversation?.selectedConversation?.entityUrn;
    const params = {
      conversationUrn,
    };
    const response = await getRequest(
      `${ACCOUNT_INFO_OFFLINE}/${state?.conversation?.selectedConversation?.interlocutor.providorId}/${state?.conversation?.selectedConversation?.prospector.entityUrn}`
    );
    const data = await response.json();
    if (response) {
      setProfileInfos(data);
      dispatch(setContactInfo(data));
    }
  };

  useEffect(() => {
    setProfileInfos(null);
    getInterlocutor();
  }, [
    state?.conversation?.selectedConversation?.interlocutor.providorId,
    state?.conversation?.selectedConversation?.prospector.entityUrn,
  ]);

  useEffect(() => {
    if (conversationDate) {
      const [day, month, year] = conversationDate.split("-");
      const parsedDate = new Date(`${year}-${month}-${day}`);
      setSelectedDate(parsedDate);
    }
  }, [
    state?.conversation?.selectedConversation?.interlocutor.providorId,
    state?.conversation?.selectedConversation?.prospector.entityUrn,
    conversationDate
  ]);

  const handleCalendarChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <div className="profile-basic-info">
      {/* <img
        src={
          state?.conversation?.selectedConversation?.interlocutor
            ?.profilePicture || DefaultPP
        }
        className="user-icon"
      /> */}

      <a
        href={
          state?.conversation?.selectedConversation?.interlocutor?.profileUrl
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={
            state?.conversation?.selectedConversation?.interlocutor
              ?.profilePicture || DefaultPP
          }
          className="user-icon"
          alt="User Profile"
        />
      </a>

      <div className="userinfo-interlocutor">
        {state?.conversation?.selectedConversation?.interlocutor?.firstName +
          " " +
          state?.conversation?.selectedConversation?.interlocutor?.lastName}
      </div>
      <div className="userinfo-prospector">
        <span>
          {state?.conversation?.selectedConversation?.prospector?.firstName +
            " " +
            state?.conversation?.selectedConversation?.prospector?.lastName}
        </span>
      </div>
      <div className="userinfo-title">
        {state?.conversation?.selectedConversation?.interlocutor?.headline}
      </div>
      {state?.contactInfo?.loading ? (
        <Loader />
      ) : (
        <>
          {loadingProfileInfo ? (
            <Loader />
          ) : profileInfos?.result ? (
            <div className="userinfo-email">
              <img src={Mobile} style={{ width: "21px", height: "21px" }} />
              <span>
                {profileInfos?.result?.phone?.includes("provided")
                  ? t("NO_PHONE")
                  : profileInfos?.result?.phone}
              </span>
              <img
                src={
                  profileInfos?.result?.phone?.includes("provided")
                    ? CopyWhite
                    : Copy
                }
                className="copy-icon"
                onClick={() => {
                  navigator.clipboard.writeText(
                    profileInfos?.result?.phone || ""
                  );
                  setToastCopyPhone(true);
                }}
              />
              <InlineToast
                shouldShow={toastCopyPhone}
                updateShouldShow={setToastCopyPhone}
                message={t("COPY")}
              />
            </div>
          ) : (
            <div className="userinfo-email" onClick={getProfileInfos}>
              <img src={Mobile} style={{ width: "21px", height: "21px" }} />
              <div
                className="blur-text-placeholder"
                title="Cliquer pour réveler"
              ></div>
            </div>
          )}
          {loadingProfileInfo ? (
            <div></div> //<Loader />
          ) : profileInfos?.result ? (
            <div className="userinfo-email">
              <img src={Email} style={{ width: "21px", height: "21px" }} />
              <span>
                {profileInfos?.result?.email?.includes("provided")
                  ? t("NO_MAIL")
                  : profileInfos?.result?.email}
              </span>
              <img
                src={
                  profileInfos?.result?.email?.includes("provided")
                    ? CopyWhite
                    : Copy
                }
                className="copy-icon"
                onClick={() => {
                  navigator.clipboard.writeText(
                    profileInfos?.result?.email || ""
                  );
                  setToastCopyEmail(true);
                }}
              />
              <InlineToast
                shouldShow={toastCopyEmail}
                updateShouldShow={setToastCopyEmail}
                message={t("COPY")}
              />
            </div>
          ) : (
            <div className="userinfo-email" onClick={getProfileInfos}>
              <img src={Email} style={{ width: "21px", height: "21px" }} />
              <div
                className="blur-text-placeholder"
                title="Cliquer pour réveler"
              ></div>
            </div>
          )}
          {loadingProfileInfo ? (
            <div></div> //<Loader />
          ) : profileInfos?.result ? (
            <div className="userinfo-email">
              <img src={Location} style={{ width: "21px", height: "21px" }} />
              <span>{profileInfos?.result?.location}</span>
              <img src={CopyWhite} />
            </div>
          ) : (
            <div className="userinfo-email" onClick={getProfileInfos}>
              <img src={Location} style={{ width: "21px", height: "21px" }} />
              <div
                className="blur-text-placeholder"
                title="Cliquer pour réveler"
              ></div>
            </div>
          )}
          {state?.conversation?.selectedConversation?.interlocutor
            ?.profileUrl ? (
            <div className="userinfo-email">
              <img
                src={Linkedin_bw}
                style={{ width: "21px", height: "21px" }}
              />
              <span>
                {state?.conversation?.selectedConversation?.interlocutor?.profileUrl.replace(
                  "https://www.",
                  ""
                )}
              </span>
              <img
                src={Copy}
                className="copy-icon"
                onClick={() => {
                  navigator.clipboard.writeText(
                    state?.conversation?.selectedConversation?.interlocutor
                      ?.profileUrl
                  );
                  setToastCopyLI(true);
                }}
              />
              <InlineToast
                shouldShow={toastCopyLI}
                updateShouldShow={setToastCopyLI}
                message={t("COPY")}
              />
            </div>
          ) : (
            ""
          )}
        </>
      )}
      <a
        className="userinfo-linkedin-cta"
        href={
          state?.conversation?.selectedConversation?.interlocutor?.profileUrl
        }
        target="_blank"
      >
        <img
          src={Linkedin}
          style={{ width: "17px", height: "17px", marginRight: "14px" }}
        />
        Linkedin profile
      </a>
      {conversationDate !== null && (
        <div className="relative flex items-center justify-between px-3 py-2 bg-[#EFEFEF] rounded-[5px] mt-5">
          <span className="text-[13px]">Follow-up</span>
          <div onClick={() => setIsCalendarOpen((curr) => !curr)} class="flex items-center gap-[6px] cursor-pointer">
            <img src={bellIcon} alt="Bell" />
            <span className="text-[13px] font-bold">{conversationDate}</span>
          </div>
          {isCalendarOpen && (
            <div className="w-[270px] absolute top-11 left-[-8px] px-1 py-2  bg-white shadow mt-1 z-50 border border-solid border-gray-300">
              <div className="w-full flex items-center justify-between mb-2">
                <button className="px-3 py-2 bg-gradient-to-r from-[#1e93fd] to-[#3154c8] cursor-pointer text-[0.6em] text-white rounded-[50px] uppercase font-semibold border-none" onClick={() => handleCustomDate()}>Set Date</button>
                <img
                  src={blackCrossIcon}
                  width={15}
                  onClick={() => setIsCalendarOpen((curr) => !curr)}
                  className="cursor-pointer"
                />
              
              </div>
              <Calendar value={selectedDate} onChange={(value) => handleCalendarChange(value)} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ProfileInfo;
