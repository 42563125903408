import React, { useEffect, useState, useRef } from "react";
import "./styles.css";
import {
  getCannedResponses,
  setCannedResponses,
  deleteCannedResponse,
  validCustomSession,
} from "../../services/CannedResponses";
import CannedResponseAdd from "../../assets/images/canned_add.svg";
import SearchIcon from "../../assets/images/search.svg";
import { useTranslation } from "react-i18next";
import MPModalHOC from "../MPFactory/MPModalHOC";
import { useSelector } from "react-redux";

const CannedResponses = ({ onClose, onMovedCannedResponse, myTrigger }) => {
  const { t } = useTranslation();
  const state = useSelector((state) => state);

  const [searchTerm, setSearchTerm] = useState("");
  const [availableResponses, setAvailableResponses] = useState([]);
  const [newCannedResponse, setNewCannedResponse] = useState({
    title: "",
    value: "",
  });
  const [collapsedNew, setCollapsedNew] = useState(true);
  const [showCustomVariable, setShowCustomVariable] = useState(false);
  const crTextBoxRef = useRef(null);
  const [CannedResponseChanged, setCannedResponseChanged] = useState(false);
  
  useEffect(() => {
    loadCannedResponse();
    checkValidSession()
  }, [CannedResponseChanged]);

  const loadCannedResponse = async () => {
    const cannedResponsesObj = await getCannedResponses({ key: 0 });
    const cannedResponses = cannedResponsesObj.map((cr) => {
      return { crId: cr?.id, title: cr?.name, value: cr?.content };
    });
    setAvailableResponses(cannedResponses);
  };
  const appendToNewCannedResponse = (value) => {
    const textBoxmessage = crTextBoxRef.current;
    const start = textBoxmessage.selectionStart;
    const end = textBoxmessage.selectionEnd;

    const currentText = textBoxmessage.value;
    const updatedResponse =
      currentText.substring(0, start) + value + currentText.substring(end);

    setNewCannedResponse({
      crId: newCannedResponse?.crId,
      title: newCannedResponse?.title,
      value: updatedResponse,
    });

    textBoxmessage.focus();
  };

  const checkValidSession = async () => {
    const params = {
      prospectorUrn: state?.conversation?.selectedConversation?.prospector?.entityUrn
    }
    const validSessions = await validCustomSession(params)
    setShowCustomVariable(!!validSessions?.isValid)
  }
  const onDeleteCannedResponse = async () => {
    const _deleted = await deleteCannedResponse({
      key: "0",
      crId: newCannedResponse?.crId,
    });
    if (_deleted) {
      const newAvailableResponses = availableResponses.filter(
        (rs) => rs.crId != newCannedResponse?.crId
      );
      setAvailableResponses(newAvailableResponses);
      setCannedResponseChanged((curr) => !curr);
      setNewCannedResponse({ title: "", value: "" });
      setCollapsedNew(true);
      myTrigger((curr) => !curr)
    }
  };

  const onSaveCannedResponse = async () => {
    const res = await setCannedResponses({
      key: "0",
      name: newCannedResponse?.title,
      content: newCannedResponse?.value,
      crId: newCannedResponse?.crId,
    });
    if (!res?.is_edit)
    {
      setAvailableResponses([
        ...availableResponses,
        {
          crId: res?.msg?.id,
          title: newCannedResponse?.title,
          value: newCannedResponse?.value,
        },
      ]);
      setCannedResponseChanged((curr) => !curr);
    }
      // add trigger here

    else {
      const updatedAvailableResponses = availableResponses.map((resp) => {
        if (resp.crId == res?.msg?.id)
          return {
            crId: resp.crId,
            title: newCannedResponse?.title,
            value: newCannedResponse?.value,
          };
        else return resp;
      });
      setAvailableResponses(updatedAvailableResponses);
      // add trigger Here
      setCannedResponseChanged((curr) => !curr); 
    }
  };

  const onMoveCannedResponse = () => {
    onMovedCannedResponse(newCannedResponse?.value);
    onClose();
  };

  const onCreateCannedResponse = () => {
    setNewCannedResponse({ title: "", value: "" });
    setCollapsedNew(false);
  };

  const renderCustomVariable = () => {

    return (showCustomVariable ?<button
      class="add-canned-response-variable"
      onClick={() => appendToNewCannedResponse("{{Formulaire_UMI}}")}
      variable-name="Formulaire_UMI"
    >
      {t("Formulaire_UMI")}
    </button>:<></>);
  };

  const filteredResponses = availableResponses.filter((response) =>
  response.title.toLowerCase().includes(searchTerm.toLowerCase())
);

  return (
    <>
      <div id="canned-responses-layer">
        <div id="canned-responses-container">
          <div id="canned-responses-header">
            <span id="canned-responses-label">{t("SAVED_RESPONSES")}</span>
            <button id="close-canned-responses" onClick={onClose}>
              <i class="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div id="canned-responses-body">
            <div id="canned-responses-column">
              <div
                className="create-canned-response-container"
                onClick={onCreateCannedResponse}
                title={t("CREATE_ANSWER")}
              >
                <span className="add-cr-text">{t("ADD_RESPONSE")}</span>
                <img src={CannedResponseAdd} />
              </div>
              <div className="canned-search">
                <img src={SearchIcon} />
                <input
                  type="text"
                  placeholder={t("SEARCH")}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              {availableResponses?.length ? (
                <ul id="canned-responses">
                  {filteredResponses.map((response) => (
                    <li
                      class={
                        (newCannedResponse?.crId == response.crId
                          ? "selected"
                          : "") + " canned-response-item"
                      }
                      onClick={() => {
                        setCollapsedNew(false);
                        setNewCannedResponse({
                          crId: response?.crId,
                          title: response.title,
                          value: response?.value,
                        });
                      }}
                    >
                      {response?.title}
                    </li>
                  ))}
                </ul>
              ) : (
                <></>
              )}
            </div>
            <div id="canned-response-container">
              {!collapsedNew ? (
                <>
                  <div id="canned-response-preview">
                    <input
                      id="canned-response-name-input"
                      placeholder={t("CANNED_RESPONSE_TITLE_PLACEHOLDER")}
                      value={newCannedResponse?.title}
                      onChange={(e) =>
                        setNewCannedResponse({
                          crId: newCannedResponse?.crId || null,
                          title: e.target.value,
                          value: newCannedResponse?.value,
                        })
                      }
                    ></input>
                    <textarea
                      ref={crTextBoxRef}
                      id="canned-response-input"
                      placeholder={t("CANNED_RESPONSE_BODY_PLACEHOLDER")}
                      value={newCannedResponse?.value}
                      onChange={(e) =>
                        setNewCannedResponse({
                          crId: newCannedResponse?.crId,
                          title: newCannedResponse?.title,
                          value: e.target.value,
                        })
                      }
                    ></textarea>
                    <div id="canned-response-variables-bar">
                      <button
                        class="add-canned-response-variable"
                        onClick={() =>
                          appendToNewCannedResponse("{{firstname}}")
                        }
                        variable-name="firstName"
                      >
                        {t("FIRST_NAME")}
                      </button>
                      <button
                        class="add-canned-response-variable"
                        onClick={() =>
                          appendToNewCannedResponse("{{lastname}}")
                        }
                        variable-name="lastName"
                      >
                        {t("LAST_NAME")}
                      </button>
                      {renderCustomVariable()}
                    </div>
                  </div>
                  <div id="canned-response-action-bar">
                    <button
                      id="delete-canned-response"
                      title={t("DELETE_ANSWER")}
                      onClick={onDeleteCannedResponse}
                    >
                      <i class="fa-solid fa-trash"></i>
                    </button>
                    {/* <button
                      id="save-canned-response"
                      title={t("SAVE_ANSWER")}
                      onClick={onSaveCannedResponse}
                    >
                      <i class="fa-solid fa-floppy-disk"></i>
                    </button> */}
                    <button
                      id="add-canned-response"
                      title={t("SELECT_ANSWER")}
                      onClick={() => {
                        onSaveCannedResponse()
                        onClose()
                      }}
                    >
                      {t("SAVE")}
                    </button>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MPModalHOC(CannedResponses);