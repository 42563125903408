import React, { useState } from "react";
import MPLogo from "../../../assets/images/mirrorProfilesLogo.png";
import "../login/styles.css";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../../../services/auth";
import { toast } from "react-toastify";
import BackArrow from "../../../assets/images/left-arrow-blue.svg";
import { useTranslation } from "react-i18next";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [email, setEmail] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const onSend = async () => {
    const { code, result } = await forgotPassword({
      email,
    });
    if (code == 200) {
      toast.success("Reset Link will be sent to your email");
    } else {
      toast.error("Something went wrong");
    }
  };
  const onRetour = () => {
    navigate("/login");
  };
  return (
    <div className="login-container">
      <img src={MPLogo} className="logo" />
      <div className="login-details">
        <div className="login-head">{t("FORGOT_PWD_TITLE")}</div>
        <div className="forgot-subhead">
        {t("FORGOT_PWD_SUBTITLE")}
        </div>
        <div className="width-wrap" style={{ textAlign: "left" }}>
          <div className="label-wrap">
            <label htmlFor="email">Email:</label>
          </div>
          <input
            type="email"
            id="email"
            value={email}
            placeholder="example@example.com"
            onChange={handleEmailChange}
          />
        </div>
        <button onClick={onSend} className="login-btn">
          {t("FORGOT_PWD_CTA")}
        </button>
        <br />

        <button onClick={onRetour} className="retour-btn">
          <img
            src={BackArrow}
            style={{ marginRight: 13, position: "relative", top: 2 }}
          />
          {t("BACK_CTA")}
        </button>
      </div>
    </div>
  );
};

export default ForgotPassword;
