import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import FranceLogo from "../../assets/images/france-flag-icon.svg";
import UKLogo from "../../assets/images/uk-flag-icon.svg";
import DropdownArrow from "../../assets/images/dropdown-arrow.svg";
import "./styles.css";
const languageMap = {
  en: { label: "English", logo: UKLogo, dir: "ltr", active: true },
  fr: { label: "Français", logo: FranceLogo, dir: "ltr", active: false },
};

const SelectLanguage = () => {
  useEffect(() => {
    const selectedLanguage = sessionStorage.getItem("i18nextLng");
    i18next.changeLanguage(selectedLanguage);
  }, []);
  const [selectedLanguage, setSelectedLanguage] = React.useState(
    sessionStorage.getItem("i18nextLng") || i18next.language
  );
  const [expanded, setExpanded] = React.useState(false);

  return (
    <div className="select_language_wrapper">
      <div className="selected_label" onClick={() => setExpanded(!expanded)}>
        <img src={languageMap?.[selectedLanguage]?.logo} />
        <img src={DropdownArrow} />
      </div>
      {expanded && (
        <div className="selected_language_expanded_list">
          {Object.keys(languageMap)?.map((item) => (
            <div
              key={item}
              onClick={() => {
                sessionStorage.setItem("i18nextLng", item);
                setSelectedLanguage(item)
                i18next.changeLanguage(item);
                setExpanded(false);
                // setMenuAnchor(null);
              }}
            >
              <img src={languageMap[item].logo} style={{ marginRight: 8 }} />
              <span>{languageMap[item].label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectLanguage;
