import React from "react";
import MpModalHolder from "../MPFactory/MPModalHOC/Modal";
import MPModalHOC from "../MPFactory/MPModalHOC";
import "./styles.css";
import FilterIcon from "../../assets/images/filter.svg";
import CrossIcon from "../../assets/images/cross.svg";
import { useTranslation } from "react-i18next";
import MPInputWithLabel from "../MPFactory/MpInputWithLabel";
import MPCounterInput from "../MPFactory/MPCounterInput";
import MPDateInput from "../MPFactory/MPDateInput";
import MPCheckbox from "../MPFactory/MPCheckbox";
import MpButtonPrimary from "../MPFactory/MPButton/primary";
import MpButtonSecondary from "../MPFactory/MPButton/secondary";
import useConversationFilter from "./useConversationFilter";
import LoaderGif from "../../assets/images/loader.gif";
import PlusIcon from "../../assets/images/plus_icon.svg";
import WhiteCrossIcon from "../../assets/images/white_close.svg";
import { stringToColour } from "../../services/Utils";

const ConversationFilter = (props) => {
  const { t } = useTranslation();

  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    onFilterApply,
    onFilterReset,
    filterResultLoading,
    selectedTagList,
    removeSelectedTag,
    setShowTagList,
    showTagList,
    tagList,
    addTag,
    selectedProspectorsList,
    removeSelectedProspector,
    setShowProspectorsList,
    showProspectorsList,
    prospectorsList,
    addProspector,
    showAdvanceFilters,
    setShowAdvanceFilters,
    isLastMessageNotByProspector,
    setIsLastMessageNotByProspector,
    numberOfMessages,
    setNumberOfMessages
  } = useConversationFilter(props);

  const renderFilterCardHeader = () => {
    return (
      <div>
        <img src={FilterIcon} />
        {t("FILTER_CONVERSATIONS")}
        <img
          src={CrossIcon}
          style={{ height: 15, cursor: "pointer" }}
          onClick={props?.onClose}
        />
      </div>
    );
  };

  const renderFilterCardBody = () => {
    const renderDateFilters = () => {
      return (
        <div>
          <div style={{ fontSize: 11 }}>{t("FILTER_DATE")}</div>
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <MPDateInput date={startDate} setDate={setStartDate} />
            <span style={{ marginRight: 15, marginLeft: 15 }}>{t("TO")}</span>
            <MPDateInput date={endDate} setDate={setEndDate} />
          </div>
        </div>
      );
    };

    return (
      <>
        <div className={`b1 px-[32px] pt-[25px] ${showAdvanceFilters ? "border-b border-x-0 border-t-0 border-solid border-[#c9c9c9] pb-[12.5px]" : "pb-[25px]"}`}>
          <div className="w-full mb-6 relative">
            <label className="text-sm">Tags</label>
            <div
              className="px-2 py-3 border-2 border-solid border-[#3498db] rounded-lg flex items-end justify-between mt-2"
            >
              <div className="w-[90%] max-w-[90%] flex flex-wrap gap-2 max-h-[7.5rem] overflow-y-auto">
                {selectedTagList.map((tag, index) => (
                  <div
                    key={index}
                    style={{ background: tag?.color ? tag.color : stringToColour(tag.tag) }}
                    className={`px-2 py-3 h-[8px] rounded-md text-white text-sm text-center flex items-center gap-2`}
                  >
                    <label>{tag.tag}</label>
                    <img
                      width={19}
                      src={WhiteCrossIcon}
                      onClick={() => removeSelectedTag(index)}
                    />
                  </div>
                ))}
              </div>
              <div className="max-w-[10%]">
                <img
                  src={PlusIcon}
                  className="cursor-pointer"
                  onClick={() => { setShowTagList(true); setShowProspectorsList(false); }}
                />
              </div>
            </div>
            {showTagList && (
              <div className="bg-white border border-solid border-[#3498db] w-[90%] ml-2 absolute mt-[-1rem] px-2 py-3 rounded-lg text-center z-[1000]">
                <div className="absolute left-[300px] top-1 cursor-pointer">
                  <img src={CrossIcon} onClick={() => setShowTagList(false)} />
                </div>
                <div className="pb-3"></div>
                <div className="w-[100%] max-h-[7.5rem] flex flex-wrap gap-2 overflow-y-auto overflow-x-hidden">
                  {tagList.length > 0
                    ? tagList.map((tag, index) => (
                      <div
                        key={index}
                        style={{ background: tag?.color ? tag.color : stringToColour(tag.label) }}
                        className={`px-2 py-3 h-[8px] rounded-md text-white text-sm text-center flex items-center gap-2 cursor-pointer`}
                        onClick={() => addTag(tag.label, tag.color)}
                      >
                        <span>{tag.label}</span>
                      </div>
                    ))
                    : (
                      <label className="text-red-400 font-bold text-sm"> You haven't added any tags </label>
                    )
                  }
                </div>
              </div>
            )}
          </div>

          <div className="w-full mb-6 relative">
            <label className="text-sm">{t("LINKEDIN_ACCOUNT")}</label>
            <div
              className="px-2 py-3 border-2 border-solid border-[#3498db] rounded-lg flex items-end justify-between mt-2"
            >
              <div className="w-[90%] max-w-[90%] flex flex-wrap gap-2 max-h-[7.5rem] overflow-y-auto">
                {selectedProspectorsList.map((prospector, index) => (
                  <div
                    key={index}
                    className="px-2 py-3 h-[8px] rounded-md bg-[#3154C8] text-white text-sm text-center flex items-center gap-2"
                  >
                    <label>{prospector}</label>
                    <img
                      width={19}
                      src={WhiteCrossIcon}
                      onClick={() => removeSelectedProspector(index)}
                    />
                  </div>
                ))}
              </div>
              <div className="max-w-[10%]">
                <img
                  src={PlusIcon}
                  className="cursor-pointer"
                  onClick={() => { setShowProspectorsList(true); setShowTagList(false); }}
                />
              </div>
            </div>

            {showProspectorsList && (
              <div className="bg-white border border-solid border-[#3498db] w-[90%] ml-2 absolute mt-[-1rem] px-2 py-3 rounded-lg text-center z-[1000]">
                <div className="absolute left-[300px] top-1 cursor-pointer">
                  <img src={CrossIcon} onClick={() => setShowProspectorsList(false)} />
                </div>
                <div className="pb-3"></div>
                <div className="w-[100%] max-h-[7.5rem] flex flex-wrap gap-2 overflow-y-auto overflow-x-hidden">
                  {prospectorsList.map((prospector, index) => (
                    <div
                      key={index}
                      className={`px-2 py-3 h-[8px] rounded-md bg-[#3154C8] text-white text-sm text-center flex items-center gap-2 cursor-pointer`}
                      onClick={() => addProspector(prospector.label)}
                    >
                      <span>{prospector.label}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          {!showAdvanceFilters &&
            (
              <div className="inline-block" onClick={() => { setShowAdvanceFilters(true); setShowProspectorsList(false); setShowTagList(false) }}>
                <div className="flex items-center gap-5 max-w-full cursor-pointer">
                  <img src={PlusIcon} className="cursor-pointer" />
                  <label className="text-[#2287F3] cursor-pointer"> {t("ADVANCED_FILTERS")} </label>
                </div>
              </div>
            )}
        </div>
        {showAdvanceFilters && (
          <div className="b2 px-[32px] pt-[12.5px] pb-[25px]">
            <div className="w-full">
              <div className="flex items-center justify-between w-full mb-3">
                <label className="font-bold"> {t("ADVANCED_FILTERS")} </label>
                <img src={CrossIcon} className="cursor-pointer" onClick={() => setShowAdvanceFilters(false)} />
              </div>
              <div>
                {renderDateFilters()}
                <MPCheckbox
                  label={t("LAST_SENT_BY")}
                  value={isLastMessageNotByProspector}
                  setValue={setIsLastMessageNotByProspector}
                />
                <br />
                <br />
                <MPCounterInput
                  counter={numberOfMessages}
                  setCounter={setNumberOfMessages}
                  placeholderText={`0`}
                  label={t("NUMBER_OF_MESSAGES")}
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const renderFilterCardFooter = () => {
    return (
      <>
        <MpButtonPrimary
          CTA_TEXT={t("Apply")}
          customStyle={{ textAlign: "center" }}
          onCtaClick={onFilterApply}
        />
        <br />
        <MpButtonSecondary
          CTA_TEXT={t("RESET_FILTER")}
          customStyle={{ textAlign: "center" }}
          onCtaClick={onFilterReset}
        />
      </>
    );
  };
  return (
    <div className="filter-container-wrapper">
      <div className="filter-card no-scrollbar">
        <div className="filter-card-header">{renderFilterCardHeader()}</div>
        <div className="filter-card-body">{renderFilterCardBody()}</div>
        <div className="filter-card-footer">{renderFilterCardFooter()}</div>
      </div>
      {filterResultLoading ? (
        <div className="filter-loading-wrapper">
          <div>
            <img src={LoaderGif} style={{ height: 50 }} /> <br />
            {/* loading */}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default MPModalHOC(MpModalHolder(ConversationFilter));
