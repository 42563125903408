import React from "react";
import "./styles.css";

const MPInputWithLabel = ({ label, value, onValueChange, type, styles={}, showLabel=true, placeholderText }) => {
  return (
    <div className="mp-custom-input-container">
      {showLabel && <div className="label-wrap">
        <label htmlFor={label}>{label}</label>
      </div>}
      <input style={styles} placeholder={placeholderText || ""} type={type} id={label} value={value} onChange={(e)=>onValueChange(e.target.value)} />
    </div>
  );
};

export default MPInputWithLabel;
