import {SET_CONVERSATION_PREFERENCE_DATE, SET_CONVERSATION_PREFERENCE_TEXT, SET_CUSTOM_DATE_TRIGGER} from "./types";

export const setConversationPreferenceDate = (date) => {
    return {
        type: SET_CONVERSATION_PREFERENCE_DATE,
        payload: date,
    };
};

export const setConversationPreferenceText = (text) => {
    return {
        type: SET_CONVERSATION_PREFERENCE_TEXT,
        payload: text,
    };
};

export const setCustomDateTrigger = (trigger) => {
    return {
        type: SET_CUSTOM_DATE_TRIGGER,
        payload: trigger,
    };
}

