import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import editIcon from "../../../assets/images/edit_icon.svg";
import blackCrossIcon from "../../../assets/images/cross_black_icon.svg";
import { GETCLIENTINFO, MODIFY_CLIENT } from "../../../services/Networking/api";
import { useNavigate } from "react-router-dom";
import { getRequest, postRequest } from "../../../services/Networking";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const MyAccount = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [price, setPrice] = useState("");
  const [maxProspector, setMaxProspector] = useState(null);

  const [editedName, setEditedName] = useState("");
  const [editedEmail, setEditedEmail] = useState("");
  const [reload, setReload] = useState(false);

  const modifyModalStates = () => {
    setEditModalOpen((curr) => !curr);
    setEditedName(name);
    setEditedEmail(email);
  };

  const editClient = async () => {
    //API CALL
    const params = {
      name: editedName,
      email: editedEmail,
    };
    const response = await postRequest(`${MODIFY_CLIENT}`, params);
    setReload((curr) => !curr);
    setEditModalOpen((curr) => !curr);
  };

  useEffect(() => {
    let isMember = Cookies.get("isMember") === '1' || null || '0' || undefined;
    if (isMember === true || isMember === "1" || isMember === 1) {
      isMember = true;
    } else {
      isMember = false;
    }
    if (isMember === true) {
      navigate("/account-management/linkedin-settings"); 
    }
  }, []);

  useEffect(
    () => {
      const fetchData = async () => {
        try {
          const response = await getRequest(`${GETCLIENTINFO}`);
          const res = await response.json();
          setName(res.result.name);
          setEmail(res.result.email);
          setCompany(res.result.company);
          setPrice(res.result.price);
          setMaxProspector(res.result.maxProspector);
        } catch (error) {
          // Handle errors here
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    },
    [reload]
  );

  return (
    <div className="xl:w-[70%] lg:w-[85%] w-[90%] mx-auto mt-[2rem] max-h-full">
      <h1 className="text-[32px] font-bold">{t("YOUR_PROFILE")}</h1>
      <div className={`${styles["profile-details"]} px-[25px] py-[35px] sm:px-[60px] sm:py-[60px] flex flex-col md:flex-row items-center gap-10`}>
        <div className="w-full md:w-[45%] h-[21rem] md:h-[23rem]">
          <div className="w-full h-full flex flex-col justify-between">
            <div>
              <div className="flex items-end justify-between mb-10">
                <div className="flex flex-col items-start gap-5">
                  <label className="font-[400] text-[14px] text-black">
                    {t("NAME")}
                  </label>
                  <span className="font-[700] text-[14px] text-[#0A66C2] ">
                    {name}
                  </span>
                </div>
                <div>
                  <img onClick={() => modifyModalStates()} src={editIcon} className="cursor-pointer" />
                </div>
              </div>

              <div className="flex items-end justify-between mb-10">
                <div className="flex flex-col items-start gap-5">
                  <label className="font-[400] text-[14px] text-black">
                    {t("EMAIL")}
                  </label>
                  <span className="font-[700] text-[14px] text-[#0A66C2] ">
                    {email}
                  </span>
                </div>
                <div>
                  <img onClick={() => modifyModalStates()} src={editIcon} className="cursor-pointer" />
                </div>
              </div>

              <div className="flex items-end justify-between">
                <div className="flex flex-col items-start gap-5">
                  <label className="font-[400] text-[14px] text-black">
                    {t("COMPANY")}
                  </label>
                  <span className="font-[700] text-[14px] text-[#0A66C2] ">
                    {company}
                  </span>
                </div>
                <div>
                  <img onClick={() => modifyModalStates()} src={editIcon} className="cursor-pointer" />
                </div>
              </div>
            </div>

            <div>
              <button
                className={`${styles.btn} uppercase text-[16px] font-semibold`}
                onClick={() => navigate("/change-password")}
              >
                {t("CHANGE_MY_PASSWORD")}
              </button>
            </div>
          </div>
        </div>

        <div className="w-full md:w-[10%] md:h-[23rem]">
          <div
            className={`border-l-0 border-solid border-[#C9C9C9] h-full hidden md:inline-block`}
          ></div>

          <div
            className={`md:hidden block border-t-0 border-solid border-[#C9C9C9] w-full`}
          ></div>
        </div>

        <div className="w-full md:w-[45%] h-[17rem] md:h-[23rem]">
          <div className="flex flex-col justify-between h-full items-baseline">
            <div className="w-full">
              <div className="xl:text-left text-center">
                <h1 className="inline-block text-2xl xl:text-3xl font-bold my-0">
                  {t("YOUR_SUBSCRIPTION")}
                </h1>
              </div>
              <div className="flex flex-row lg:flex-col xl:flex-row items-center justify-between mt-5 gap-2 lg:gap-0 xl:gap-2">
                <span className="bg-[#E7E7E7] p-2 rounded-md font-bold text-[12px]">
                  {maxProspector} {t("ACCOUNTS")} x {price} €/{t("MONTH")}
                </span>
                <h3 className="font-bold text-lg bg-gradient-to-r from-[#1E93FD] to-[#3154C8] text-transparent bg-clip-text m-0">
                  {maxProspector * price}€/{t("MONTH")}
                </h3>
              </div>
            </div>
            <div className="w-full">
              <button
                className={`${styles["gradient-btn"]} uppercase text-[16px] font-semibold border-none mb-5`}
              >
                {t("CHANGE_MY_SUBSCRIPTION")}
              </button>
              <a href="https://checkout.mirrorprofiles.com/p/login/28oaF416f2lF9Py9AA" target="_blank" rel="noopener noreferrer">
                <button
                  className={`${styles.btn} uppercase text-[16px] font-semibold`}
                >
                  {t("INVOICES")}
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      {isEditModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-md w-[85%] sm:w-[70%] md:w-[50%] lg:w-[35%] xl:w-[25%]">
            <div className="flex justify-end">
              <img
                src={blackCrossIcon}
                width={15}
                onClick={() => setEditModalOpen((curr) => !curr)}
                className="cursor-pointer"
              />
            </div>
            <h2 className="text-lg font-bold mb-6 text-center">
              {t("EDIT_THE_ACCOUNT")}
            </h2>
            <div className="flex flex-col items-center gap-5 w-[90%] mx-auto">
              <div className="w-full">
                <label for="name" className="block text-[14px] mb-3">{t("FULL_NAME")}</label>
                <input
                  id="name"
                  type="text"
                  value={editedName}
                  onChange={(e) => setEditedName(e.target.value)}
                  className={`${styles["edit-input"]} w-[95%] mx-auto`}
                />
              </div>

              <div className="w-full">
                <label for="email" className="block text-[14px] mb-3">{t("EMAIL")}</label>
                <input
                  type="text"
                  id="email"
                  value={editedEmail}
                  onChange={(e) => setEditedEmail(e.target.value)}
                  className={`${styles["edit-input"]} w-[95%] mx-auto`}
                />
              </div>

              <button
                className={`${styles["gradient-btn"]} uppercase text-[16px] font-semibold border-none my-6`}
                onClick={() => editClient()}
              >
                {t("MODIFY")}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyAccount;
