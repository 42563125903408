import { SET_FILTER_TAGS, SET_PROSPECTORS_LIST, SET_LAST_MESSAGE_NOT_BY_PROSPECTOR, SET_START_DATE, SET_END_DATE, SET_NUMBER_OF_MESSAGES } from "../actions/types";

const initialState = {
    selectedFilterTags: [],
    selectedProspectorsList: [],
    isLastMessageNotByProspector: false,
    startDate: "",
    endDate: "",
    numberOfMessages: 0
};

const conversationFilter = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTER_TAGS:
            return {
                ...state,
                selectedFilterTags: action?.payload,
            };
        case SET_PROSPECTORS_LIST:
            return {
                ...state,
                selectedProspectorsList: action?.payload
            };
        case SET_LAST_MESSAGE_NOT_BY_PROSPECTOR:
            return {
                ...state,
                isLastMessageNotByProspector: action?.payload
            }
        case SET_START_DATE:
            return {
                ...state,
                startDate: action?.payload
            }
        case SET_END_DATE:
            return {
                ...state,
                endDate: action?.payload
            }
        case SET_NUMBER_OF_MESSAGES:
            return {
                ...state,
                numberOfMessages: action?.payload
            }
        default:
            return state;
    }
};


export default conversationFilter;