import { ADD_MESSAGE, SET_MESSAGES, UPDATE_MESSAGE, SELECTED_MESSAGE, UPDATE_MESSAGE_CONTENT, DELETE_MESSAGE } from "../actions/types";

const initialState = {
    messages: [],
};


const message = (state = initialState, action) => {
    switch(action.type) {
        case ADD_MESSAGE: 
            return {
                ...state, 
                messages: [...state.messages, action?.payload]
            }
        
        case SET_MESSAGES: 
            return {
                ...state, 
                messages:  [...action?.payload]
            }
        
        case UPDATE_MESSAGE:
            state.messages.find(message=>message?.tempId==action.payload)?.updateMessage()
            return {
                ...state, 
                messages: [...state?.messages]
            }
        case SELECTED_MESSAGE: 
            return {
                ...state,
                selectedMessage: {... action?.payload}
            }

        case UPDATE_MESSAGE_CONTENT:
            let newMessages = state?.messages?.map(message =>{
               return (message.entityUrn == action?.payload?.entityUrn) ? action?.payload: message
            }) 
            return {
                ...state,
                messages: [... newMessages]
            }
        
        case DELETE_MESSAGE:
            const newList = state.messages.filter(message=>(message?.entityUrn!=action.payload && message?.tempId != action.payload))
            return {
                ...state, 
                messages: newList
            }
        default: return state

    }
}

export default message