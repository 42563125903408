import React from "react";
import MPModalHOC from ".";

const MpModalHolder = (WrappedComponent) => {
  const modalWrap = (props) => {
    return (
        <div style={styles.overlay}>
            <WrappedComponent {...props} />
        </div>
    )
  };

  return modalWrap;
};

const styles = {
    overlay: {
        position: "fixed",
        top: 0,
        left:0 ,
        height: "100vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 100
    }
}

export default MpModalHolder;
