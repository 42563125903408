import React, { useState, useEffect } from "react";
import DashboardLeft from "./dashboard-left";
import DashboardCenter from "./dashboard-center";
import DashboardRight from "./dashboard-right";
import DashboardHeader from "./dashboard-header";
import { isMobile } from "../../services/responsiveness";
import ProfileInfoWrapper from "./dashboard-right/mobile/profileInfoWrapper";

import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";


const Dashboard = (props, { prefTrigger }) => {
  const [showLeft, setShowLeft] = useState(true);
  const [showCenter, setShowCenter] = useState(true);
  const [showRight, setShowRight] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const [showProfileInfo, setShowProfileInfo] = useState(false);
  const [myPrefTrigger, setMyPrefTrigger] = useState(false);
  const [tagTrigger, setMyTagTrigger] = useState(false);

  const navigate = useNavigate();
  const clientId = Cookies.get("clientId") || null;

  if (!clientId)
  {
    navigate("/login");
  }

  useEffect(() => {
    if (!isMobile()) setShowRight(true);
  }, []);
  const onCollapse = (component) => {
    if (!isMobile()) return;

    switch (component) {
      case "left":
        setShowLeft(false);
        setShowCenter(true);
        setShowHeader(false);
        break;
      case "center":
        setShowCenter(false);
        setShowLeft(true);
        setShowHeader(true);

        break;
      case "right":
        setShowRight(false);
        setShowCenter(true);
      default:
        break;
    }
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      {showHeader && <DashboardHeader />}
      <div
        style={{ display: "flex", flexGrow: 1, height: "calc(100vh - 100px)" }}
      >
        {showLeft && <DashboardLeft onCollapse={onCollapse} />}
        {showCenter && (
          <DashboardCenter
            onCollapse={onCollapse}
            setShowProfileInfo={setShowProfileInfo}
            prefTrigger={() => {
              setMyPrefTrigger((item) => !item);
            }}
            tagTrigger={tagTrigger}
          />
        )}
        {!isMobile() && (
          <DashboardRight
            prefTrigger2={myPrefTrigger}
            tagTrigger={() => {
              setMyTagTrigger((item) => !item);
            }}
          />
        )}
        {isMobile() && showProfileInfo && (
          <ProfileInfoWrapper setShowProfileInfo={setShowProfileInfo} />
        )}
      </div>
    </div>
  );
};

export default Dashboard;
