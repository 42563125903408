import { useState } from "react";

const InputField = ({placeholder, setEmailValue, emailValue}) => {
  return (

    <input
      id="name"
      type="text"
      placeholder={placeholder}
      value={emailValue}
      onChange={(e) => setEmailValue(e.target.value)}
      className='border border-solid border-[#1E93FD] rounded-[5px] px-5 h-[40px] w-[85%] text-black focus:outline-none'
    />
 
  );
};

export default InputField;
