import React, { useState, useEffect } from "react";
import TagChip from "../Chip";
import "../styles.css";
import TagTitleAdd from "../../../assets/images/tagTitleAdd.svg";
import { getClientTags } from "../../../services/Tags";
import { useTranslation } from "react-i18next";

const TagListModal = ({ tags = [], addTagsByTagId, addTagsByTitle }) => {
  const [newTag, setNewTag] = useState();
  const [allTags, setAllTags] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    loadClientTags();
  }, []);
  const loadClientTags = async () => {
    const tags = await getClientTags({});
    setAllTags(tags?.result);
  };
  return (
    <div className="tag-list-modal">
      <div className="tag-list-chip-wrapper">
        {allTags &&
          allTags?.map((tag) => (
            <TagChip
              key={tag?.id}
              onClick={() => addTagsByTagId(tag?.id)}
              tag={tag}
            />
          ))}
      </div>
      <div className="tag-input-wrapper">
        <input
          className="tag-input-text"
          placeholder={t("ADD")}
          type="text"
          value={newTag}
          onChange={(e) => setNewTag(e.target?.value)}
        />
        <img
          src={TagTitleAdd}
          style={{ cursor: "pointer" }}
          onClick={() => addTagsByTitle(newTag)}
        />
      </div>
    </div>
  );
};

export default TagListModal;
