import blackCrossIcon from "../../assets/images/cross_black_icon.svg";

const Modal = ({setModalOpen, children}) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-md w-[85%] sm:w-[70%] md:w-[50%] lg:w-[35%] xl:w-[25%]">
            <div className="flex justify-end">
              <img
                alt="close modal"
                src={blackCrossIcon}
                width={15}
                onClick={() => setModalOpen((curr) => !curr)}
                className="cursor-pointer"
              />
            </div>
            {children}
          </div>
        </div>
  )
}

export default Modal