import React, { useState } from "react";
import ProfileInfo from "../profileInfo";
import Webhook from "../../../../assets/images/addWebhook.svg";
import Close from "../../../../assets/images/close.svg";
import WebhookModal from "../../../../components/Webhook/WebhookModal";
import BlockAccount from "../../../../components/BlockAccount";

const ProfileInfoWrapper = ({setShowProfileInfo}) => {
  const [showWebhookModal, setShowWebhookModal] = useState(false);

  return (<>
    <div style={wrapperStyle}>
        <img
            src={Webhook}
            onClick={() => setShowWebhookModal(!showWebhookModal)}
            style={{
              width: "28px",
              height: "28px",
              float: "left",
              position: "relative",
              left: "15px",
              top: "15px",
              cursor: "pointer",
            }}
          />
          <img
            src={Close}
            onClick={() => setShowProfileInfo(false)}
            style={{
              width: "28px",
              height: "28px",
              float: "right",
              position: "relative",
              right: "15px",
              top: "15px",
              cursor: "pointer",
            }}
          />

          {showWebhookModal && (
            <WebhookModal onWebhooksent={() => setShowWebhookModal(false)} />
          )}
          <br />
      <ProfileInfo />
      <BlockAccount />


    </div>
      <div style={overlayStyles}></div>
  </>);
};
const wrapperStyle = {
  position: "fixed",
  top: "100px",
  left: 0,
  right: 0,
  zIndex: 100,
  background: "#FFFFFF",
  boxShadow: "0px 20px 40px rgba(0, 0, 0, 0.2)",
  borderRadius: "10px",
  width: "100%",
  maxWidth: "310px",
  margin: "auto",
};
const overlayStyles = {
    height: "100vh", width: "100vh",
    position: "fixed",
    zIndex: 8,
    top: 0,
    left: 0,
    background: "rgba(0,0,0,0.5)"
}
export default ProfileInfoWrapper;
