import React, { useState, useEffect } from "react";
import { getWebhookUrls } from "../../../services/Webhook";
import Add from "../../../assets/images/tagAdd.svg";
import "../styles.css";
import { useTranslation } from "react-i18next";
import WebhookWithTitle from "../WebhookWithTitle";
import WebhookItem from "./WebhookItem";

const WebhookList = () => {
  const [webhookList, setWebhookList] = useState();
  const [showWebhookModal, setShowWebhookModal] = useState();
  const [webhookModalData, setWebhookModalData] = useState({})

  const { t } = useTranslation();

  useEffect(() => {
    loadWebhookUrl();
  }, []);
  const loadWebhookUrl = async () => {
    const params = { key: "0" };
    const webhookUrls = await getWebhookUrls(params);
    const nonDefaultWebhook = webhookUrls.filter(
      (webhook) => webhook.title && webhook.title !== "DEFAULT"
    );

    if (webhookUrls.length) setWebhookList(nonDefaultWebhook);
  };

  const onCreateWebhook = () => {
    setWebhookModalData({isEdit: false})
    setShowWebhookModal(true)
  }

  const onEditWebhook = (webhook) => {
    setWebhookModalData({webhook, isEdit: true})
    setShowWebhookModal(true)
  }

  const onWebhookModalClose = () => {
      setShowWebhookModal(false)
      setWebhookModalData({})
      loadWebhookUrl()
  }

  return (
    <div className="webhook-list-wrap">
      <div className="webhook-list-title">{t("CUSTOM_ACTION")}</div>
      <div className="webhook-list-urls">
        {webhookList?.map((webhook) => <WebhookItem webhook={webhook} onEditWebhook={onEditWebhook}/>)}
      </div>
      <div className="webhhook-list-footer" onClick={onCreateWebhook}>
          <img src={Add} style={{ cursor: "pointer", marginRight: 8 }}/>
          {t("ADD_OPTION")}
      </div>

      {
          showWebhookModal ? <WebhookWithTitle data={webhookModalData} onClose={onWebhookModalClose}/> : <></>
      }
    </div>
  );
};

export default WebhookList;
