import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import AddAccountsModal from "../../../../components/AddAccount";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { getRequest, postRequest } from "../../../../services/Networking";
import { HOSTEDAUTHURL } from "../../../../services/Networking/api";
import Cookies from "js-cookie";
import {
  CLIENT_PROSPECTORS,
  GET_MEMBER_PROSPECTORS,
} from "../../../../services/Networking/api";

const Additional = () => {
  const navigate = useNavigate();
  const [showAddAccount, setShowAddAccount] = useState(false);
  const { t } = useTranslation();
  const [validProspectorsCount, setValidProspectorsCount] = useState(0);
  const [maxProspector, setMaxProspector] = useState(0);

  let isMember = Cookies.get("isMember") === "1" || null || "0" || undefined;
  if (isMember === true || isMember === "1" || isMember === 1) {
    isMember = true;
  } else {
    isMember = false;
  }

  const getProspectors = async () => {
    let prospectors = null;
    if (isMember) {
      prospectors = await getRequest(`${GET_MEMBER_PROSPECTORS}`);
    } else {
      prospectors = await getRequest(`${CLIENT_PROSPECTORS}`);
    }
    const res = await prospectors.json();
    setMaxProspector(res.result.prospectors.length);
    setValidProspectorsCount(
      res.result.prospectors.filter(
        (prospector) => prospector.valid_session === true
      ).length
    );
  };

  useEffect(() => {
    getProspectors();
  }, []);

  return (
    <>
      <div id="mirrorprofiles-buttons">
        <div>
          {/* <button
            id="mirrorprofiles-add-accounts"
            // onClick={() => setShowAddAccount(true)}
            onClick={API}
          >
            {t("ADD_ACCOUNTS")}
          </button> */}
          <button
            id="mirrorprofiles-add-accounts"
            className="flex-center"
            // onClick={() => setShowAddAccount(true)}
            onClick={() => navigate("/account-management/myaccount")}
          >
            {t("ACCOUNTS_MANAGEMENT")}
            <img
              alt="connected"
              width="10"
              src="/static/media/status_green_icon.9d1f535b89dd905a17aacbe586af5cf2.svg"
              class="cursor-pointer hidden sm:block"
            />
            <span class="hidden sm:block">
              {validProspectorsCount}/{maxProspector}
            </span>
          </button>
        </div>
      </div>
      {showAddAccount
        ? createPortal(
            <AddAccountsModal onClose={() => setShowAddAccount(false)} />,
            document.body
          )
        : ""}
    </>
  );
};

export default Additional;
