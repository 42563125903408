import { getRequest, postRequest } from "./Networking";
import {
  CONVERSATIONS,
  FILTER_CONVERSATIONS,
  CONVERSATIONS2,
  FILTER_CONVERSATIONS2,
} from "./Networking/api";
import { getUrlParamsString } from "./Utils";
let conversationsController = null;

const getConversations = async (params) => {
  if (!conversationsController) {
    conversationsController = new AbortController();
  } else {
    conversationsController.abort();
    conversationsController = new AbortController();
  }

  const account_id = "tempAccount";
  const paramsString = getUrlParamsString(params);

  const response = await getRequest(
    `${CONVERSATIONS}?${paramsString}`,
    conversationsController?.signal
  );
  const response2 = await getRequest(
    `${CONVERSATIONS2}/${account_id}`,
    conversationsController?.signal
  );

  conversationsController = null;

  // return response.json()?.then(res=>res?.result)
  return response2.json()?.then((res) => res?.result);
};

const getFilteredConversations = async (params) => {
  if (!conversationsController) {
    conversationsController = new AbortController();
  } else {
    conversationsController.abort();
    conversationsController = new AbortController();
  }

  // const account_id = "zv_mhlVvRraj8S5zmgDGlw";
  // const account_id = params.account_id;
  // console.log("ACCOUNT_ID: ", account_id)

  var response2 = null;
  // response2 = await getRequest(`${CONVERSATIONS2}/${account_id[0]}/${params.count}/${params.nextCursor}`, conversationsController?.signal)
  if (params.type == "FILTER") {
    response2 = await postRequest(
      `${FILTER_CONVERSATIONS2}`,
      params,
      conversationsController?.signal
    );
  } else {
    response2 = await postRequest(
      `${CONVERSATIONS2}`,
      params,
      conversationsController?.signal
    );
  }
  // const myRes = account_id.map(async (id) => {
  //     response2 = await getRequest(`${CONVERSATIONS2}/${id}/${params.count}/${params.nextCursor}`, conversationsController?.signal)
  //     return response2;
  // })

  // const response = await postRequest(`${FILTER_CONVERSATIONS}`, params, conversationsController?.signal)

  conversationsController = null;
  return response2.json()?.then((res) => res?.result);

  // return response.json()?.then(res=>res?.result)
};
export { getConversations, getFilteredConversations };
