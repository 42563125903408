import React from 'react';

const Separator = () => {
  return (
    <>
        <div className='py-1'></div>
        <hr />
    </>
  )
}

export default Separator;