import React from "react";
import "./styles.css";
import MPInputWithLabel from "../MpInputWithLabel";
const MPDateInput = ({date, setDate}) => {
  return (
    <div style={{flex: 1 }}>
      <MPInputWithLabel
        styles={{ position: "relative", fontSize: 12}}
        type={"date"}
        value={date}
        onValueChange={setDate}
        placeholderText={`dd/mm/yy`}
      />
    </div>
  );
};

export default MPDateInput;
