import { SET_CONTACT_INFO, SET_CONTACT_INFO_LOADING, SET_CONTACT_INFO_LI_AT } from "./types";

const setContactInfo = (contactInfo) => {
  return {
    type: SET_CONTACT_INFO,
    payload: contactInfo,
  };
};

const setContactInfoLi_At = (li_at) => {
  return {
    type: SET_CONTACT_INFO_LI_AT,
    payload: li_at,
  };
};

const setContactInfoLoading = (loading) => {
  return {
    type: SET_CONTACT_INFO_LOADING,
    payload: loading
  };
}


export { setContactInfo, setContactInfoLoading, setContactInfoLi_At};
