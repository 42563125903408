import React, { useState } from "react";


import MPLogo from "../../../assets/images/mirrorProfilesLogo.png";
import "./styles.css";
import { loginApi } from "../../../services/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Login = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const onLogin = async (event) => {
    if (!isValidDetais()) return;
    
    const { code, result } = await loginApi({
      email,
      password,
    });
    console.log(code, result);

    if (code == 200 && result.msg == "LOGIN SUCCESS") {
      navigate("/dashboard");
    } else {
      toast.error("LOGIN FAILED");
    }
  };
  const isValidDetais = () => {
    return email && password;
  };

  const onChangePassword = () => {
    navigate("/forgot-password");
  };
  return (
    <div className="login-container">
      <img src={MPLogo} className="logo" />
      <div className="login-details">
        <div className="width-wrap">
          <div className="login-head">
            {t("LOGIN_TITLE")}
          </div>
          <div style={{ textAlign: "left" }}>
            <div className="label-wrap">
              <label htmlFor="email">Email:</label>
            </div>
            <input
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
            />
            <br />
            <div className="label-wrap">
              <label htmlFor="password">{t("PASSWORD")}</label>
            </div>
            <input
              type="password"
              id="password"
              value={password}
              onChange={handlePasswordChange}
            />
            <div className="change-password-text" onClick={onChangePassword}>
              {t("FORGOT_PASSWORD")}
            </div>
            <br />
          </div>
          <button onClick={onLogin} className="login-btn">
            {t("LOGIN")}
          </button>

          {/* <button onClick={}>S'inscrire</button> */}
        </div>
      </div>
    </div>
  );
};

export default Login;
