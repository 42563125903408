import i18n from "i18next";

const getUrlParamsString = (params) => {
  const queryArr = Object.keys(params).map((key) => `${key}=${params[key]}`);
  return queryArr.join("&");
};
const formattedUnixTime = (_unixTime, currentLanguage = "fn") => {
  const ONE_SECOND = 1000;
  const ONE_MINUTE = 60 * ONE_SECOND;
  const ONE_HOUR = 60 * ONE_MINUTE;
  const ONE_DAY = 24 * ONE_HOUR;
  const ONE_WEEK = 7 * ONE_DAY;

  let unixTime = _unixTime;
  let unixNow = Date.now(); // now, in milliseconds
  let now = new Date(unixNow);
  let time = new Date(unixTime);
  let yesterday = new Date(unixNow - ONE_DAY);
  let deltaTime = unixNow - unixTime;


  let flag = true;
  if (currentLanguage == "en") {
    flag = false;
  }

  let months =
    flag
      ? [
          "Jan",
          "Fév",
          "Mar",
          "Avr",
          "Mai",
          "Juin",
          "Juil",
          "Aoû",
          "Sep",
          "Oct",
          "Nov",
          "Déc",
        ]
      : [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
  let days =
    flag
      ? ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"]
      : ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  let year = time.getFullYear();
  let month = months[time.getMonth()];
  let date = time.getDate();
  let day = days[time.getDay()];
  let hour = time.getHours();
  let min = time.getMinutes();

  if (deltaTime < ONE_SECOND) {
    return flag ? "Maintenant" : "Now";
  } else if (deltaTime < ONE_MINUTE) {
    return Math.floor(deltaTime / ONE_SECOND) + "s";
  } else if (deltaTime < ONE_HOUR) {
    return Math.floor(deltaTime / ONE_MINUTE) + "m";
  } else if (time.setHours(0, 0, 0, 0) == now.setHours(0, 0, 0, 0)) {
    return Math.floor(deltaTime / ONE_HOUR) + "h";
  } else if (time.setHours(0, 0, 0, 0) == yesterday.setHours(0, 0, 0, 0)) {
    if (min < 10) min = "0" + min;
    {
      return flag ? ("Hier, " + hour + ":" + min) : ("Yesterday, " + hour + ":" + min);
    }
  } else if (deltaTime < ONE_WEEK) {
    return day;
  } else if (year == now.getFullYear()) {
    return date + " " + month;
  } else {
    return date + " " + month + " " + year;
  }
};

const formattedUnixTime_ = (_unixTime, currentLanguage = "fn") => {
  const ONE_SECOND = 1000;
  const ONE_MINUTE = 60 * ONE_SECOND;
  const ONE_HOUR = 60 * ONE_MINUTE;
  const ONE_DAY = 24 * ONE_HOUR;
  const ONE_WEEK = 7 * ONE_DAY;

  let unixTime = _unixTime;
  let unixNow = Date.now(); // now, in milliseconds
  let now = new Date(unixNow);
  let time = new Date(unixTime);
  let yesterday = new Date(unixNow - ONE_DAY);
  let deltaTime = unixNow - unixTime;

  let flag = true;
  if (currentLanguage == "en") {
    flag = false;
  }

  // Month and day names for both languages
  let months = flag
    ? ["Jan", "Fév", "Mar", "Avr", "Mai", "Juin", "Juil", "Aoû", "Sep", "Oct", "Nov", "Déc"]
    : ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  let days = flag
    ? ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"]
    : ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  // Extract the date values
  let year = time.getUTCFullYear();
  let month = months[time.getUTCMonth()]; // Get month in UTC
  let date = time.getUTCDate();           // Get date in UTC
  let day = days[time.getUTCDay()];       // Get day in UTC
  let hour = time.getUTCHours();          // Get hour in UTC
  let min = time.getUTCMinutes();         // Get minutes in UTC

  // Handle minute formatting
  if (min < 10) min = "0" + min;

  // Compare deltaTime to return formatted output
  if (deltaTime < ONE_SECOND) {
    return flag ? "Maintenant" : "Now";
  } else if (deltaTime < ONE_MINUTE) {
    return Math.floor(deltaTime / ONE_SECOND) + "s";
  } else if (deltaTime < ONE_HOUR) {
    return Math.floor(deltaTime / ONE_MINUTE) + "m";
  } else if (time.setUTCHours(0, 0, 0, 0) == now.setUTCHours(0, 0, 0, 0)) {
    return Math.floor(deltaTime / ONE_HOUR) + "h";
  } else if (time.setUTCHours(0, 0, 0, 0) == yesterday.setUTCHours(0, 0, 0, 0)) {
    return flag ? `Hier, ${hour}:${min}` : `Yesterday, ${hour}:${min}`;
  } else if (deltaTime < ONE_WEEK) {
    return day;
  } else if (year == now.getUTCFullYear()) {
    return `${date} ${month}`;
  } else {
    return `${date} ${month} ${year}`;
  }
};

const formatFullDateTime = (timestamp, language = 'en') => {
  const date = new Date(timestamp);

  // Arrays of weekday and month names for English and French
  const daysOfWeek = {
    en: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    fr: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']
  };

  const monthsOfYear = {
    en: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    fr: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
  };

  // Extract day, date, month, year, hours, minutes, and seconds
  const day = daysOfWeek[language][date.getDay()];
  const dayOfMonth = date.getDate();
  const month = monthsOfYear[language][date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  // Determine the ordinal suffix for English or French version
  const ordinalSuffix = (n) => {
    if (language === 'fr') return ''; // French doesn't use ordinal suffixes in dates

    if (n > 3 && n < 21) return 'th'; // English special case for 11th, 12th, 13th, etc.
    switch (n % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };

  // Format the date string based on the language
  const formattedDate = language === 'en' 
    ? `${day} ${dayOfMonth}${ordinalSuffix(dayOfMonth)} ${month} ${year}, ${hours}:${minutes}:${seconds}`
    : `${day} ${dayOfMonth} ${month} ${year}, ${hours}:${minutes}:${seconds}`;

  return formattedDate;
};

const isValidTimeDiff = (_unixTime, minDiff = 3600000) => {
  let unixTime = _unixTime;
  let unixNow = Date.now(); // now, in milliseconds
  let deltaTime = unixNow - unixTime;

  if (deltaTime < minDiff) {
    return true;
  }

  return false;
};

const stringToColour = (str) => {
  if (str == "Interested") {
    return "#069852";
  }
  if (str == "Not Interested") {
    return "#FF5454";
  }

  if (str == "Reminder") {
    return "#FF910F";
  }

  let hash = 0;
  str?.split("").forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  });

  const hue = hash % 360;
  const saturation = 30 + Math.abs(hash % 40); // Adjust the range for pastel saturation
  const lightness = 40 + Math.abs(hash % 20); // Adjust the range for pastel lightness

  const colour = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  return colour;
};

// const stringToColour = (str) => {
//   let str2 = str.toUpperCase()
//   let hash = 0;
//   str?.split("").forEach((char) => {
//     hash = char.charCodeAt(0) + ((hash << 5) - hash);
//   });
//   let colour = "#";
//   for (let i = 0; i < 3; i++) {
//     const value = (hash >> (i * 8)) & 0xff;
//     colour += value.toString(16).padStart(2, "0");
//   }
//   return colour;
// };
const formatDateFromMillis = (millis) => {
  const date = new Date(millis);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
const insertUrlParam = (key, value) => {
  // if (history.pushState) {
  let searchParams = new URLSearchParams(window.location.search);
  searchParams.set(key, value);
  let newurl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname +
    "?" +
    searchParams.toString();
  window.history.pushState({ path: newurl }, "", newurl);
  // }
};
export {
  getUrlParamsString,
  formattedUnixTime,
  stringToColour,
  isValidTimeDiff,
  formatDateFromMillis,
  insertUrlParam,
  formatFullDateTime
};
