import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AccountListItem from "../../../components/AccountListItem";

import accountNumberIcon from "../../../assets/images/account_number_icon.svg";
import statusGreenIcon from "../../../assets/images/status_green_icon.svg";
import statusRedIcon from "../../../assets/images/status_red_icon.svg";
import editIcon from "../../../assets/images/edit_icon.svg";
import crossIcon from "../../../assets/images/cross_red_icon.svg";
import blackCrossIcon from "../../../assets/images/cross_black_icon.svg";
import addIcon from "../../../assets/images/add_icon.svg";

import styles from "./styles.module.css";

import {
  getRequest,
  postRequest,
  deleteRequest,
} from "../../../services/Networking";
import Cookies from "js-cookie";
import {
  MODIFY_PROSPECTOR,
  HOSTEDAUTHURL,
  REMOVE_ACCOUNT,
  SETUNIPILEIDS,
  RESTART_ACCOUNT,
  SET_VALID_SESSION,
  GET_ACCOUNT_STATUS,
  CLIENT_PROSPECTORS,
  GETCLIENTINFO,
  GET_MEMBER_PROSPECTORS,
  SYNC_CHAT_ATTENEES_AND_MESSAGES,
} from "../../../services/Networking/api";

const LinkedInSettings = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isAddAccountModalOpen, setAddAccountModalOpen] = useState(false);

  const [fullName, setFullName] = useState([]);
  const [validSession, setValidSession] = useState([]);
  const [profile_picture, setProfile_picture] = useState([]);
  const [unipile_id, setUnipile_id] = useState([]);

  const [updateStatus, setUpdateStatus] = useState(true);

  const [modalProfile_picture, setModalProfile_picture] = useState("");
  const [modalFullName, setModalFullName] = useState("");
  const [editedFullName, setEditedFullName] = useState("");
  const [index, setIndex] = useState(null);
  const [maxProspector, setMaxProspector] = useState(0);
  const [accountReconnect, setAccountReconnect] = useState([]);

  const [isSynching, setIsSynching] = useState(false)

  let isMember = Cookies.get("isMember") === "1" || null || "0" || undefined;
  if (isMember === true || isMember === "1" || isMember === 1) {
    isMember = true;
  } else {
    isMember = false;
  }

  const [urlKey, setUrlKey] = useState(0);
  const [authURL, setAuthURL] = useState("");

  const checkEndTrial = async () => {
    let response = await getRequest(`${GETCLIENTINFO}`);
    let res2 = await response.json();

    if (
      res2.result?.endTrialDate &&
      new Date(res2.result?.endTrialDate) < new Date()
    ) {
      navigate("/end-trial");
    }
  };
  checkEndTrial();

  const modifyModalStates = (index) => {
    setEditModalOpen((curr) => !curr);
    setModalFullName(fullName[index]);
    setModalProfile_picture(profile_picture[index]);
    setEditedFullName(fullName[index]);
    setIndex(index);
  };

  const closeModal = () => {
    setUpdateStatus((curr) => !curr);
    setAddAccountModalOpen((curr) => !curr);
  };

  const reconnectStateHandler = (index, value) => {
    const temp_array = [...accountReconnect];
    temp_array[index] = value;
    setAccountReconnect(temp_array);
  };

  const removeAccount = async (index) => {
    try {
      const response = await deleteRequest(
        `${REMOVE_ACCOUNT}/${unipile_id[index]}`
      );
      const res = await response.json();
      if (res.code == 200) {
        setTimeout(() => {
          setUpdateStatus((curr) => !curr);
        }, 2000);
      }
    } catch (error) {
      console.log("Cannot Remove Account");
    }
  };

  const getHostedAuthUrl = async (type, index) => {
    try {
      setAuthURL("");
      const clientId = Cookies.get("clientId") || null;
      let params;
      if (clientId != null) {
        if (type === "create") {
          params = {
            name: clientId,
            type,
          };
        } else if (type === "reconnect") {
          params = {
            name: clientId,
            type,
            account_id: unipile_id[index],
          };
        } else {
          console.log("Invalid Credentials");
          return;
        }
        const response = await postRequest(`${HOSTEDAUTHURL}`, params);
        const res = await response.json();
        setAuthURL(res.result.url);
        if (authURL != "" || authURL != null || authURL != undefined) {
          setUrlKey((prevKey) => prevKey + 1);
          setAddAccountModalOpen((curr) => !curr);
        }
      }
    } catch (error) {
      console.log("Error fetching URL:");
    }
  };

  const restartAccount = async (type, index) => {
    let res = null;
    reconnectStateHandler(index, true);
    try {
      const response = await getRequest(
        `${GET_ACCOUNT_STATUS}/${unipile_id[index]}`
      );
      res = await response.json();
    } catch (error) {
      console.error(error);
    }

    if (res.result !== "CREDENTIALS") {
      // reconnectStateHandler(index, true);

      try {
        const response = await postRequest(`${RESTART_ACCOUNT}`, {
          account_id: unipile_id[index],
        });
        res = await response.json();
      } catch (error) {
        console.error(error);
        reconnectStateHandler(index, true);
      }

      if (
        res.result === "AccountRestarted" ||
        res.result === "Account Restarted"
      ) {
        //waiting for 5 seconds to again check the status
        setUpdateStatus((curr) => !curr);

        // await new Promise((resolve) => setTimeout(resolve, 5000)); // second wait for now

        // try {
        //   const response = await getRequest(
        //     `${GET_ACCOUNT_STATUS}/${unipile_id[index]}`
        //   );
        //   res = await response.json();
        // } catch (error) {
        //   console.error(error);
        // }

        // if (res.result === "OK") {
        //   // making valid session as 1 in db
        //   try {
        //     const response = await postRequest(`${SET_VALID_SESSION}`, { account_id: unipile_id[index] });
        //     res = await response.json();
        //   } catch (error) {
        //     console.error(error);
        //   }

        //   if (res.code === 200) {
        //     setUpdateStatus((curr) => !curr);
        //   }
        //   else {
        //     // change back button to reconnect
        //     reconnectStateHandler(index, false);
        //   }
        // }
        // else {
        //   // change back button to reconnect
        //   reconnectStateHandler(index, false);
        // }
      } else {
        // change back button to reconnect
        reconnectStateHandler(index, false);
        getHostedAuthUrl("reconnect", index);
      }
    } else {
      getHostedAuthUrl("reconnect", index);
    }
  };

  const editProspector = async () => {
    //API key
    const params = {
      name: editedFullName,
      unipile_id: unipile_id[index],
    };
    try {
      const response = await postRequest(`${MODIFY_PROSPECTOR}`, params);
    } catch (error) {
      console.error(error);
    }
    setEditModalOpen((curr) => !curr);
    setUpdateStatus((curr) => !curr);
  };

  const fetchData = async () => {
    try {
      let response = null;
      let res = null;

      if (isMember !== true) {
        response = await getRequest(`${CLIENT_PROSPECTORS}`);
      } else {
        response = await getRequest(`${GET_MEMBER_PROSPECTORS}`);
      }

      res = await response.json();

      setMaxProspector(res.result.maxProspector);
      const prospectorsData = res.result.prospectors.map((item) => ({
        fullName: item.full_name,
        validSession: item.valid_session,
        profilePicture: item.profilePicture,
        unipileId: item.unipile_id,
      }));

      setFullName(prospectorsData.map((item) => item.fullName));
      setValidSession(prospectorsData.map((item) => item.validSession));
      setProfile_picture(prospectorsData.map((item) => item.profilePicture));
      setUnipile_id(prospectorsData.map((item) => item.unipileId));

      setAccountReconnect(new Array(res.result.prospectors.length).fill(false));
    } catch (error) {
      // Handle errors here
      console.error("Error fetching data:", error);
    }
  };

  const callSyncApi = async () => {
    setIsSynching(true);
    const validUnipileIds = unipile_id.filter((id, index) => validSession[index] === true);
    const response = await getRequest(`${SYNC_CHAT_ATTENEES_AND_MESSAGES}?unipile_id=${validUnipileIds}`);
    setIsSynching(false);
  };

  useEffect(() => {
    // setFullName([]);
    // setValidSession([]);
    // setProfile_picture([]);
    // setUnipile_id([]);
    fetchData();
  }, [updateStatus]);

  return (
    <div className="xl:w-[50%] lg:w-[70%] md:w-[80%] w-[90%] mt-5 mx-auto max-h-full">
      <div className="flex flex-col items-center gap-10 w-full">
        <div className="w-full flex items-center justify-between">
          <div className="flex flex-row gap-3 min-w-[60%] w-[60%] max-w-[90%]">
            <button
              className={`${styles["gradient-btn"]} text-[0.7em] sm:text-[0.9em] w-[60%] sm:w-[40%]`}
              onClick={() => setUpdateStatus((curr) => !curr)}
            >
              {t("UPDATE_STATUS")}
            </button>

            <button
              className={`${styles.btn} text-[0.7em] sm:text-[0.9em]`}
              onClick={() => callSyncApi()}
            >
              {isSynching == false ? t("SYNCHRO_MESSAGES") : "SYNCING..."}
            </button>
          </div>
          <div className="flex items-center justify-end gap-2 sm:gap-3">
            <img alt="Account number icon" src={accountNumberIcon} />
            <label className="w-[50%] sm:w-[80%]">
              {" "}
              <strong>
                {validSession.length} / {maxProspector}
              </strong>{" "}
              {t("ACCOUNTS")}{" "}
            </label>
          </div>
        </div>
        <div
          className={`${styles["accounts-list"]} px-[30px] py-[30px] flex flex-col items-center w-[90%] gap-5`}
        >
          <div className="flex flex-col items-center w-[100%] gap-8 max-h-[16rem] overflow-y-auto overflow-x-hidden">
            {unipile_id.map((item, index) => (
              <AccountListItem
                key={unipile_id[index]}
                index={index}
                fullName={fullName[index]}
                profilePicture={profile_picture[index]}
                validSession={validSession[index]}
                unipile_id={unipile_id[index]}
                modifyModalStates={modifyModalStates}
                removeAccount={removeAccount}
                setUpdateStatus={setUpdateStatus}
                getHostedAuthUrl={getHostedAuthUrl}
                isMember={isMember}
              />
            ))}
          </div>
          {validSession.length < maxProspector && !isMember && (
            <div
              className="flex items-center gap-3 cursor-pointer"
              onClick={() => getHostedAuthUrl("create", index)}
            >
              <img alt="add account" src={addIcon} />
              <span className="text-[#2287F3]">
                {t("ADD_AN_ADDITIONAL_ACCOUNT")}
              </span>
            </div>
          )}
        </div>
        <div>
          <a
            href="https://mirrorprofiles.com/products/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button
              className={`${styles["gradient-btn"]} text-[0.7em] sm:text-[0.9em] mb-5`}
            >
              {t("RENT_ACCOUNTS")}
            </button>
          </a>
        </div>
      </div>

      {isEditModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-md w-[85%] sm:w-[70%] md:w-[50%] lg:w-[35%] xl:w-[25%]">
            <div className="flex justify-end">
              <img
                alt="close modal"
                src={blackCrossIcon}
                width={15}
                onClick={() => setEditModalOpen((curr) => !curr)}
                className="cursor-pointer"
              />
            </div>
            <h2 className="text-lg font-bold mb-6 text-center">
              {t("EDIT_THE_ACCOUNT")}
            </h2>
            <div className="flex items-center gap-5 w-[90%] mx-auto">
              <img
                alt="profile"
                src={modalProfile_picture}
                className="w-[50px] rounded-full"
              />
              <h4 className="text-[#0A66C2] text-[14px]">{modalFullName}</h4>
            </div>
            <div className="flex flex-col items-center gap-5 w-[90%] mx-auto">
              <div className="my-5 mx-auto w-full">
                <label className="block text-[14px] mb-3">
                  {t("FULL_NAME")}
                </label>
                <input
                  type="text"
                  value={editedFullName}
                  onChange={(e) => setEditedFullName(e.target.value)}
                  className={`${styles["edit-input"]} w-[95%] mx-auto`}
                />
              </div>
              <button
                className={`${styles["gradient-btn"]} uppercase text-[16px] font-semibold border-none`}
                onClick={() => editProspector()}
              >
                Modify
              </button>
              <button
                className={`${styles["disconnect-btn"]} uppercase text-[16px] font-semibold border-none mb-5`}
                onClick={() => {
                  console.log(index);
                  removeAccount(index);
                  setEditModalOpen((curr) => !curr);
                }}
              >
                {t("DISCONNECT")}
              </button>
            </div>
          </div>
        </div>
      )}

      {isAddAccountModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-md h-[60%] w-[85%] sm:w-[70%] md:w-[50%] lg:w-[35%] xl:w-[25%]">
            <div className="flex justify-end">
              <img
                src={blackCrossIcon}
                width={15}
                onClick={closeModal}
                className="cursor-pointer"
              />
            </div>
            <iframe
              title="Attach Account"
              src={`${authURL}?key=${urlKey}`}
              className={`w-full h-full border-none mt-2`}
            ></iframe>
          </div>
        </div>
      )}
    </div>
  );
};

export default LinkedInSettings;
