import React, { useState, useEffect } from "react";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useTranslation } from "react-i18next";

const DateInput = ({selectedDate, setSelectedDate}) => {
  const { t } = useTranslation();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [day, setDay] = useState("dd");
  const [month, setMonth] = useState("mm");
  const [year, setYear] = useState("yyyy");

  const extractDate = (date) => {
    if (date === null) return;
    const selectedDate = date.toLocaleDateString();
    const [month, day, year] = selectedDate.split("/");
    setDay(day);
    setMonth(month);
    setYear(year);
  }

  const handleCalendarChange = (date) => {
    setSelectedDate(date);
    setIsCalendarOpen(false);
  };

  useEffect(() => {
    extractDate(selectedDate);
  }, [selectedDate]);

  return (
    <div className="relative w-full">
      <label className="text-[11px]">{t("CHOOSE_DATE")}</label>
      <div
        className="px-[22px] py-[10px] border border-solid border-[#1E93FD] rounded-[17px] mt-1 cursor-pointer"
        onClick={() => setIsCalendarOpen((prev) => !prev)}
      >
        <div className="flex items-center justify-between text-[10px] font-bold">
          <span className={`${selectedDate !== null ? "bg-gradient-to-r from-[#1E93FD] to-[#3154C8] bg-clip-text text-transparent" : "text-[#A9A9A9]"}`}>{day}</span>
          <span className="text-black">/</span>
          <span className={`${selectedDate !== null ? "bg-gradient-to-r from-[#1E93FD] to-[#3154C8] bg-clip-text text-transparent" : "text-[#A9A9A9]"}`}>{month}</span>
          <span className="text-black">/</span>
          <span className={`${selectedDate !== null ? "bg-gradient-to-r from-[#1E93FD] to-[#3154C8] bg-clip-text text-transparent" : "text-[#A9A9A9]"}`}>{year}</span>
        </div>
      </div>
      {isCalendarOpen && (
        <div className="w-[270px] absolute top-[-160px] left-[-295px] px-1 py-4  bg-white shadow mt-1">
          <Calendar value={selectedDate} onChange={(value) => handleCalendarChange(value)} />
        </div>
      )}
    </div>
  );
};

export default DateInput;
