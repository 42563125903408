import { postRequest, getRequest } from "./Networking"
import { TRIGGER_WEBHOOK, GET_WEBHOOK_URLS, SAVE_OR_UPDATE_WEBHOOK, TRIGGER_WEBHOOK_BY_ID } from "./Networking/api"
import { getUrlParamsString } from "./Utils"

const triggerWebhook = async (params) => {
    const response = await postRequest(`${TRIGGER_WEBHOOK}`, params)
    return response.json()
}
const getWebhookUrls = async (params) => {
    const paramsString = getUrlParamsString(params)

    const response = await getRequest(`${GET_WEBHOOK_URLS}?${paramsString}`)

    return response.json().then(res=>res?.result)
}
const saveOrUpdateWebhook = async (params) => {
    const response = await postRequest(`${SAVE_OR_UPDATE_WEBHOOK}`, params)
    return response.json()
}
const triggerWebhookById = async (params)=> {
    const response = await postRequest(`${TRIGGER_WEBHOOK_BY_ID}`, params)
    return response.json()
}
export {triggerWebhook, getWebhookUrls, saveOrUpdateWebhook, triggerWebhookById}
