import React, { useState } from "react";
import InlineToast from "../../InlineToast";
import { triggerWebhookById } from "../../../services/Webhook";
import { useSelector } from "react-redux";
import Modify from "../../../assets/images/modify_message.svg";
import { useTranslation } from "react-i18next";

const WebhookItem = ({onEditWebhook, webhook}) => {
  const [toastWebhookSent, setToastWebhookSent] = useState();
  const state = useSelector((state) => state);
  const { t } = useTranslation();

  const triggerWebhook = async () => {
    const interlocutor =
      state?.conversation?.selectedConversation?.interlocutor;
    const prospector = state?.conversation?.selectedConversation?.prospector;
    const basicDetails = state?.contactInfo?.basicDetails;
    const body = {
      key: "0",
      id: webhook?.id,
      linkedin_profile_name:
        interlocutor?.firstName + " " + interlocutor?.lastName,
      linkedin_profile_url: interlocutor?.profileUrl,
      linkedin_account_sender:
        prospector?.firstName + " " + prospector?.lastName,
      linkedin_profile_title: interlocutor?.headline,
      linkedin_profile_phone: basicDetails?.result?.phone,
      linkedin_profile_email: basicDetails?.result?.email,
      linkedin_conversations: state?.message?.messages,
      prospector_urn: prospector?.entityUrn,
      conversation_urn: state?.conversation?.selectedConversation?.entityUrn,
      member_urn_id: state?.conversation?.selectedConversation?.interlocutor?.memberUrn?.replace("urn:li:member:", "")
    };
    const webhookResp = await triggerWebhookById(body);
    console.log(webhookResp);
    setToastWebhookSent(true);
  };
  return (
    <div className="webhook-url-item" style={{position: "relative"}}>
      <span style={{ cursor: "pointer" }} onClick={triggerWebhook}>
        {webhook?.title}
      </span>
      <InlineToast
        shouldShow={toastWebhookSent}
        updateShouldShow={setToastWebhookSent}
        style={{ right: 25 }}
        message={t("SENT")}
      />

      <img
        src={Modify}
        onClick={() => onEditWebhook(webhook)}
        style={{ cursor: "pointer" }}
      />
    </div>
  );
};

export default WebhookItem