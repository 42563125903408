import React, { useState, useEffect } from "react";
import MpModalHolder from "../../MPFactory/MPModalHOC/Modal";
import { useTranslation } from "react-i18next";
import MPInputWithLabel from "../../MPFactory/MpInputWithLabel";
import MpButtonPrimary from "../../MPFactory/MPButton/primary";
import { saveOrUpdateWebhook } from "../../../services/Webhook";
import { toast } from "react-toastify";
import MPModalHOC from "../../MPFactory/MPModalHOC";

const WebhookWithTitle = ({ data, onClose }) => {
  const { t } = useTranslation();
  const [webhookTitle, setWebhookTitle] = useState();
  const [webhookUrl, setWebhookUrl] = useState();
  useEffect(()=>{
    if(data?.webhook?.title)
        setWebhookTitle(data?.webhook?.title)
    
    if(data?.webhook?.url)
        setWebhookUrl(data?.webhook?.url)
  }, [data])
  const getTitle = () => {
    if (data?.isEdit) {
      return t("MODIFY");
    }

    return t("CUSTOM_ACTION");
  };
  const getCtaText = () => {
    if (data?.isEdit) {
      return t("SAVE");
    }

    return t("REGISTER");
  };

  const onSubmit = async () => {
      const body = {
          id: data?.webhook?.id,
          title: webhookTitle,
          url: webhookUrl
      }
      const webhookResp = await saveOrUpdateWebhook(body)
      toast.success(t("WEBHOOK_UPDATE_SUCCESS"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      onClose()
  };

  return (
    <div style={styles.wrapper}>
      <i
        class="fa-solid fa-xmark"
        style={styles.crossIcon}
        onClick={onClose}
      ></i>
      <div style={styles.title}>{getTitle()}</div>
      <MPInputWithLabel
        label={t("ACTION_NAME")}
        value={webhookTitle}
        onValueChange={setWebhookTitle}
        type={"text"}
      />

      <MPInputWithLabel
        label={t("WEBHOOK_URL")}
        value={webhookUrl}
        onValueChange={setWebhookUrl}
        type={"text"}
      />
      <div style={{ marginTop: 40 }}>
        <MpButtonPrimary CTA_TEXT={getCtaText()} onCtaClick={onSubmit} />
      </div>
    </div>
  );
};

const styles = {
  wrapper: {
    borderRadius: 10,
    background: "#FFF",
    boxShadow: "0px 10px 40px 0px rgba(30, 147, 253, 0.20)",
    padding: "44px 34px",
    width: 370,
    textAlign: "center",
    position: "relative",
  },
  crossIcon: {
    cursor: "pointer",
    float: "right",
    position: "absolute",
    top: 9,
    right: 9,
  },
  title: {
    fontSize: 16,
    fontWeight: 700,
    marginBottom: 37,
  },
};
export default MPModalHOC(MpModalHolder(WebhookWithTitle));
