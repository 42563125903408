import React from "react";
import MPInputWithLabel from "../MpInputWithLabel";
import "./styles.css";

const MPCheckbox = ({ label, value, setValue, setCheckBoxesStatuses, memberId }) => {
  const handleCheckboxChange = () => {
    setValue(!value);
    let updatedValue= {};
    updatedValue[memberId] = !value;
    setCheckBoxesStatuses((item) => ({
      ...item,
      ...updatedValue,
    }));
  };
  return (
    <label className={`custom-checkbox ${value ? "checked" : ""}`}>
      <input type="checkbox" checked={value} onChange={handleCheckboxChange} />
      {/* <MPInputWithLabel value={value} setValue={value} type={"checkbox"} /> */}

      <span className="checkmark"></span>
      {label}
    </label>
  );
};

export default MPCheckbox;
