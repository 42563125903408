import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { validSession } from "../../services/auth";
const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    handleSession()

  }, [])
  const handleSession = async () => {
    const { code, message } = await validSession()
    if (code == 200) {
      navigate('/dashboard');

    } else {
      navigate('/login');

    }
  }
  return (<Loader />);
}

export default Home;