const DropdownBox = ({ children, top, right, width }) => {
  return (
    <div
      style={{ boxShadow: "0px 20px 40px 0px rgba(30, 147, 253, 0.20)", top: top, right: right, width: width}}
      className={`p-[10px] rounded-[10px] bg-white border border-solid border-[#1E93FD] absolute z-10 `}
    >
      {children}
    </div>
  );
};

export default DropdownBox;
