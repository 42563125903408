import { getRequest, postRequest } from "./Networking";
import { getUrlParamsString } from "./Utils";
import { CONVERSATION_PREFERENCES } from "./Networking/api";

const getConversationPreferences = async (params) => {
  const paramsString = getUrlParamsString(params);
  const response = await getRequest(
    `${CONVERSATION_PREFERENCES}?${paramsString}`
  );
  return response.json()?.then((res) => res?.result);
};

const setConversationPreferences = async (params) => {
  const response = await postRequest(`${CONVERSATION_PREFERENCES}`, params);
  return response.json()?.then((res) => res?.result);
};

export { getConversationPreferences, setConversationPreferences };
