import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import RightArrow from "../../../assets/images/right-arrow.svg";

import Webhook from "../../../assets/images/addWebhook.svg";

import "./styles.css";
import WebhookModal from "../../../components/Webhook/WebhookModal";
import ProfileInfo from "./profileInfo";
import Tag from "../../../components/Tag";
import BlockAccount from "../../../components/BlockAccount";
import WebhookList from "../../../components/Webhook/WebhookList";

const ExpandedDashboardRight = ({ setIsCollapsed, prefTrigger, tagTrigger }) => {
  const state = useSelector((state) => state);
  const [showWebhookModal, setShowWebhookModal] = useState(false);
  return (
    <div className="expanded-wrapper">
      {!!state?.conversation?.selectedConversation && (
        <div className="full-profile-container">
          <img
            src={RightArrow}
            onClick={() => setIsCollapsed(true)}
            style={{
              width: "28px",
              height: "28px",
              float: "left",
              position: "relative",
              left: "18px",
              cursor: "pointer",
            }}
          />
          <img
            src={Webhook}
            onClick={() => setShowWebhookModal(!showWebhookModal)}
            style={{
              width: "28px",
              height: "28px",
              float: "right",
              position: "relative",
              right: "18px",
              cursor: "pointer",
            }}
          />
          {showWebhookModal && (
            <WebhookModal onWebhooksent={() => setShowWebhookModal(false)} />
          )}
          <ProfileInfo />
          <Tag prefTrigger={prefTrigger} tagTrigger={tagTrigger}/>
          <WebhookList />
        </div>
      )}
    </div>
  );
};

export default ExpandedDashboardRight;
