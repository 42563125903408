import { UPDATE_SELECTED_CONVERSATION, MARK_UNREAD, NEW_MESSAGES, UPDATE_UNREAD_COUNT } from "../actions/types";

const initialState = {
    selectedConversation: null,
    markedUnread: [],
    newMessages: {},
    unreadCount: 0
};


const conversation = (state = initialState, action) => {
    switch(action.type) {
        case UPDATE_SELECTED_CONVERSATION: 
            return {
                ...state, 
                selectedConversation: action?.payload
            }
        case MARK_UNREAD:
            return {
                ...state,
                markedUnread: [...state?.markedUnread, action?.payload?.entityUrn]
            }
        
        case NEW_MESSAGES:
            return {
                ...state,
                newMessages: {...state?.newMessages, ...action?.payload}
            }
        
        case UPDATE_UNREAD_COUNT: 
            return {
                ...state,
                unreadCount: action?.payload
            }
        default: return state
    }
}

export default conversation