import React from "react";
import MPLogo from "../../../assets/images/mirrorProfilesLogo.png";
import { logoutApi } from "../../../services/auth";
import styles from "./styles.module.css"
import { useTranslation } from "react-i18next";

const Header = () => {
    const { t } = useTranslation();

    const onLogout = async () => {
        const { msg, code } = await logoutApi();
        if (code == 200) {
            window.location.href = "/";
        }
    };

    return (
        <div className="flex items-center justify-between mr-[0rem] md:mr-[3rem] lg:mx-[4.5rem] my-10">
            <div className="flex items-center gap-5">
                <img src={MPLogo} className="logo" />
            </div>
            <button onClick={onLogout} className={`cursor-pointer ${styles['disconnect-btn']} border-none px-[16px] py-[10px] text-[12px] md:px-[22px] md:py-[16px] md:text-[14px]`}> {t("DISCONNECT")} </button>
        </div>
    );
}


export default Header;