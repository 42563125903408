import ConversationPreferences from "../components/ConversationPreferences";
import { getRequest, postRequest } from "./Networking";
import {
  CONVERSATION,
  CONVERSATION2,
  SEND_MESSAGE,
  SEND_MESSAGE2,
  MARK_UNREAD,
  BLOCK_ACCOUNT,
  UPDATE_MESSAGE_URL,
  DELETE_MESSAGE_URL
} from "./Networking/api";
import { getUrlParamsString } from "./Utils";

let conversationController = null;

const getConversation = async (params) => {
  if(!conversationController) {
      conversationController = new AbortController()
  }else {
      conversationController.abort();
      conversationController = new AbortController()

  }
  // code here
  const paramsString = getUrlParamsString(params);

  // const response = await getRequest(`${CONVERSATION}?${paramsString}`, conversationController?.signal);
  
  // const response2 = await getRequest(`${CONVERSATION2}/${params.urn}/${params.prospectorUrn}/${params.reminder}`, conversationController?.signal);
  const response2 = await postRequest(`${CONVERSATION2}`, params, conversationController?.signal);
  
  conversationController = null
  
  // return response.json()?.then(res=>res?.result);
  return response2.json()?.then(res=>res?.result);
};
const sendMessage = async (params) => {
  const response = await postRequest(`${SEND_MESSAGE}`, params);
  const response2 = await postRequest(`${SEND_MESSAGE2}`, params);
  // return response.json();
  return response2.json();
};
const markConversationUnread = async (params) => {
  const response = await postRequest(`${MARK_UNREAD}`, params);
  return response.json();
};
const blockAccount = async (params) => {
  const response = await postRequest(`${BLOCK_ACCOUNT}`, params);
  return response.json();
};
const updateMessageApi = async (params) => {
  const response = await postRequest(`${UPDATE_MESSAGE_URL}`, params)
  return response.json()
}
const deleteMessage = async (params) => {
  const response = await postRequest(`${DELETE_MESSAGE_URL}`, params)
  return response.json()
}
export { getConversation, sendMessage, markConversationUnread, blockAccount, updateMessageApi, deleteMessage };
