import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { CLIENT_PROSPECTORS } from "../../services/Networking/api";
import { getRequest, postRequest } from "../../services/Networking";
import { getClientTags } from "../../services/Tags";
import { getFilteredConversations } from "../../services/Conversations";
import {
  setFilterTagsRedux,
  setProspectorsListRedux,
  setLastMessageNotByProspectorRedux,
  setStartDateRedux,
  setEndDateRedux,
  setNumberOfMessagesRedux,
} from "../../store/actions/conversationFilter";

import Cookies from "js-cookie";

import {
  GET_PROSPECTOR_URN_FOR_FILTERING,
  GET_PROSPECTOR_TAGS_FOR_FILERTING,
} from "../../services/Networking/api";

const useConversationFilter = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  {
    /* ----------------------------------------------- */
  }
  const [numberOfMessages, setNumberOfMessages] = useState(0);
  const [filters, setFilters] = useState();
  const [filterResultLoading, setFilterResultLoading] = useState();

  // const colors = ["#069852", "#FF5454", "#FF910F"];
  const [showAdvanceFilters, setShowAdvanceFilters] = useState(false);
  const [isLastMessageNotByProspector, setIsLastMessageNotByProspector] =
    useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [showTagList, setShowTagList] = useState(false);
  const [showProspectorsList, setShowProspectorsList] = useState(false);

  const [prospectorsList, setProspectorsList] = useState([]);
  const [tagList, setTagList] = useState([]);

  const [selectedProspectorsList, setSelectedProspectorsList] = useState([]);
  const [selectedTagList, setSelectedTagList] = useState([]);
  //

  const removeSelectedTag = (index) => {
    const updatedTagList = [
      ...selectedTagList.slice(0, index),
      ...selectedTagList.slice(index + 1),
    ];
    setSelectedTagList(updatedTagList);
  };

  const removeSelectedProspector = (index) => {
    const updatedProspectorList = [
      ...selectedProspectorsList.slice(0, index),
      ...selectedProspectorsList.slice(index + 1),
    ];
    setSelectedProspectorsList(updatedProspectorList);
  };

  const addTag = (tag, color) => {
    if (
      !selectedTagList.some((item) => item.tag === tag && item.color === color)
    ) {
      setSelectedTagList((prevSelectedTags) => [
        ...prevSelectedTags,
        { tag, color },
      ]);
    }
  };

  const addProspector = (prospector) => {
    if (!selectedProspectorsList.includes(prospector)) {
      setSelectedProspectorsList((prevSelectedProspectors) => [
        ...prevSelectedProspectors,
        prospector,
      ]);
    }
  };

  const getProspectors = async () => {
    try {
      const prospectors = await getRequest(
        `${CLIENT_PROSPECTORS}/?valid_session=true`
      );
      const res = await prospectors.json();
      const prospectorsForFilters = res.result.prospectors.map((prospector) => {
        return {
          label: prospector?.full_name,
          value: prospector?.unipile_id,
        };
      });
      setProspectorsList(prospectorsForFilters);
    } catch (error) {
      console.error("Error Loading Client Prospectors:", error);
    }
  };

  const loadClientTags = async () => {
    try {
      const tags = await getClientTags({ isFromFilters: true });
      const tagsForFilters = tags.result.map((tag) => {
        return { label: tag?.title, value: tag?.id, color: tag?.color };
      });
      setTagList(tagsForFilters);
    } catch (error) {
      console.error("Error Loading Client Tags:", error);
    }
  };

  const reduxSelectedTags = useSelector(
    (state) => state.conversationFilter.selectedFilterTags
  );
  const reduxProspectorsList = useSelector(
    (state) => state.conversationFilter.selectedProspectorsList
  );
  const reduxIsLastMessageNotByProspector = useSelector(
    (state) => state.conversationFilter.isLastMessageNotByProspector
  );
  const reduxStartDate = useSelector(
    (state) => state.conversationFilter.startDate
  );
  const reduxEndDate = useSelector((state) => state.conversationFilter.endDate);
  const reduxNumberOfMessages = useSelector(
    (state) => state.conversationFilter.numberOfMessages
  );

  useEffect(() => {
    setSelectedTagList(reduxSelectedTags);
    setSelectedProspectorsList(reduxProspectorsList);
    setIsLastMessageNotByProspector(reduxIsLastMessageNotByProspector);
    setStartDate(reduxStartDate);
    setEndDate(reduxEndDate);
    setNumberOfMessages(reduxNumberOfMessages);
    getProspectors();
    loadClientTags();
  }, []);

  function findIntersection(arr1, arr2) {
    if (
      (arr1 == null || arr1 == undefined || !arr1.length) &&
      (arr2 == null || arr2 == undefined || !arr2.length)
    ) {
      return [];
    }
    if (arr1 == null || arr1 == undefined || !arr1.length) return arr2;
    if (arr2 == null || arr2 == undefined || !arr2.length) return arr1;

    const set1 = new Set(arr1);
    const set2 = new Set(arr2);

    const intersection = [];
    set1.forEach((element) => {
      if (set2.has(element)) {
        intersection.push(element);
      }
    });

    return intersection;
  }

  const onFilterApply = async () => {
    Cookies.set("addFilterToPayload", true);

    setShowProspectorsList(false);
    setShowTagList(false);
    setFilterResultLoading(true);
    const unipile_id = Cookies.get("unipile_id") || null;
    const parsed_unipile_id = JSON.parse(unipile_id) || null;

    //set redux variables
    dispatch(setFilterTagsRedux(selectedTagList));
    dispatch(setProspectorsListRedux(selectedProspectorsList));
    dispatch(setStartDateRedux(startDate));
    dispatch(setEndDateRedux(endDate));
    dispatch(setLastMessageNotByProspectorRedux(isLastMessageNotByProspector));
    dispatch(
      setNumberOfMessagesRedux(
        Number(numberOfMessages) ? Number(numberOfMessages) : 0
      )
    );

    let filterTags = [];
    let filterProspectors = [];
    let finalRes = [];
    let flag = false;
    if (
      selectedTagList.length > 0 &&
      (selectedTagList != null || selectedTagList != "")
    ) {
      try {
        flag = true;
        const params = {
          tagNames: selectedTagList.map((item) => item.tag),
        };
        const response = await postRequest(
          `${GET_PROSPECTOR_TAGS_FOR_FILERTING}`,
          params
        );
        filterTags = await response.json();
        finalRes.push(filterTags.result);
      } catch (error) {
        console.log("Error in fetching tags for filtering: ", error.message);
      }
    }

    if (
      selectedProspectorsList.length > 0 &&
      (selectedProspectorsList != null || selectedProspectorsList != "")
    ) {
      try {
        flag = true;
        const params = {
          fullNames: selectedProspectorsList,
        };
        const response = await postRequest(
          `${GET_PROSPECTOR_URN_FOR_FILTERING}`,
          params
        );
        filterProspectors = await response.json();
        finalRes.push(filterProspectors.result);
      } catch (error) {
        console.log(
          "Error in fetching prospectors for filtering: ",
          error.message
        );
      }
    }

    const intersection = findIntersection(
      filterTags.result,
      filterProspectors.result
    );

    let payload = {};

    if (
      intersection.length > 0 &&
      intersection != undefined &&
      intersection != null
    ) {
      payload = {
        type: "FILTER",
        account_id: intersection,
        readStatus: "all",
        offset: 0,
        count: 20,
        // nextCursor: Array(intersection.length).fill(null),
        nextCursor: null,
        tags: selectedTagList.map((item) => item.tag),
        prospectorNames: selectedProspectorsList,
        lastMessageNotByProspector: isLastMessageNotByProspector,
        startDate: startDate,
        endDate: endDate,
        numberOfMessages: Number(numberOfMessages)
          ? Number(numberOfMessages)
          : 0,
          firstLoad: true,
      };
    } else if (
      (intersection.length <= 0 ||
        intersection == undefined ||
        intersection == null) &&
      flag == false
    ) {
      payload = {
        type: "FILTER",
        account_id: parsed_unipile_id,
        readStatus: "all",
        offset: 0,
        count: 20,
        // nextCursor: Array(parsed_unipile_id.length).fill(null),
        nextCursor: null,
        tags: selectedTagList.map((item) => item.tag),
        prospectorNames: selectedProspectorsList,
        lastMessageNotByProspector: isLastMessageNotByProspector,
        startDate: startDate,
        endDate: endDate,
        numberOfMessages: Number(numberOfMessages)
          ? Number(numberOfMessages)
          : 0,
          firstLoad: true,
      };
    } else if (
      (intersection.length <= 0 ||
        intersection == undefined ||
        intersection == null) &&
      flag == true
    ) {
      payload = {
        type: "FILTER",
        account_id: parsed_unipile_id,
        readStatus: "all",
        offset: 0,
        count: 20,
        // nextCursor: Array(parsed_unipile_id.length).fill(null),
        nextCursor: null,
        tags: selectedTagList.map((item) => item.tag),
        prospectorNames: selectedProspectorsList,
        lastMessageNotByProspector: isLastMessageNotByProspector,
        startDate: startDate,
        endDate: endDate,
        numberOfMessages: Number(numberOfMessages)
          ? Number(numberOfMessages)
          : 0,
          firstLoad: true,
      };
      setFilterResultLoading(false);
      setFilters(payload);
      props?.onFilterConversationsResult([], {
        ...payload,
        nextCursor: "",
      });
      return;
    } else {
      payload = {
        type: "FILTER",
        account_id: parsed_unipile_id,
        readStatus: "all",
        offset: 0,
        count: 20,
        // nextCursor: Array(parsed_unipile_id.length).fill(null),
        nextCursor: null,
        tags: selectedTagList.map((item) => item.tag),
        prospectorNames: selectedProspectorsList,
        lastMessageNotByProspector: isLastMessageNotByProspector,
        startDate: startDate,
        endDate: endDate,
        numberOfMessages: Number(numberOfMessages)
          ? Number(numberOfMessages)
          : 0,
        firstLoad: true,
      };
    }

    const filteredConversations = await getFilteredConversations(payload);
    setFilterResultLoading(false);
    setFilters(payload);
    props?.onFilterConversationsResult(filteredConversations?.conversations, {
      ...payload,
      nextCursor: filteredConversations?.nextCursor,
      offset: filteredConversations?.offset,
    });
  };

  const onFilterReset = () => {
    Cookies.set("addFilterToPayload", false);
    //add dispatch call here
    setShowTagList(false);
    setShowProspectorsList(false);
    setSelectedProspectorsList([]);
    setSelectedTagList([]);
    setStartDate("");
    setEndDate("");
    setIsLastMessageNotByProspector(false);
    setNumberOfMessages(0);

    // dispatch(setFilterTagsRedux(selectedTagList));
    // dispatch(setProspectorsListRedux(selectedProspectorsList));
    // dispatch(setStartDateRedux(startDate));
    // dispatch(setEndDateRedux(endDate));
    // dispatch(setLastMessageNotByProspectorRedux(isLastMessageNotByProspector));
    // dispatch(
    //   setNumberOfMessagesRedux(
    //     Number(numberOfMessages) ? Number(numberOfMessages) : 0
    //   )
    // );

    dispatch(setFilterTagsRedux([]));
    dispatch(setProspectorsListRedux([]));
    dispatch(setStartDateRedux(""));
    dispatch(setEndDateRedux(""));
    dispatch(setLastMessageNotByProspectorRedux(false));
    dispatch(setNumberOfMessagesRedux(0));

    // props?.setFilterReset((curr) => !curr);
    props?.setAddFilterToPayload(false);
    props?.setFilterReset();
    props?.onClose();
  };

  {
    /* ----------------------------------------------- */
  }

  // const lastMessageBy = [
  //   {
  //     label: t("LAST_MESSAGE_ME"),
  //     value: "P",
  //   },
  //   {
  //     label: t("LAST_MESSAGE_OTHER"),
  //     value: "I",
  //   },
  //   {
  //     label: t("LAST_MESSAGE_ANY"),
  //     value: "",
  //   },
  // ];
  // const states = [
  //   {
  //     label: t("MESSAGE_UNREAD"),
  //     value: "unread",
  //   },
  //   {
  //     label: t("MESSAGE_READ"),
  //     value: "read",
  //   },
  //   {
  //     label: t("MESSAGE_ALL"),
  //     value: "all",
  //   },
  // ];
  // const [interestChips, updateInterestChips] = useState([
  //   {
  //     label: t("APPOINTMENT_MADE"),
  //     value: "APPOINTMENT",
  //     style: { background: "#01BC62" },
  //     selected: false,
  //   },
  //   {
  //     label: t("REMINDER"),
  //     value: "REMINDER",
  //     style: { background: "#FF910F" },
  //     selected: false,
  //   },
  //   {
  //     label: t("NOT_INTERESTED"),
  //     value: "NOT_INTERESETED",
  //     style: { background: "#FF5454" },
  //     selected: false,
  //   },
  // ]);

  // const [filters, setFilters] = useState();
  // const [selectedLastMessageByFilter, setSelectedLastMessageByFilter] =
  //   useState(lastMessageBy[2]);
  // const [clientProspectors, setClientProspectors] = useState();
  // const [clientTags, setClientTags] = useState();

  // const [selectedState, setSelectedState] = useState(states[2]);
  // const [conversationContent, setConversationContent] = useState("");
  // const [numberOfMessages, setNumberOfMessages] = useState();
  // const [neverAnswered, setNeverAnswered] = useState();
  // const [selectedLinkedinAccount, setSelectedLinkedinAccount] = useState();
  // const [selectedInterest, setSelectedInterest] = useState();
  // const [selectedTags, setSelectedTags] = useState();
  // const [filterResultLoading, setFilterResultLoading] = useState();

  // useEffect(() => {
  //   getProspectors();
  //   loadClientTags();
  // }, []);

  // useEffect(() => {
  //   prefillSelectedFilters();
  // }, [clientProspectors, clientTags]);
  // const getProspectors = async () => {
  //   const prospectors = await getClientProspectors();
  //   const prospectorsForFilters = prospectors.map((prospector) => {
  //     return {
  //       label: prospector?.prospector_data?.name,
  //       value: prospector?.prospector_urn,
  //     };
  //   });
  //   setClientProspectors(prospectorsForFilters);
  // };

  // const loadClientTags = async () => {
  //   const tags = await getClientTags({});
  //   const tagsForFilters = tags.result.map((tag) => {
  //     return { label: tag?.title, value: tag?.id };
  //   });
  //   setClientTags(tagsForFilters);
  // };

  // const onInterestChipSelection = (selectedIc) => {
  //   const updatedIc = interestChips.map((ic) => {
  //     if (ic.value == selectedIc?.value) ic.selected = !ic.selected;

  //     return ic;
  //   });
  //   updateInterestChips(updatedIc);
  // };

  // const prefillSelectedFilters = () => {
  //   let appliedFilters = props?.filters;

  //   if (!clientTags && !clientProspectors) {
  //     return;
  //   }

  //   if (!appliedFilters) {
  //     appliedFilters = getFilterPayloadFromUrl();
  //   }

  //   const selectedTags = clientTags?.filter(
  //     (tag) => tag.value == appliedFilters?.tagIds?.[0]
  //   );
  //   const selectedLastMessageBy = lastMessageBy.filter(
  //     (by) => by.value == appliedFilters?.lastSentBy
  //   );
  //   const selectedState = states.filter(
  //     (by) => by.value == appliedFilters?.readStatus
  //   );
  //   const selectedProspects = clientProspectors?.filter(
  //     (p) => p.value == appliedFilters?.prospectorUrns?.[0]
  //   );
  //   const startDate = new Date(appliedFilters?.timestamp?.start);
  //   const endDate = new Date(appliedFilters?.timestamp?.end);

  //   setSelectedState(selectedState?.[0]);
  //   setSelectedTags(selectedTags?.[0]);
  //   setSelectedLastMessageByFilter(selectedLastMessageBy?.[0]);
  //   setStartDate(formatDateFromMillis(startDate));
  //   setEndDate(formatDateFromMillis(endDate));

  //   setSelectedLinkedinAccount(selectedProspects?.[0]);
  //   setConversationContent(appliedFilters?.messageQuery);

  //   if (
  //     appliedFilters?.numberOfMessages &&
  //     appliedFilters?.numberOfMessages != "null" &&
  //     appliedFilters?.numberOfMessages != "undefined"
  //   )
  //     setNumberOfMessages(appliedFilters?.numberOfMessages);
  //   setNeverAnswered(appliedFilters?.neverAnswered);

  //   if (appliedFilters?.interestChips)
  //     updateInterestChips(appliedFilters?.interestChips);
  // };

  // const onFilterApply = async () => {
  //   setFilterResultLoading(true);

  //   const timestamp = {
  //     start: new Date(startDate).getTime() || "",
  //     end: new Date(endDate).getTime() || "",
  //   };
  //   const states = {
  //     isInterested: interestChips?.filter(
  //       (ic) => ic.value == "APPOINTMENT"
  //     )?.[0]?.selected
  //       ? true
  //       : interestChips?.filter((ic) => ic.value == "NOT_INTERESTED")?.[0]
  //           ?.selected
  //       ? false
  //       : null,
  //     reminderDuration: interestChips?.filter(
  //       (ic) => ic.value == "REMINDER"
  //     )?.[0]?.selected
  //       ? true
  //       : false,
  //   };

  //   const unipile_id = Cookies.get("unipile_id") || null;
  //   const parsed_unipile_id = JSON.parse(unipile_id) || null;

  //   const payload = {
  //     type: "FILTER",
  //     account_id: parsed_unipile_id,
  //     readStatus: "all",
  //     count: 20,
  //     nextCursor: Array(parsed_unipile_id.length).fill(null),
  //     tags: selectedTagList,
  //     prospectorNames: selectedProspectorsList,
  //     lastMessageNotByProspector: isLastMessageNotByProspector,
  //     startDate: startDate,
  //     endDate: endDate,
  //   };

  //   const filterPayload = {
  //     prospectorUrns: selectedLinkedinAccount?.value
  //       ? [selectedLinkedinAccount?.value]
  //       : [],
  //     messageQuery: conversationContent || "",
  //     tagIds: selectedTags?.value ? [selectedTags?.value] : [],
  //     states: states,
  //     lastSentBy: selectedLastMessageByFilter?.value,
  //     nextCursor: "",
  //     readStatus: selectedState?.value,
  //     // timestamp,
  //     neverAnswered,
  //     numberOfMessages,
  //   };
  //   const filteredConversations = await getFilteredConversations(payload);
  //   // console.log({filteredConversations});
  //   setFilterResultLoading(false);
  //   setFilters(payload);
  //   props?.onFilterConversationsResult(filteredConversations?.conversations, {
  //     ...payload,
  //     interestChips,
  //     nextCursor: filteredConversations?.nextCursor,
  //   });
  //   setUrlQueries(payload);
  // };

  // const setUrlQueries = (filterPayload) => {
  //   const {
  //     prospectorUrns = {},
  //     messageQuery = "",
  //     tagIds = {},
  //     states = {},
  //     lastSentBy = {},
  //     nextCursor = "",
  //     readStatus = {},
  //     timestamp = { start: "", end: "" },
  //     numberOfMessages,
  //     neverAnswered,
  //   } = filterPayload;
  //   insertUrlParam("states", JSON.stringify(states));
  //   insertUrlParam("prospectorUrns", JSON.stringify(prospectorUrns));
  //   insertUrlParam("messageQuery", messageQuery);

  //   insertUrlParam("tagIds", JSON.stringify(tagIds));
  //   insertUrlParam("lastSentBy", JSON.stringify(lastSentBy));
  //   insertUrlParam("nextCursor", nextCursor);

  //   insertUrlParam("readStatus", JSON.stringify(readStatus));
  //   insertUrlParam("timestamp", JSON.stringify(timestamp));

  //   insertUrlParam("neverAnswered", neverAnswered);
  //   insertUrlParam("numberOfMessages", numberOfMessages);
  // };

  // const getFilterPayloadFromUrl = () => {
  //   const urlParams = new Proxy(new URLSearchParams(window.location.search), {
  //     get: (searchParams, prop) => searchParams.get(prop),
  //   });
  //   const {
  //     prospectorUrns = {},
  //     messageQuery = "",
  //     tagIds = {},
  //     states = {},
  //     lastSentBy = {},
  //     nextCursor = "",
  //     readStatus = {},
  //     timestamp = { start: "", end: "" },
  //     numberOfMessages,
  //     neverAnswered = false,
  //   } = urlParams;

  //   const filterPayload = {
  //     prospectorUrns: JSON.parse(prospectorUrns),
  //     messageQuery,
  //     tagIds: JSON.parse(tagIds),
  //     states: JSON.parse(states),
  //     lastSentBy: JSON.parse(lastSentBy),
  //     nextCursor,
  //     readStatus: JSON.parse(readStatus),
  //     timestamp: JSON.parse(timestamp),
  //     neverAnswered: neverAnswered === true || neverAnswered === "true",
  //     numberOfMessages,
  //   };

  //   return filterPayload;
  // };

  // const onFilterReset = async () => {
  //   setSelectedState({});
  //   setSelectedTags({});
  //   setSelectedLastMessageByFilter({});
  //   setStartDate({});
  //   setEndDate({});

  //   setSelectedLinkedinAccount({});
  //   setConversationContent("");

  //   const updatedIc = interestChips.map((ic) => {
  //     ic.selected = false;

  //     return ic;
  //   });
  //   updateInterestChips(updatedIc);
  // };

  // return {
  //   clientProspectors,
  //   lastMessageBy,
  //   states,
  //   interestChips,
  //   selectedLastMessageByFilter,
  //   setSelectedLastMessageByFilter,
  //   selectedState,
  //   setSelectedState,
  //   conversationContent,
  //   setConversationContent,
  //   numberOfMessages,
  //   setNumberOfMessages,
  //   startDate,
  //   setStartDate,
  //   endDate,
  //   setEndDate,
  //   neverAnswered,
  //   setNeverAnswered,
  //   selectedLinkedinAccount,
  //   setSelectedLinkedinAccount,
  //   selectedInterest,
  //   setSelectedInterest,
  //   selectedTags,
  //   setSelectedTags,
  //   clientTags,
  //   onFilterApply,
  //   onFilterReset,
  //   filterResultLoading,
  //   onInterestChipSelection,
  //   //---------------------------------
  //   selectedTagList,
  //   removeSelectedTag,
  //   setShowTagList,
  //   showTagList,
  //   tagList,
  //   addTag,
  //   selectedProspectorsList,
  //   removeSelectedProspector,
  //   setShowProspectorsList,
  //   showProspectorsList,
  //   prospectorsList,
  //   addProspector,
  //   colors,
  //   showAdvanceFilters,
  //   setShowAdvanceFilters,
  //   isLastMessageNotByProspector,
  //   setIsLastMessageNotByProspector,
  // };

  return {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    onFilterApply,
    onFilterReset,
    filterResultLoading,
    selectedTagList,
    removeSelectedTag,
    setShowTagList,
    showTagList,
    tagList,
    addTag,
    selectedProspectorsList,
    removeSelectedProspector,
    setShowProspectorsList,
    showProspectorsList,
    prospectorsList,
    addProspector,
    showAdvanceFilters,
    setShowAdvanceFilters,
    isLastMessageNotByProspector,
    setIsLastMessageNotByProspector,
    numberOfMessages,
    setNumberOfMessages,
  };
};

export default useConversationFilter;
