import React, { useEffect } from 'react'

const useOutsideClickClose = (ref, handler) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
          if (ref.current && !ref.current.contains(event.target)) {
            handler();
          }
        };
    
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
          // Unbind the event listener on cleanup
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [ref, handler]);
}
 
export default useOutsideClickClose;