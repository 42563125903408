import React, { useState } from "react";
import MPLogo from "../../../assets/images/mirrorProfilesLogo.png";
import "../login/styles.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { forgotPassword, changePassword, loginApi } from "../../../services/auth";
import { toast } from "react-toastify";
import BackArrow from "../../../assets/images/left-arrow-blue.svg";
import Success from "../../../assets/images/success.svg";
import Pending from "../../../assets/images/pending.svg";
import { useTranslation } from "react-i18next";

const ChangePassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordChanged, setConfirmPasswordChanged] = useState(false)

  const [validations, setValidations] = useState({
    minChar: false, 
    alphabets: false,
    number: false, 
    specialChar: false
  });

  const validationTextMap = {
    minChar: t("VALIDATION_STRING_MINCHAR"), 
    alphabets: t("VALIDATION_STRING_ALPHABETS"),
    number: t("VALIDATION_STRING_NUMBER"), 
    specialChar: t("VALIDATION_STRING_SPECIALCHAR")
  }

  const handlePasswordChange = (event) => {
    const currentText = event.target.value
    setPassword(currentText);
    validatePassword(currentText)
    

  };
  const validatePassword = (password) => {
    let {minChar, alphabets, number, specialChar} = validations
    if(password.length<15) {
      minChar = false
    } else minChar = true

    if(/[a-zA-Z]/g.test(password)){
      alphabets=true
    }else alphabets=false

    if(/\d/.test(password)) 
      number=true
    else 
      number=false

    if(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password))
      specialChar=true
      else specialChar=false

    setValidations({minChar, alphabets, number, specialChar})
  }

  const isValidSubmit = () => {
    const anyValidationMissing = Object.keys(validations).some(val=>!validations[val])
    return !anyValidationMissing && password==confirmPassword
  }
  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    setConfirmPasswordChanged(true)
  };

   
  const onSend = async () => {

    const resetToken = searchParams.get("resetToken");
    console.log("searchParams", searchParams)
    if(!resetToken) {
      toast.error("Reset token missing")
      return
    }
    
    if(!isValidSubmit())  {
      toast.error("Validation missing")
      return
    }

    const { code, result } = await changePassword({
      newPassword: password,
      resetToken,
    });
    if (code == 200) {
      toast.success("Password changed successfully! Redirecting to login");
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } else toast.error("Something went wrong");
  };
  const onRetour = () => {
    // navigate("/login");
    navigate("/account-management/myaccount");
  };
  return (
    <div className="login-container">
      <img src={MPLogo} className="logo" />
      <div className="login-details">
        <div className="login-head">{t("CHANGE_PASSWORD_TITLE")}</div>
        <div
          style={{
            textAlign: "left",
            display: "flex",
            width: "80%",
            margin: "50px auto auto auto",
          }}
        >
          <div style={{ flex: 1 }}>
            <div className="label-wrap">
              <label htmlFor="password">{t("CHANGE_PASSWORD_PWD")}</label>
            </div>
            <input
              type="password"
              id="password"
              value={password}
              placeholder="********"
              onChange={handlePasswordChange}
            />
          </div>
          <div style={{ flex: 1, marginLeft: 36 }}>
            <div className="label-wrap">
              <label htmlFor="confirmPassword">
              {t("CHANGE_PASSWORD_CONFIRM_PWD")}
              </label>
            </div>
            <input
              type="password"
              id="confirmPassword"
              placeholder="********"
              value={confirmPassword}
              style={{borderColor: confirmPasswordChanged && password!=confirmPassword ? "red": "#1E93FD"}}
              onChange={handleConfirmPasswordChange}
            />
          </div>
        </div>
        <div style={{textAlign: "left", marginLeft: "10%"}}>
          {
            Object.keys(validations).map(key=>{
              return (
                <div style={{marginTop: 8}}>
                  <img src={validations[key]?Success:Pending} style={{marginRight: 10, position: "relative", top: 2}}/>
                  <span className="validation-string">
                    {validationTextMap[key]}
                    </span>
                </div>
              )
            })
          }
        </div>
        <button onClick={onSend} className="login-btn">
          {t("SUBMIT")}
        </button>
        <br />

        <button onClick={onRetour} className="retour-btn">
          <img
            src={BackArrow}
            style={{ marginRight: 13, position: "relative", top: 2 }}
          />
          {t("BACK_CTA")}

        </button>
      </div>
    </div>
  );
};

export default ChangePassword;
