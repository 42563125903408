import React from 'react'

const List = ({children}) => {
  return (
    <div className="flex flex-col items-center w-[100%] gap-3 max-h-[18.5rem] overflow-y-auto overflow-x-hidden">
        {children}
    </div>
  )
}

export default List;