import React, {useEffect} from "react";
import EmojiPicker from "emoji-picker-react";
import MPModalHOC from "../MPModalHOC";

const MPEmojiPicker = ({ onEmojiClick, setShowEmojiPicker }) => {
  useEffect(() => {
    function handleClickOutside(e) {
        const mpEmojiWrapper = document.querySelector("#mpEmojiWrapper")
        if(!mpEmojiWrapper?.contains(e.target))
            setShowEmojiPicker(false);
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <EmojiPicker
      style={{
        position: "flex",
        top: "100px", // Adjust this value as needed
        right: "100px", // Adjust this value as needed
        zIndex: 9999,
      }}
      height="100%"
      width="100%"
      lazyLoadEmojis={true}
      searchDisabled="true"
      autoFocusSearch="false"
      previewConfig={{ showPreview: false }}
      onClose={() => setShowEmojiPicker(false)}
      onEmojiClick={onEmojiClick}
    />
  );
};

export default MPModalHOC(MPEmojiPicker);
