import React from "react";
import { useSelector } from "react-redux";
const UnreadCount = () => {
  const unreadCount = useSelector((state) => state?.conversation?.unreadCount);
  return  (
    <div style={styles.counterWrap}>{unreadCount}</div>
  ) 
};

const styles = {
  counterWrap: {
    borderRadius: 9,
    border: "1px solid #3153C7",
    display: "inline-flex",
    width: 35,
    justifyContent: "center",
    fontSize: 12,
    marginLeft: 8,
  },
};
export default UnreadCount;
