import { ADD_MESSAGE, SET_MESSAGES, UPDATE_MESSAGE, SELECTED_MESSAGE, UPDATE_MESSAGE_CONTENT, DELETE_MESSAGE } from "./types";

const addMessage = (newMessage) => {
  return {
    type: ADD_MESSAGE,
    payload: newMessage
  };
};


const setMessages = (messages) => {
  return {
    type: SET_MESSAGES,
    payload: messages
  };
};

const updateMessage = (message) => {
  return {
    type: UPDATE_MESSAGE,
    payload: message
  };
};

const setSelectedMessage = (message) => {
  return {
    type: SELECTED_MESSAGE,
    payload: message
  }
}

const updateMessageContent = (message) => {
  return {
    type: UPDATE_MESSAGE_CONTENT,
    payload: message
  }
}

const deleteMessage = (message) => {
  return {
    type: DELETE_MESSAGE,
    payload: message
  }
}

export { addMessage, setMessages, updateMessage, setSelectedMessage, updateMessageContent, deleteMessage };
