import React from "react";
// import BlockIcon from "../../assets/images/block-icon.svg";
import { useTranslation } from "react-i18next";
import { blockAccount } from "../../services/Conversation";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedConversation } from "../../store/actions/conversation";
import { toast } from 'react-toastify'
const BlockAccount = () => {
  const { t } = useTranslation();
  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  const onBlockAccount = async () => {
    const selectedConversation = state?.conversation?.selectedConversation;
    if (
      !selectedConversation?.prospector?.entityUrn ||
      !selectedConversation?.interlocutor?.entityUrn
    )
      return;
    const prospectorUrn = selectedConversation?.prospector?.entityUrn;
    const memberUrn = selectedConversation?.interlocutor?.entityUrn;
    const body = {
      memberUrn,
      prospectorUrn,
    };
    const blockedAccount = await blockAccount(body);
    if(blockedAccount?.status)    {
        removeConversationFromList()
    }
  };

  const removeConversationFromList = () => {
    dispatch(setSelectedConversation(null))
    toast.success(t("BLOCKED_SUCCESS"), {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setTimeout(() => {
      window.location.reload()
    }, 1500);
  }
  return (
    <div style={blockStyles.container}>
      {/* <img src={BlockIcon} /> */}
      <span onClick={onBlockAccount} style={blockStyles.blockText}>
        {t("BLOCK")}
      </span>
    </div>
  );
};

const blockStyles = {
  container: {
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
    // marginBottom: "20px",
  },
  blockText: {
    color: "#FA5D72",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: 400,
    marginLeft: "10px",
    cursor: "pointer",
  },
};
export default BlockAccount;
