import React from "react";
import MPInputWithLabel from "../MpInputWithLabel";

const MPCounterInput = ({
  counter,
  label,
  placeholderText,
  setCounter = () => {},
}) => {
  const incrementCounter = () => {
    const currentCounterValue = isNaN(parseInt(counter)) ? 0 : parseInt(counter) + 1;
    setCounter(Math.min(currentCounterValue, 10));
  }

  const decrementCounter = () => {
    const currentCounterValue = isNaN(parseInt(counter)) ? 0 : parseInt(counter) - 1;
    setCounter(Math.max(currentCounterValue, 0));
  }

  return (
    <div>
      <div style={{fontSize: 11}}>{label}</div>
      <div style={{ display: "flex", alignItems: "center"}}>
        <div
          style={{ ...styles.buttons, ...styles.buttonLeft }}
          onClick={() => decrementCounter()}
        >
          -
        </div>
        <MPInputWithLabel
          styles={{ textAlign: "center", borderRadius: 0, width: 50, height: 30 }}
          placeholderText={placeholderText}
          value={counter}
          onValueChange={setCounter}
        />
        <div
          style={{ ...styles.buttons, ...styles.buttonRight }}
          onClick={() => incrementCounter()}
        >
          +
        </div>
      </div>
    </div>
  );
};

const styles = {
  buttons: {
    background: "linear-gradient(90deg, #1E93FD 0%, #3154C8 100%)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 30,
    width: 30,
    color: "#fff",
    fontWeight: 800,
    fontSize: 24,
    position: "relative",
    top: "-2px",
    cursor: "pointer"
  },
  buttonLeft: {
    borderRadius: "4.189px 0px 0px 4.189px",
  },
  buttonRight: {
    borderRadius: "0px 4.189px 4.189px 0px",
  },
};
export default MPCounterInput;
