import React, {useState} from 'react';
import CollapsedDashboardRight from './Collapsed';
import ExpandedDashboardRight from './Expanded';

const DashboardRight = (props) => {
    const [isCollaped, setIsCollapsed] = useState(true)
    return (<>
       {
           isCollaped ? <CollapsedDashboardRight setIsCollapsed={setIsCollapsed} /> : <ExpandedDashboardRight setIsCollapsed={setIsCollapsed} prefTrigger={props.prefTrigger2} tagTrigger={props.tagTrigger}/>
       }
    </>);
}
 
export default DashboardRight;