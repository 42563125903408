import React from 'react';
import Conversation from './Conversation';
import MessageBox from './MessageBox';
import { useSelector } from "react-redux";
import "./styles.css";

const DashboardCenter = ({onCollapse, setShowProfileInfo, prefTrigger, tagTrigger}) => {
    const state = useSelector((state) => state);

    return (<>{
        state?.conversation?.selectedConversation && 
                <Conversation onCollapse={onCollapse} setShowProfileInfo={setShowProfileInfo} prefTrigger={prefTrigger} tagTrigger={tagTrigger}/>
        }
    </>);
}
 
export default DashboardCenter;