import React, { useState, useEffect, useRef } from "react";
import "./styles.css";
import { useSelector, useDispatch } from "react-redux";
import {
  getProspectorTagsForUser,
  addProspectorTagsForUser,
  deleteProspectorTagForUser,
  editTagForUser,
} from "../../services/Tags";
import TagChip from "./Chip";
import TagAdd from "../../assets/images/tagAdd.svg";
import TagListModal from "./ListModal";
import { useTranslation } from "react-i18next";
import useOutsideClickClose from "../MPFactory/Hooks/outsideClickClose";
import TrashIcon from "../../assets/images/trashcan_icon.svg";
import { stringToColour } from "../../services/Utils";
import EditIcon from "../../assets/images/tabler_edit.svg";
import CrossIcon from "../../assets/images/cross.svg";
import redTrashIcon from "../../assets/images/red_trashcan_icon.svg";
import colorPickerIcon from "../../assets/images/color_picker_icon.svg";
import WhiteCrossIcon from "../../assets/images/white_close.svg";

import { triggerWebhook } from "../../services/Webhook";
import { setConversationPreferenceText } from "../../store/actions/conversationPreference";

const Tag = ({ prefTrigger, tagTrigger }) => {
  const [selectedConversation, setSelectedConversation] = useState({});
  const [appliedTags, setAppliedTags] = useState([]);
  const [expandTagModal, setExpandTagModal] = useState(false);
  const [showEditMenu, setShowEditMenu] = useState(false);
  const state = useSelector((state) => state);
  const { t } = useTranslation();
  const tagModalRef = useRef(null);
  const dispatch = useDispatch();

  const [selectedColor, setSelectedColor] = useState(null);
  const [customColor, setCustomColor] = useState("#ffffff"); // Default custom color

  const predefinedColors = ["#FF3377", "#C331C8", "#194D80", "#33DDFF"]; // Predefined colors
  const [currentEditTagId, setCurrentEditTagId] = useState(null);
  const [currentEditTagText, setCurrentEditTagText] = useState("");
  const [currentEditTagWebhookUrl, setCurrentEditTagWebhookUrl] = useState("");
  const [currentEditTagColor, setCurrentEditTagColor] = useState("");
  const [editedTagText, setEditedTagText] = useState("");
  const [editedTagWebhookUrl, setEditedTagWebhookUrl] = useState("");
  const [webhookText, setWebhookText] = useState("");

  const selectColor = (color) => {
    setSelectedColor(color);
  };

  const handleCustomColorChange = (e) => {
    setCustomColor(e.target.value);
    setSelectedColor(e.target.value);
  };

  const openEditMenu = (tag) => {
    setCurrentEditTagId(tag?.tagId);

    setCurrentEditTagText(tag?.title || tag?.tag?.title);
    setCurrentEditTagWebhookUrl(tag?.webhook_url || tag?.tag?.webhook_url);

    setCurrentEditTagColor(tag?.color || tag?.tag?.color || null);

    setEditedTagText(tag?.title || tag?.tag?.title);
    setEditedTagWebhookUrl(tag?.webhook_url || tag?.tag?.webhook_url);

    setShowEditMenu(true);
  };

  const closeEditMenu = () => {
    setCurrentEditTagId(null);
    setCurrentEditTagText("");
    setSelectedColor(null);
    setShowEditMenu(false);
  };

  const handleDeleteTag = (flag = false) => {
    closeEditMenu();
    onTagDelete(currentEditTagId, true, flag);
  };

  const sendWebhook = async (webhookUrl) => {
    if (!webhookUrl) return;

    const interlocutor =
      state?.conversation?.selectedConversation?.interlocutor;
    const prospector = state?.conversation?.selectedConversation?.prospector;
    const basicDetails = state?.contactInfo?.basicDetails;
    const body = {
      key: "0",
      webhook_url: webhookUrl,
      linkedin_profile_name:
        interlocutor?.firstName + " " + interlocutor?.lastName,
      linkedin_profile_url: interlocutor?.profileUrl,
      linkedin_account_sender:
        prospector?.firstName + " " + prospector?.lastName,
      linkedin_profile_title: interlocutor?.headline,
      linkedin_profile_phone: basicDetails?.phoneNumbers?.[0],
      linkedin_profile_email: basicDetails?.result,
      linkedin_conversations: state?.message?.messages,
      prospector_urn: prospector?.entityUrn,
      conversation_urn: state?.conversation?.selectedConversation?.entityUrn,
      member_urn_id:
        state?.conversation?.selectedConversation?.interlocutor?.memberUrn?.replace(
          "urn:li:member:",
          ""
        ),
    };
    const webhook = await triggerWebhook(body);
  };

  useOutsideClickClose(tagModalRef, () => setExpandTagModal(false));

  useEffect(() => {
    if (state?.conversation?.selectedConversation) {
      setSelectedConversation(state?.conversation?.selectedConversation);
    }
  }, [state?.conversation?.selectedConversation?.entityUrn]);

  useEffect(() => {
    loadTags();
  }, [selectedConversation, prefTrigger]);

  const loadTags = async () => {
    if (
      !selectedConversation?.prospector?.entityUrn ||
      !selectedConversation?.interlocutor?.entityUrn
    )
      return;

    const textType = state?.conversationPreferenceReducer?.textType;
    // console.log("NEW STATE FROM TAGS: ", textType);
    const query = {
      prospectorUrn: selectedConversation?.prospector?.entityUrn,
    };
    const tags = await getProspectorTagsForUser(
      selectedConversation?.entityUrn,
      query
    );
    setAppliedTags(tags?.result);

    try {
      if (textType?.textType == null) {
        return;
      }
      tags.result.map((tag) => {
        if (
          tag?.tag?.title.toLowerCase() === textType?.textType.toLowerCase() &&
          tag?.tag?.webhook_url !== null
        ) {
          sendWebhook(tag?.tag?.webhook_url);
          dispatch(setConversationPreferenceText({ textType: null }));
          return;
        }
      });
    } catch (error) {
      console.log("Error triggering webhook: ", error.message);
    }
  };

  const addTagsByTitle = async (title) => {
    if (title.trim() == null || title.trim() == "") return;

    const body = {
      title,
      prospectorUrn: selectedConversation?.prospector?.entityUrn,
    };
    const newTag = await addProspectorTagsForUser(
      selectedConversation?.entityUrn,
      body
    );

    setExpandTagModal(false);

    if (!newTag?.result?.existing) {
      addToAppliedTag(newTag?.result);
    }
  };

  const addTagsByTagId = async (tagId) => {
    const body = {
      tagId,
      prospectorUrn: selectedConversation?.prospector?.entityUrn,
    };
    const newTag = await addProspectorTagsForUser(
      selectedConversation?.entityUrn,
      body
    );
    setExpandTagModal(false);

    try {
      const res = await sendWebhook(newTag?.result?.tag?.webhook_url);
    } catch (error) {
      console.log("Error triggering webhook: ", error.message);
    }

    if (!newTag?.result?.existing) addToAppliedTag(newTag?.result);
  };

  const onTagDelete = async (tagId, delPrevs = false, flag = false) => {
    const body = {
      tagId,
      prospectorUrn: selectedConversation?.prospector?.entityUrn,
      delPrevs,
      deleteFromDB: flag,
    };
    const deletedTag = await deleteProspectorTagForUser(
      selectedConversation?.entityUrn,
      body
    );
    if (deletedTag?.result) {
      deleteFromAppliedTag(tagId);
      tagTrigger();
      loadTags();
    }
  };

  const onTagEdit = async (
    tagId,
    color = null,
    title = null,
    webhook_url = null
  ) => {
    if (
      currentEditTagText == editedTagText &&
      currentEditTagWebhookUrl == editedTagWebhookUrl &&
      color == null
    ) {
      setShowEditMenu(false);
      return;
    }

    let body = {};

    if (
      title == "Interested" ||
      title == "Rendez-vous pris" ||
      title == "Not Interested" ||
      title == "Pas intéressé" ||
      title == "Reminder" ||
      title == "A suivre"
    ) {
      body = {
        tagId,
        color,
        title,
        webhook_url,
        flag: true,
        conversationUrn: selectedConversation?.entityUrn,
        prospectorUrn: selectedConversation?.prospector?.entityUrn,
      };
    } else {
      body = {
        tagId,
        color,
        title,
        webhook_url,
        flag: false,
        conversationUrn: null,
        prospectorUrn: null,
      };
    }
    const editedTag = await editTagForUser(body);
    if (editedTag?.result) {
      tagTrigger();
      loadTags();
      setShowEditMenu(false);
    }
  };

  const addToAppliedTag = async (tag) => {
    let newAppliedTags = [...appliedTags, tag];

    const removeItems = (item1, item2) => {
      const updatedArray = newAppliedTags.filter(
        (item) => item.tag.title !== item1 && item.tag.title !== item2
      );
      newAppliedTags = updatedArray;
      setAppliedTags(newAppliedTags);
    };

    const findItems = (current, text, text2) => {
      const filteredArray = current.filter(
        (item) => item.tag.title === text || item.tag.title === text2
      );
      return filteredArray;
    };

    try {
      if (
        tag.tag.title == "Interested" ||
        tag.tag.title == "Rendez-vous pris"
      ) {
        const res = findItems(
          newAppliedTags,
          "Not Interested",
          "Pas intéressé"
        );
        removeItems("Not Interested", "Pas intéressé");
        res.map(async (tag) => await onTagDelete(tag.tagId));
      } else if (
        tag.tag.title == "Not Interested" ||
        tag.tag.title == "Pas intéressé"
      ) {
        const res = findItems(newAppliedTags, "Interested", "Rendez-vous pris");
        removeItems("Interested", "Rendez-vous pris");
        res.map(async (tag) => await onTagDelete(tag.tagId));
      } else {
        setAppliedTags(newAppliedTags);
      }
      tagTrigger();
    } catch (error) {
      setAppliedTags(newAppliedTags);
      tagTrigger();
    }
  };

  const deleteFromAppliedTag = async (tagId) => {
    const newAppliedTags = appliedTags.filter((tag) => tag?.tagId != tagId);
    setAppliedTags(newAppliedTags);
  };

  return (
    <div className="tag-list-wrapper">
      <div className="tags-overflow-wrap flex">
        {!appliedTags?.length ? (
          <span className="no-tag-text">{t("NO_TAG_FOR_USER")}</span>
        ) : (
          appliedTags?.map((tag) => (
            <div className="flex flex-col justify-center items-center gap-1">
              <span
                className="tag-chip-new"
                style={{
                  background: tag?.tag?.color
                    ? tag.tag.color
                    : stringToColour(tag?.title || tag?.tag?.title),
                }}
              >
                {tag?.title || tag?.tag?.title}
                <img
                  alt="Edit tag"
                  src={EditIcon}
                  onClick={() => openEditMenu(tag)}
                  style={{ marginLeft: "6px", cursor: "pointer" }}
                />
                <img
                  src={WhiteCrossIcon}
                  width={12}
                  onClick={() => onTagDelete(tag?.tagId, true)}
                  className="cursor-pointer ml-1"
                />
              </span>
            </div>
          ))
        )}

        {showEditMenu && (
          <div className="bg-white border border-solid border-[#3498db] w-[72%] top-[45px] absolute mt-2 px-2 py-3 rounded-lg text-center z-[1000]">
            <div className="absolute ml-[205px] mt-[-10px] cursor-pointer">
              <img
                alt="Cross"
                src={CrossIcon}
                onClick={() => closeEditMenu()}
              />
            </div>
            <div className="pb-3"></div>
            <div className="flex flex-col gap-3">
              <div className="flex justify-between items-center px-3">
                <span className="text-[13px] text-[#757575]"> Title </span>
                <input
                  type="text"
                  onChange={(e) => setEditedTagText(e.target.value)}
                  value={editedTagText}
                  placeholder="Votre titre"
                  className={`border border-solid border-[#1e93fd] w-[57%] rounded-[5px] h-[30px] pl-2 text-[13px]`}
                />
              </div>

              <div className="flex justify-between items-center px-3">
                <span className="text-[13px] text-[#757575]"> Webhook </span>
                <input
                  type="text"
                  onChange={(e) => setEditedTagWebhookUrl(e.target.value)}
                  value={editedTagWebhookUrl}
                  // placeholder="Votre titre"
                  className={`border border-solid border-[#1e93fd] w-[57%] rounded-[5px] h-[30px] pl-2`}
                />
              </div>

              <div className="flex justify-between items-center pl-3 pr-1 mt-1">
                <span className="text-[13px] text-[#757575]"> Colors </span>
                <div className="flex justify-center items-center w-[70%] gap-3">
                  {/* Predefined colors */}
                  {predefinedColors.map((color, index) => (
                    <div
                      key={index}
                      className={`w-2 h-2 rounded-full cursor-pointer
                        }`}
                      style={{
                        backgroundColor: color,
                        border: `3.5px solid ${
                          selectedColor === color ? "#3182ce" : "transparent"
                        }`,
                      }}
                      onClick={() => selectColor(color)}
                    ></div>
                  ))}
                  {/* Custom color picker */}
                  <label className="cursor-pointer max-h-[16px]">
                    <img src={colorPickerIcon} alt="Color picker" />
                    <input
                      type="color"
                      className="w-0 h-0 p-0 m-0 rounded-full white cursor-pointer border-[0px]"
                      value={customColor}
                      onChange={handleCustomColorChange}
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-between px-3 mt-5">
              <div
                onClick={() => handleDeleteTag(true)}
                className="flex items-center gap-1 cursor-pointer"
              >
                <img src={redTrashIcon} alt="Delete icon" />
                <span className="text-[12px] text-red-600 font-bold">
                  {" "}
                  {t("DELETE")}{" "}
                </span>
              </div>

              <button
                className={`rounded-full px-5 py-2 w-[40%] cursor-pointer bg-gradient-to-r from-blue-500 to-blue-700 text-white text-[12px] font-semibold border-none`}
                onClick={() =>
                  onTagEdit(
                    currentEditTagId,
                    selectedColor,
                    editedTagText,
                    editedTagWebhookUrl
                  )
                }
              >
                {t("EDIT")}
              </button>
            </div>
          </div>
        )}
      </div>

      <img
        alt="Add tag"
        src={TagAdd}
        style={{ height: "28px", width: "28px", cursor: "pointer" }}
        onClick={() => setExpandTagModal(!expandTagModal)}
      />
      {expandTagModal ? (
        <div ref={tagModalRef}>
          <TagListModal
            addTagsByTitle={addTagsByTitle}
            addTagsByTagId={addTagsByTagId}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

const colorPickerStyle = {
  backgroundImage: `url(${colorPickerIcon})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "contain",
};

export default Tag;
