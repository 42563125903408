import React, { useState, useEffect, useRef } from "react";
import MPChips from "../../MPFactory/MPChips";
import DropdownArrow from "../../../assets/images/dropdown-arrow.svg";
import WhiteDropDownArrow from "../../../assets/images/white_dropdown_arrow.svg";
import { useTranslation } from "react-i18next";
import useOutsideClickClose from "../../MPFactory/Hooks/outsideClickClose";
import Separator from "../../Separator";
import DateInput from "../../DateInput";
import { useDispatch } from "react-redux";
import { setConversationPreferenceDate } from "../../../store/actions/conversationPreference";

const ReminderOptions = ({ conversationPref, saveConversationPreferences }) => {
  const [expanded, setExpanded] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const dropdownRef = useRef();
  useOutsideClickClose(dropdownRef, () => setExpanded(false));

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  useEffect(() => {
    if (conversationPref && conversationPref?.reminderDuration) {
      setSelectedOption({ key: conversationPref?.reminderDuration });

      if (conversationPref?.reminderOn !== null) {
        setSelectedDate(new Date(conversationPref?.reminderOn));
        dispatch(setConversationPreferenceDate({ conversationDate: formatDate(conversationPref?.reminderOn) }));
      }
    }
    else {
      setSelectedDate(null);
      dispatch(setConversationPreferenceDate({ conversationDate: null }));
      setSelectedOption(null);
    }
  }, [conversationPref])
  const REMINDER_OPTIONS = [
    {
      key: "3D",
      value: t("REMINDER_3_DAYS"),
    },
    {
      key: "1W",
      value: t("REMINDER_1_WEEK"),
    },
    {
      key: "1M",
      value: t("REMINDER_1_MONTH"),
    },
  ];
  const onReminderOptionSelect = (_ro) => {
    onSetReminderOption(_ro)
  };
  const onSetReminderOption = async (_ro) => {
    const reminderDuration = _ro.key == selectedOption?.key ? null : _ro.key
    const isInterested = conversationPref?.isInterested;
    saveConversationPreferences({
      isInterested: isInterested,
      reminderDuration: _ro.key == "Custom" ? "Custom" : reminderDuration,
      customRemminderDate: _ro.key == "Custom" ? _ro.value : null
    })
    setExpanded(false)
  }

  const renderExpandedOptions = () => {
    return (
      <div style={styles.expandedDivWrapper} >
        {REMINDER_OPTIONS.map((_ro) => (
          <MPChips
            onChipClick={() => onReminderOptionSelect(_ro)}
            text={_ro.value}
            selected={_ro.key == selectedOption?.key}
          />
        ))}
        <Separator />
        <div className="flex flex-col items-center justify-center gap-2">
          <DateInput selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
          <button className="border-none cursor-pointer bg-gradient-to-r from-[#1E93FD] to-[#3154C8] bg-clip-text text-transparent font-bold" onClick={() => onReminderOptionSelect({ key: "Custom", value: selectedDate.toISOString() })}> Valider </button>
        </div>
      </div>
    );
  };

  const optionTitleStyles = selectedOption === null ? { ...styles.unSelectedBg, ...styles.optionTitleDiv } : selectedOption?.key === null ? { ...styles.unSelectedBg, ...styles.optionTitleDiv } : { ...styles.selectedBg, ...styles.optionTitleDiv };
  const optionTextStyles = selectedOption === null ? { ...styles.optionTitleText, ...styles.unSelectedText } : selectedOption?.key === null ? { ...styles.optionTitleText, ...styles.unSelectedText } : { ...styles.optionTitleText, ...styles.selectedText };
  const dropDownIcon = selectedOption === null ? DropdownArrow : selectedOption?.key === null ? DropdownArrow : WhiteDropDownArrow;

  return (
    <div style={styles.optionWrapper} ref={dropdownRef}>
      <div style={optionTitleStyles} onClick={() => setExpanded(!expanded)}>
        <div style={optionTextStyles}>{t("REMINDER")}</div>
        <img
          src={dropDownIcon}
          style={{ cursor: "pointer" }}
        />
      </div>
      {expanded ? renderExpandedOptions() : <></>}
    </div>
  );
};

const styles = {
  optionWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: " center",
    position: "relative",
  },
  optionTitleDiv: {
    width: 81,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 6,
    paddingBottom: 6,
    borderRadius: 30,
    justifyContent: "space-between",
    alignItems: "center",
    gap: 10,
    display: "inline-flex",
    cursor: "pointer"
  },
  optionTitleText: {
    fontSize: 10,
    fontFamily: "Montserrat",
    wordWrap: "break-word",
  },
  expandedDivWrapper: {
    position: "absolute",
    marginTop: 295,
    zIndex: 10,
    background: "#fff",
    borderRadius: "10px",
    boxShadow: "0px 20px 40px 0px rgba(30, 147, 253, 0.20)",
    padding: 13,
    right: 0,
    border: "1px solid #3154C8",
    width: 190,
  },
  unSelectedBg: {
    background: "white",
    border: "1px #1E93FD solid",
  },
  selectedBg: {
    background: "linear-gradient(90deg, #1E93FD 0%, #3154C8 100%)",
  },
  unSelectedText: {
    color: "#3154C8",
    fontWeight: "400",
  },
  selectedText: {
    color: "#fff",
    fontWeight: 700
  }
};
export default ReminderOptions;
