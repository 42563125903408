import Dashboard from "./modules/dashboard";
import mirrorChatStore from "./store/index";
import { Provider } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import Login from "./modules/auth/login";
import EndTrial from "./modules/auth/end-trial";
import ForgotPassword from "./modules/auth/forgot-password";
import Home from "./modules/Home";
import ChangePassword from "./modules/auth/change-password";
import CreatePassword from "./modules/auth/create-password";
import AccountManagement from "./modules/AccountManagement";
import LinkedInSettings from "./modules/AccountManagement/LinkedInSettings";
import MyAccount from "./modules/AccountManagement/MyAccount";
import { Helmet } from 'react-helmet';
import Team from "./modules/AccountManagement/Team";

function App() {

  return (
    <Provider store={mirrorChatStore}>
      <Helmet>
        <meta httpEquiv="X-Frame-Options" content="SAMEORIGIN" /> {/* or 'SAMEORIGIN' if you want to allow embedding from the same origin */}
      </Helmet>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/end-trial" element={<EndTrial />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/change-password" element={<ChangePassword />} />
          <Route exact path="/create-password" element={<CreatePassword />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/account-management/*" element={<AccountManagement />}>
            <Route path="linkedin-settings" element={<LinkedInSettings />} />
            <Route path="myaccount" element={<MyAccount />}/>
            <Route path="team" element={<Team />} />
            {/* <Route path="team" element={<div className="text-slate-900">Team</div>} /> */}
            <Route path="disconnect"/>
          </Route>
          {/* <Dashboard /> */}
        </Routes>
      </Router>
      <ToastContainer />
    </Provider>
  );
}

export default App;
