import React from "react";
import MPLogo from "../../../assets/images/mirrorProfilesLogo.png";
import "./styles.css";
import StatusMessage from "../../../components/statusMessage";
import { logoutApi } from "../../../services/auth";
import SelectLanguage from "../../../components/SelectLanguage";
import { useTranslation } from "react-i18next";
import Sidebar, { SidebarItem } from "../../../components/Sidebar/Sidebar";
import Cookies from "js-cookie";

const DashboardHeader = () => {
  const { t } = useTranslation();
  let isMember = Cookies.get("isMember") === '1' || null || '0' || undefined;
  if (isMember === true || isMember === "1" || isMember === 1) {
    isMember = true;
  } else {
    isMember = false;
  }

  const onLogout = async () => {
    const { msg, code } = await logoutApi();
    if (code == 200) {
      window.location.href = "/";
    }
  };
  return (
    <div className="dh-container">
      <div className="float-right" style={{ position: "relative", zIndex: 20 }}>
        <div className="cursor-pointer logout-btn" onClick={onLogout}>
          {t("LOGOUT")}
        </div>
      </div>
      {/*<div className="clear-both"></div>*/}
      <div className="head-left flex items-center gap-1">
        <div>
          <Sidebar mobileViewOnly={true}>
          {!isMember && (
              <>
                <SidebarItem
                  icon="Money"
                  text={t("MY_ACCOUNT")}
                  to="/account-management/myaccount"
                />
                <SidebarItem
                  icon="Setting"
                  text={t("LINKEDIN_SETTINGS")}
                  to="/account-management/linkedin-settings"
                />
                <SidebarItem
                  icon="Team"
                  text="Team"
                  to="/account-management/team"
                />
              </>
            )}
            {isMember && (
              <SidebarItem
                icon="Setting"
                text={t("LINKEDIN_SETTINGS")}
                to="/account-management/linkedin-settings"
              />
            )}
          </Sidebar>
        </div>
        <img src={MPLogo} className="logo" />
        <SelectLanguage />
        <StatusMessage />
      </div>
    </div>
  );

};

export default DashboardHeader;
