import { getRequest, postRequest } from "./Networking";
import { INTERLOCUTOR_INFOS } from "./Networking/api";
import { getUrlParamsString } from "./Utils";

const addInterlocutorInfos = async (conversationUrn, params) => {
    const response = await postRequest(`${INTERLOCUTOR_INFOS}/${conversationUrn}`, params);
    return response.json();
};

const getInterlocutorInfos = async (conversationUrn, params) => {
    const paramsString = getUrlParamsString(params);
    const response = await getRequest(`${INTERLOCUTOR_INFOS+"/"+conversationUrn}?${paramsString}`);
    return response.json();
};

export { addInterlocutorInfos, getInterlocutorInfos };
