export const UPDATE_SELECTED_CONVERSATION = "UPDATE_SELECTED_CONVERSATION";
export const SET_CONTACT_INFO = "SET_CONTACT_INFO";
export const SET_CONTACT_INFO_LI_AT = "SET_CONTACT_INFO_LI_AT";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const SET_MESSAGES = "SET_MESSAGES";
export const UPDATE_MESSAGE = "UPDATE_MESSAGE";
export const SELECTED_MESSAGE = "SELECTED_MESSAGE";
export const SET_CONTACT_INFO_LOADING = "SET_CONTACT_INFO_LOADING";
export const MARK_UNREAD = "MARKED_UNREAD"
export const UPDATE_MESSAGE_CONTENT = "UPDATE_MESSAGE_CONTENT"
export const DELETE_MESSAGE = "DELETE_MESSAGE"
export const NEW_MESSAGES = "NEW_MESSAGES"
export const UPDATE_UNREAD_COUNT = "UPDATE_UNREAD_COUNT"
export const SET_FILTER_TAGS = "SET_FILTER_TAGS"
export const SET_PROSPECTORS_LIST = "SET_PROSPECTORS_LIST"
export const SET_LAST_MESSAGE_NOT_BY_PROSPECTOR = "SET_LAST_MESSAGE_NOT_BY_PROSPECTOR"
export const SET_START_DATE = "SET_START_DATE"
export const SET_END_DATE = "SET_END_DATE" 
export const SET_NUMBER_OF_MESSAGES = "SET_NUMBER_OF_MESSAGES"
export const SET_CONVERSATION_PREFERENCE_DATE = "SET_CONVERSATION_PREFERENCE_DATE"  
export const SET_CONVERSATION_PREFERENCE_TEXT = "SET_CONVERSATION_PREFERENCE_TEXT"  
export const SET_CUSTOM_DATE_TRIGGER = "SET_CUSTOM_DATE_TRIGGER"