import React from "react";
import "./styles.css";
import AddAccountIcon from "../../assets/images/addAccount.svg";
import WarningUnfilledIcon from "../../assets/images/warning-unfilled.svg";
import { useTranslation } from "react-i18next";
import MPModalHOC from "../MPFactory/MPModalHOC";

const AddAccountsModal = ({ onClose }) => {
  const { t } = useTranslation();


  const url =
    "https://account-dev.unipile.com/eyJ1cmwiOiJodHRwczovL2FwaTIudW5pcGlsZS5jb206MTMyMjAiLCJ0b2tlbiI6IkJOVjhsb2RtLmJ6N0M4ZSt5NzFSazBTYVVyTzUwT0pMUmxPN2ZYdXRxOVkyUXJxSWdMSE09In0%3D";

  return (
    <>
      <div id="mirrorprofiles-add-accounts-layer">
        <div id="mirrorprofiles-add-accounts-container">
          <button
            id="mirrorprofiles-close-add-accounts-window"
            onClick={onClose}
          >
            <i class="fa-solid fa-xmark"></i>
          </button>
          <iframe title="Attach Account" src={url} width="100%" height="100%" />
        </div>
      </div>
    </>
  );
  return (
    <>
      <div id="mirrorprofiles-add-accounts-layer">
        <div id="mirrorprofiles-add-accounts-container">
          <button
            id="mirrorprofiles-close-add-accounts-window"
            onClick={onClose}
          >
            <i class="fa-solid fa-xmark"></i>
          </button>
          <img
            src={AddAccountIcon}
            style={{ height: "83px", width: "81px", margin: "auto" }}
          />
          <div id="mirrorprofiles-add-accounts-header">
            <span id="mirrorprofiles-add-accounts-label">
              {t("ADD_ACCOUNT_TITLE")}
            </span>
          </div>
          <div id="mirrorprofiles-add-accounts-body">
            <p>{t("ADD_ACCOUNT_SUBHEAD")}</p>
            <div id="mirrorprofiles-add-accounts-info">
              <div id="mirrorprofiles-add-accounts-info-left">
                {/* <i class="fa-light fa-circle-info"></i> */}
                <img
                  src={WarningUnfilledIcon}
                  style={{ height: "20px", width: "20px" }}
                />
              </div>
              <div id="mirrorprofiles-add-accounts-info-right">
                {t("ADD_ACCOUNT_INFO")}
              </div>
            </div>
            <div id="mirrorprofiles-add-accounts-buttons">
              <div>
                <a
                  id="mirrorprofiles-rent-account"
                  href="https://checkout.stepward.com/b/cN2aIwa5z9bx6XefZv"
                  target="_blank"
                >
                  {t("RENT_ACCOUNT_CTA")}
                </a>
              </div>
              <div>
                <a
                  id="mirrorprofiles-rent-account-more-infos"
                  href="https://mirrorprofiles.com/"
                  target="_blank"
                >
                  {t("LEARN_MORE_CTA")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MPModalHOC(AddAccountsModal);
