import React, { useState, useEffect } from "react";
import Check from "../../assets/images/check.svg";
import { triggerWebhook, getWebhookUrls } from "../../services/Webhook";
import "./styles.css";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { WEBHOOK_SUCCESS } from "../../constants/messages";
import { useTranslation } from "react-i18next";

const WebhookModal = (props) => {
  const state = useSelector((state) => state);
  const { t } = useTranslation();
  const [webhookUrl, setWebhookUrl] = useState();
  useEffect(() => {
    loadWebhookUrl();
  }, []);
  const sendWebhook = async () => {
    const interlocutor =
      state?.conversation?.selectedConversation?.interlocutor;
    const prospector = state?.conversation?.selectedConversation?.prospector;
    const basicDetails = state?.contactInfo?.basicDetails;
    const body = {
      key: "0",
      webhook_url: webhookUrl,
      linkedin_profile_name:
        interlocutor?.firstName + " " + interlocutor?.lastName,
      linkedin_profile_url: interlocutor?.profileUrl,
      linkedin_account_sender:
        prospector?.firstName + " " + prospector?.lastName,
      linkedin_profile_title: interlocutor?.headline,
      linkedin_profile_phone: basicDetails?.phoneNumbers?.[0],
      linkedin_profile_email: basicDetails?.result,
      linkedin_conversations: state?.message?.messages,
      prospector_urn: prospector?.entityUrn,
      conversation_urn: state?.conversation?.selectedConversation?.entityUrn,
      member_urn_id: state?.conversation?.selectedConversation?.interlocutor?.memberUrn?.replace("urn:li:member:", "")
    };
    const webhook = await triggerWebhook(body);
    props?.onWebhooksent();
    toast.success(t("WEBHOOK_SUCCESS"), {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const loadWebhookUrl = async () => {
    const params = { key: "0" };
    const webhookUrls = await getWebhookUrls(params);
    const defaultWebhook = webhookUrls.filter(webhook=>webhook.title=="DEFAULT" || !webhook.title);

    if (webhookUrls.length)
      setWebhookUrl(defaultWebhook[defaultWebhook.length - 1]?.url);
  };

  return (
    <div className="webhook-container">
      <div className="webhook-input-wrap">
        <input
          type="text"
          value={webhookUrl}
          onChange={(e) => setWebhookUrl(e.target.value)}
          placeholder="Webhook"
        />
        <div onClick={sendWebhook} className="check-wrap">
          <img src={Check}></img>
        </div>
      </div>
      <div className="webhook-test">{t("ENTER_WEBHOOK_URL")}</div>
    </div>
  );
};

export default WebhookModal;
