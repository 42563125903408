import { createContext, useContext, useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import leftArrow from "../../assets/images/arrowleft.svg";
import Money from "../../assets/images/streamline_money.svg";
import Setting from "../../assets/images/setting.svg";
import Team from "../../assets/images/team.svg";
import HighlightedTeam from "../../assets/images/team_highlighted.svg";
import HighlightedMoney from "../../assets/images/money_highlighted.svg";
import HighlightedSetting from "../../assets/images/setting_highlighted.svg";
import HamburgerIcon from "../../assets/images/hamburger_icon.svg";
import WhiteCloseIcon from "../../assets/images/white_close.svg";


const SidebarContext = createContext();

export default function Sidebar({ children, mobileViewOnly }) {
  const [expand, setExpand] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  

  const closeMobileSidebar = () => {
    if (window.innerWidth < 1024) {
      setExpand(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setExpand(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {/* sidebar view for pc/laptop screens */}
      {mobileViewOnly === false ? (
        <div className="inline-block h-[23px]">
          <img
            src={HamburgerIcon}
            onClick={() => setExpand(true)}
            className="block lg:hidden cursor-pointer"
          />
        </div>
      ) : (
        <div className="inline-block h-[23px]">
          <img
            src={HamburgerIcon}
            onClick={() => setExpand(true)}
            className="block cursor-pointer"
          />
        </div>
      )}
      {!mobileViewOnly && (<aside className={`h-screen hidden lg:block`}>
        <nav className="h-full inline-flex flex-col bg-white border-r shadow-sm bg-gradient-to-b from-[#1E93FD] to-[#3153C7]">
          <div className="p-4 mt-8 mb-6 pb-0 flex gap-3 justify-between items-center w-[75%] mx-auto">
            <button
              onClick={() => navigate("/dashboard")}
              className="p-1.5 bg-transparent border-none cursor-pointer"
            >
              <img src={leftArrow}></img>
            </button>
            <div
              className={`overflow-hidden transition-all block text-xl text-white leading-[120.0%] font-semibold`}
              alt=""
            >
              {t("BACK_TO_MESSAGING")}
            </div>
          </div>
          <SidebarContext.Provider value={{ closeMobileSidebar }}>
            <ul className={`px-3 w-[75%] mx-auto`}>{children}</ul>
          </SidebarContext.Provider>
        </nav>
      </aside>)}

      {/* sidebar view for tablet/mobile screens */}
      {expand && (
        <aside
          className={`h-screen fixed top-0 z-[9999] ${
            expand ? `left-0` : `left-[-100%]`
          } transition-all`}
        >
          <nav className="h-full inline-flex flex-col bg-white border-r shadow-sm bg-gradient-to-b from-[#1E93FD] to-[#3153C7]">
            <div className="p-4 mt-8 mb-6 pb-0 flex gap-3 justify-between items-center w-[75%] mx-auto">
              <button
                onClick={() => navigate("/dashboard")}
                className="p-1.5 bg-transparent border-none cursor-pointer"
              >
                <img src={leftArrow}></img>
              </button>
              <div
                className={`overflow-hidden transition-all block text-xl text-white leading-[120.0%] font-semibold`}
                alt=""
              >
                {t("BACK_TO_MESSAGING")}
              </div>
              <img
                src={WhiteCloseIcon}
                width={40}
                className="cursor-pointer"
                onClick={() => setExpand(false)}
              />
            </div>
            <SidebarContext.Provider value={{ closeMobileSidebar }}>
              <ul className={`px-3 w-[75%] mx-auto`}>{children}</ul>
            </SidebarContext.Provider>
          </nav>
        </aside>
      )}
    </>
  );
}

export function SidebarItem({ to, icon, text }) {
  const location = useLocation();
  const { closeMobileSidebar } = useContext(SidebarContext);

  const iconSrc = () => {
    const isActive = location.pathname === to;
    if (isActive) {
      if (icon === "Money") {
        return HighlightedMoney;
      } else if (icon === "Setting") {
        return HighlightedSetting;
      } else if (icon === "Team") {
        return HighlightedTeam;
      }
    } else {
      if (icon === "Money") {
        return Money;
      } else if (icon === "Setting") {
        return Setting;
      } else if (icon === "Team") {
        return Team;
      }
    }
  };

  return (
    <NavLink
      to={to}
      onClick={() => closeMobileSidebar()}
      className={({ isActive }) => {
        return isActive
          ? "relative flex items-center py-3 px-3 my-5 rounded-md cursor-pointer group text-[#1E93FD] bg-white no-underline font-semibold"
          : "relative flex items-center py-3 px-3 my-5 font-medium rounded-md cursor-pointer group no-underline text-white bg-transparent";
      }}
    >
      <img src={iconSrc()} />
      <span className={`overflow-hidden w-52 ml-3`}>{text}</span>
    </NavLink>
  );
}
